import React from "react";
import { ILSecurity } from "src/assets";

const SectionImage = () => {
  return (
    <div className="col-md-6 text-center d-none d-sm-block align-self-center">
      <img src={ILSecurity} className="img-fluid" width={450} />
    </div>
  );
};

export default SectionImage;
