import React, { useEffect } from "react";
import { Header, LockPage } from "src/components";
import { IoCalendarOutline } from "react-icons/io5";
import SectionMapel from "./parts/SectionMapel";
import { useSelector, useDispatch } from "react-redux";
import SectionModalFilter from "./parts/SectionModalFilter";
import {
  getDateElearningFilterFirstLast,
  setDataRuangBelajar,
  setFormElearning,
} from "src/config/redux/action";
import "./style.css";
import { FUNCDateToString } from "src/config";

const Elearning = (props) => {
  const { gbDataUser } = useSelector((state) => state.globalReducer);
  const {
    modalFilterElearning,
    formElearning,
    dataElearnings,
    dataElearningDates,
  } = useSelector((state) => state.elearningReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDateElearningFilterFirstLast(formElearning.TanggalFilter));
    dispatch(
      setDataRuangBelajar(FUNCDateToString(formElearning.TanggalFilter))
    );
  }, [dispatch]);

  const showModalFilterElearning = () =>
    dispatch({
      type: "MODAL_FILTER_ELEARNING",
      payload: !modalFilterElearning,
    });

  return (
    <>
      <Header name={props.name} />
      {/* {gbDataUser.IsProfileDone &&
      gbDataUser.KelasParalel.IdKelasParalel !== 0 ? ( */}
      <>
        <div className="filter-mapel-mobile d-block d-md-none">
          <div
            className="d-flex align-items-center"
            onClick={() => showModalFilterElearning()}
          >
            <IoCalendarOutline color="#3F3D56" size={18} />
            <h4>Filter</h4>
          </div>
        </div>
        <SectionMapel
          dataElearnings={dataElearnings}
          dataElearningDates={dataElearningDates}
          onFilterModal={() => showModalFilterElearning()}
        />
        <SectionModalFilter
          isOpen={modalFilterElearning}
          onModal={showModalFilterElearning}
        />
      </>
      {/* ) : (
        <LockPage />
      )} */}
    </>
  );
};

export default Elearning;
