import React, { forwardRef } from "react";
import MaterialTable from "material-table";
import { createTheme, Paper, ThemeProvider } from "@mui/material";
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
  CloudUpload,
} from "@mui/icons-material";

const DataTable = ({ ...props }) => {
  const theme = createTheme({
    typography: {
      fontFamily: "Poppins",
      h6: {
        fontSize: 15,
        color: "#3F3D56",
        fontWeight: 500,
      },
    },
    components: {
      MuiInputBase: {
        styleOverrides: {
          inputAdornedStart: {
            fontSize: 13,
          },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          root: {
            fontSize: 13,
            color: "#3F3D56",
            fontWeight: 400,
            borderBottom: "none",
          },
          select: {
            fontSize: 13,
            color: "#3F3D56",
            fontWeight: 400,
          },
          selectLabel: {
            fontSize: 13,
            color: "#3F3D56",
            fontWeight: 400,
          },
          displayedRows: {
            fontSize: 13,
            color: "#3F3D56",
            fontWeight: 400,
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            borderBottom: "none",
          },
        },
      },
    },
  });

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    CloudUpload: forwardRef((props, ref) => (
      <CloudUpload {...props} ref={ref} />
    )),
  };
  return (
    <ThemeProvider theme={theme}>
      <MaterialTable
        icons={tableIcons}
        components={{
          Container: (props) => (
            <Paper {...props} elevation={0} style={{ borderRadius: 20 }} />
          ),
        }}
        options={{
          rowStyle: {
            fontSize: 13,
            fontFamily: "Poppins",
            fontWeight: 400,
          },
          headerStyle: {
            color: "#3F3D56",
            fontSize: 13,
            fontFamily: "Poppins",
          },
        }}
        {...props}
      />
    </ThemeProvider>
  );
};

export default DataTable;
