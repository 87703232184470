import React from "react";
import Slider from "react-slick";
import { Skeleton } from "@mui/material";
import { IMGNotFoundLandscape } from "src/assets";

const SectionBanner = ({ data }) => {
  return (
    <div className="card-global mb-3">
      <div className="d-flex align-items-center">
        <div className="icon-global"></div>
        <h5 className="title-global">Banner</h5>
      </div>
      <div className="mt-4 mx-3 mb-4">
        {data ? (
          <Slider
            className="card-banner"
            slidesPerRow={1}
            autoplay={true}
            infinite={true}
            dots
            speed={500}
            responsive={[
              {
                breakpoint: 801,
                settings: {
                  slidesPerRow: 1,
                },
              },
            ]}
          >
            {data &&
              data.map((v, i) => {
                return (
                  <div key={i} className="banner">
                    <img
                      src={v}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = IMGNotFoundLandscape;
                      }}
                      className="img-fluid banner-img"
                    />
                  </div>
                );
              })}
          </Slider>
        ) : (
          <Skeleton
            variant="rectangular"
            height={200}
            style={{ borderRadius: 20 }}
          />
        )}
      </div>
    </div>
  );
};

export default SectionBanner;
