import { Skeleton } from "@mui/material";
import React from "react";
import Slider from "react-slick";

const SectionMutabaah = ({ data }) => {
  return (
    <div className="col-md-12 mb-4">
      <div className="card-global">
        <div className="d-flex align-items-center">
          <div className="icon-global"></div>
          <h5 className="title-global">Mutabaah</h5>
        </div>
        <div className="mt-4 mb-2">
          {data ? (
            <Slider
              slidesPerRow={1}
              autoplay={true}
              infinite={true}
              speed={500}
              arrows={false}
              responsive={[
                {
                  breakpoint: 801,
                  settings: {
                    slidesPerRow: 1,
                  },
                },
              ]}
            >
              {data &&
                data.map((v, i) => {
                  return (
                    <div key={i} className="d-flex justify-content-center">
                      <span className="info-mutabaah w-100">{v}</span>
                    </div>
                  );
                })}
            </Slider>
          ) : (
            <Skeleton
              variant="rectangular"
              height={40}
              style={{ borderRadius: 20 }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SectionMutabaah;
