import React from "react";
import "./style.css";

const Alert = ({ icon, type, desc }) => {
  const badges = {
    danger: "alert-custom-danger",
    warning: "alert-custom-warning",
    success: "alert-custom-success",
  };
  const badge = badges[type];
  return (
    <div className={badge}>
      <div className="icon">{icon}</div>
      <span dangerouslySetInnerHTML={{ __html: desc }} />
    </div>
  );
};

export default Alert;
