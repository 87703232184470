import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker, ReactSelect } from "src/components";
import {
  apiInfoUpdateDataIbu,
  apiInfoUpdateDataAyah,
  apiInfoUpdateDataWali,
  setDataInfoIbu,
  setDataInfoAyah,
  setDataInfoWali,
  setDataComboJenisPekerjaan,
  setDataComboJenisPendidikan,
  setDataComboStatusOrtu,
  setDataAlamatAyah,
  setDataComboProvinsi,
  setDataComboKabkot,
  setDataComboKecamatan,
  setDataComboDesa,
  apiInfoAddDataWali,
  setDataComboOrtuJenisTempatTinggal,
} from "src/config/redux/action";
import ReactInputMask from "react-input-mask";
import { FUNCDateDmytoYmd } from "src/config";

const jsonTabs = [
  {
    tab: "father",
    title: "Ayah",
  },
  {
    tab: "mother",
    title: "Ibu",
  },
  {
    tab: "wali",
    title: "Wali",
  },
];

const SectionParent = () => {
  const [tab, setTab] = useState({ value: "father", label: "Ayah" });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setDataComboProvinsi("DATA_COMBO_PROVINSI"));
    dispatch(setDataComboJenisPekerjaan("DATA_COMBO_JENIS_PEKERJAAN"));
    dispatch(setDataComboJenisPendidikan("DATA_COMBO_JENJANG_PENDIDIKAN"));
    dispatch(setDataComboStatusOrtu("DATA_COMBO_STATUS_ORTU"));
    dispatch(
      setDataComboOrtuJenisTempatTinggal("DATA_COMBO_ORTU_JENIS_TEMPAT_TINGGAL")
    );
    dispatch(setDataInfoIbu());
    dispatch(setDataInfoAyah());
    dispatch(setDataInfoWali());
  }, [dispatch]);

  return (
    <>
      <div className="card-list-parent">
        {jsonTabs.map((v, i) => {
          return (
            <div
              key={i}
              className={`tab-parent ${tab.value === v.tab ? "active" : ""}`}
              onClick={() =>
                setTab({
                  value: v.tab,
                  label: v.title,
                })
              }
            >
              <span>{v.title}</span>
            </div>
          );
        })}
      </div>
      <div className="card-global">
        <div className="d-flex align-items-center">
          <div className="icon-global"></div>
          <h5 className="title-global">{tab.label}</h5>
        </div>

        {tab.value === "mother" && <ItemFormMother />}
        {tab.value === "father" && <ItemFormFather />}
        {tab.value === "wali" && <ItemFormWali />}
      </div>
    </>
  );
};

const ItemFormMother = () => {
  const { dataInfoIbu, dataAlamatAyah } = useSelector(
    (state) => state.userReducer
  );
  const {
    dataComboJenisPekerjaan,
    dataComboJenjangPendidikan,
    dataComboStatusOrtu,
    dataComboProvinsi,
    dataComboKabkot,
    dataComboKecamatan,
    dataComboDesa,
    dataComboOrtuJenisTempatTinggal,
    dataComboAlamatTunjuk,
  } = useSelector((state) => state.comboReducer);
  const dispatch = useDispatch();
  const {
    control,
    getValues,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const [isWna, setIsWna] = useState(false);
  const [isDomisili, setIsDomisili] = useState(1);

  useEffect(() => {
    setValue("IdSiswaOrtu", dataInfoIbu?.IdSiswaOrtu);
    setValue("Nik", dataInfoIbu?.Nik);
    setValue("Nama", dataInfoIbu?.Nama);
    setValue(
      "IdStatus",
      dataInfoIbu?.Status?.Nama && {
        value: dataInfoIbu?.Status?.Id,
        label: dataInfoIbu?.Status?.Nama,
      }
    );
    setValue("IdKewarganegaraan", `${dataInfoIbu?.Kewarganegaraan?.Id || 1}`);
    if (dataInfoIbu?.Kewarganegaraan?.Id === 2) setIsWna(true);
    setValue("AsalNegara", dataInfoIbu?.AsalNegara);
    setValue("NoKitas", dataInfoIbu?.NoKitas);
    var TanggalLahir = new Date("1900-01-01");
    if (dataInfoIbu?.TanggalLahir)
      TanggalLahir = new Date(FUNCDateDmytoYmd(dataInfoIbu?.TanggalLahir));
    setValue("TanggalLahir", TanggalLahir);
    setValue("TempatLahir", dataInfoIbu?.TempatLahir);
    setValue(
      "IdJenisJenjangPendidikan",
      dataInfoIbu?.JenisJenjangPendidikan?.Nama && {
        value: dataInfoIbu?.JenisJenjangPendidikan?.Id,
        label: dataInfoIbu?.JenisJenjangPendidikan?.Nama,
      }
    );
    setValue(
      "IdJenisPekerjaan",
      dataInfoIbu?.JenisPekerjaan?.Nama && {
        value: dataInfoIbu?.JenisPekerjaan?.Id,
        label: dataInfoIbu?.JenisPekerjaan?.Nama,
      }
    );
    setValue("NamaInstansi", dataInfoIbu?.NamaInstansi);
    setValue("Penghasilan", dataInfoIbu?.Penghasilan);
    setValue("IdDomisili", `${dataInfoIbu?.Domisili?.Id}`);
    setValue(
      "IdJenisTempatTinggal",
      dataInfoIbu?.JenisTempatTinggal?.Nama && {
        value: dataInfoIbu?.JenisTempatTinggal?.Id,
        label: dataInfoIbu?.JenisTempatTinggal?.Nama,
      }
    );
    setValue(
      "IdProvinsi",
      dataInfoIbu?.Alamat?.Provinsi?.Nama && {
        value: dataInfoIbu?.Alamat?.Provinsi?.Id,
        label: dataInfoIbu?.Alamat?.Provinsi?.Nama,
      }
    );
    setValue(
      "IdKabkot",
      dataInfoIbu?.Alamat?.Kabkot?.Nama && {
        value: dataInfoIbu?.Alamat?.Kabkot?.Id,
        label: dataInfoIbu?.Alamat?.Kabkot?.Nama,
      }
    );
    setValue(
      "IdKecamatan",
      dataInfoIbu?.Alamat?.Kecamatan?.Nama && {
        value: dataInfoIbu?.Alamat?.Kecamatan?.Id,
        label: dataInfoIbu?.Alamat?.Kecamatan?.Nama,
      }
    );
    setValue(
      "IdDesa",
      dataInfoIbu?.Alamat?.Desa?.Nama && {
        value: dataInfoIbu?.Alamat?.Desa?.Id,
        label: dataInfoIbu?.Alamat?.Desa?.Nama,
      }
    );
    setValue(
      "KdAlamatTunjuk",
      dataInfoIbu?.Alamat?.AlamatTunjuk?.Nama && {
        value: dataInfoIbu?.Alamat?.AlamatTunjuk?.Kode,
        label: dataInfoIbu?.Alamat?.AlamatTunjuk?.Nama,
      }
    );
    setValue("Alamat", dataInfoIbu?.Alamat?.Alamat);
    setValue("Longitude", dataInfoIbu?.Alamat?.Longitude);
    setValue("Latitude", dataInfoIbu?.Alamat?.Latitude);
    setValue("KdPos", dataInfoIbu?.Alamat?.KdPos);
    setValue("Rt", dataInfoIbu?.Alamat?.Rt);
    setValue("Rw", dataInfoIbu?.Alamat?.Rw);
    setValue("Dusun", dataInfoIbu?.Alamat?.Dusun);
    setValue("NoHp", dataInfoIbu?.NoHp);
    setValue("Email", dataInfoIbu?.Email);
  }, [dataInfoIbu]);

  const onWilayah = (id, type) => {
    if (type === "Provinsi") {
      setValue("IdKabkot", "");
      setValue("IdKecamatan", "");
      setValue("IdDesa", "");
      dispatch(setDataComboKabkot("DATA_COMBO_KABKOT", id));
      return;
    }
    if (type === "Kabkot") {
      setValue("IdKecamatan", "");
      setValue("IdDesa", "");
      dispatch(setDataComboKecamatan("DATA_COMBO_KECAMATAN", id));
      return;
    }
    if (type === "Kecamatan") {
      setValue("IdDesa", "");
      dispatch(setDataComboDesa("DATA_COMBO_DESA", id));
      return;
    }
  };

  const onTempatTinggal = (isTrue) => {
    if (isTrue) {
      dispatch(setDataAlamatAyah(setValue));
      dispatch({ type: "DATA_ALAMAT_AYAH", payload: true });
    } else {
      dispatch({ type: "DATA_ALAMAT_AYAH", payload: false });
    }
  };

  const btnSave = (data) => dispatch(apiInfoUpdateDataIbu(data));

  return (
    <div className="card-data-siswa mt-3">
      <div className="content-data-siswa">
        <form onSubmit={handleSubmit(btnSave)}>
          <div className="row">
            <div className="col-md-12">
              <div className="mb-3">
                <label className="label-input mb-2">NIK Ibu</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="input-costum form-control"
                    placeholder="Masukkan NIK Ibu"
                    {...register("Nik", { required: true })}
                  />
                </div>
                {errors.Nik && (
                  <span className="alert-input">
                    NIK Ibu tidak boleh kosong
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb-3">
                <label className="label-input mb-2">Nama Ibu</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="input-costum form-control"
                    placeholder="Masukkan Nama Ibu"
                    {...register("Nama", { required: true })}
                  />
                </div>
                {errors.Nama && (
                  <span className="alert-input">
                    Nama Ibu tidak boleh kosong
                  </span>
                )}
              </div>
            </div>
            <div className="mb-3">
              <label className="label-input mb-2">Status</label>
              <Controller
                control={control}
                defaultValue={getValues("IdStatus") || null}
                name="IdStatus"
                render={({ field, ref }) => (
                  <ReactSelect
                    inputRef={ref}
                    classNamePrefix="addl-class"
                    options={dataComboStatusOrtu}
                    value={getValues("IdStatus")}
                    onChange={(val) => {
                      field.onChange(val);
                    }}
                    isClearable={true}
                  />
                )}
                rules={{
                  required: false,
                }}
              />
              {errors.IdJenisPekerjaanIbu && (
                <span className="text-danger">Kolom wajib diisi</span>
              )}
            </div>
            <div className="col-md-12">
              <div className="mb-3">
                <label className="label-input mb-2">Warga Negara</label>
                <div className="wrapper-radiobtn">
                  <div className="form-check me-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="IdKewarganegaraan"
                      id="wnI"
                      value={1}
                      defaultChecked={getValues("IdKewarganegaraan") === "1"}
                      {...register("IdKewarganegaraan")}
                      onChange={() => setIsWna(false)}
                    />
                    <label className="form-check-label" htmlFor="wnI">
                      WNI
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="IdKewarganegaraan"
                      id="wnA"
                      value={2}
                      defaultChecked={getValues("IdKewarganegaraan") === "2"}
                      {...register("IdKewarganegaraan")}
                      onChange={() => setIsWna(true)}
                    />
                    <label className="form-check-label" htmlFor="wnA">
                      WNA
                    </label>
                  </div>
                </div>
                {errors.IdKewarganegaraan && (
                  <span className="alert-input">
                    Warga Negara tidak boleh kosong
                  </span>
                )}
              </div>
            </div>
            {isWna && (
              <>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="label-input mb-2">Asal Negara</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="input-costum form-control"
                        placeholder="Masukkan Asal Negara"
                        {...register("AsalNegara", { required: isWna })}
                      />
                    </div>
                    {errors.AsalNegara && (
                      <span className="alert-input">
                        Asal Negara tidak boleh kosong
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="label-input mb-2">
                      No. Izin Tinggal (KITAS)
                    </label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="input-costum form-control"
                        placeholder="Masukkan No. Izin Tinggal (KITAS)"
                        {...register("NoKitas", { required: isWna })}
                      />
                    </div>
                    {errors.NoKitas && (
                      <span className="alert-input">
                        No. Izin Tinggal (KITAS) tidak boleh kosong
                      </span>
                    )}
                  </div>
                </div>
              </>
            )}
            <div className="col-md-6">
              <div className="mb-3">
                <label className="label-input mb-2">Tanggal Lahir Ibu</label>
                <div className="input-group">
                  <Controller
                    control={control}
                    name="TanggalLahir"
                    render={({ field, value }) => (
                      <DatePicker
                        placeholderText="Masukkan Tanggal Lahir..."
                        selected={field.value}
                        value={value}
                        onChange={(val) => field.onChange(val)}
                        dateFormat="dd-MM-yyyy"
                        maxDate={new Date()}
                        customInput={
                          <ReactInputMask
                            mask="99-99-9999"
                            onChange={field.onChange}
                            value={field.value}
                            maskChar=""
                          />
                        }
                      />
                    )}
                    rules={{
                      required: true,
                    }}
                  />
                  {errors.TanggalLahir && (
                    <span className="alert-input">Kolom wajib diisi</span>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="label-input mb-2">Tempat Lahir</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="input-costum form-control"
                    placeholder="Masukkan Tempat Lahir"
                    {...register("TempatLahir", { required: true })}
                  />
                </div>
                {errors.TempatLahir && (
                  <span className="alert-input">
                    Tempat Lahir tidak boleh kosong
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="label-input mb-2">Pendidikan </label>
                <Controller
                  control={control}
                  defaultValue={getValues("IdJenisJenjangPendidikan") || null}
                  name="IdJenisJenjangPendidikan"
                  render={({ field, ref }) => (
                    <ReactSelect
                      inputRef={ref}
                      classNamePrefix="addl-class"
                      options={dataComboJenjangPendidikan}
                      value={getValues("IdJenisJenjangPendidikan")}
                      onChange={(val) => {
                        field.onChange(val);
                      }}
                      isClearable={true}
                    />
                  )}
                  rules={{
                    required: false,
                  }}
                />
                {errors.IdJenisJenjangPendidikan && (
                  <span className="text-danger">Kolom wajib diisi</span>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="label-input mb-2">Jenis Pekerjaan</label>
                <Controller
                  control={control}
                  defaultValue={getValues("IdJenisPekerjaan") || null}
                  name="IdJenisPekerjaan"
                  render={({ field, ref }) => (
                    <ReactSelect
                      inputRef={ref}
                      classNamePrefix="addl-class"
                      options={dataComboJenisPekerjaan}
                      value={getValues("IdJenisPekerjaan")}
                      onChange={(val) => {
                        field.onChange(val);
                      }}
                      isClearable={true}
                    />
                  )}
                  rules={{
                    required: false,
                  }}
                />
                {errors.IdJenisPekerjaan && (
                  <span className="text-danger">Kolom wajib diisi</span>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="label-input mb-2">Nama Instansi</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="input-costum form-control"
                    placeholder="Masukkan Nama Instansi"
                    {...register("NamaInstansi", { required: true })}
                  />
                </div>
                {errors.NamaInstansi && (
                  <span className="alert-input">
                    Nama Instansi tidak boleh kosong
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="label-input mb-2">
                  Penghasilan Rata-rata /bulan
                </label>
                <div className="input-group">
                  <input
                    type="number"
                    className="input-costum form-control"
                    placeholder="Masukkan Penghasilan"
                    {...register("Penghasilan", { required: true })}
                  />
                </div>
                {errors.Penghasilan && (
                  <span className="alert-input">
                    Penghasilan tidak boleh kosong
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="label-input mb-2">Nomor HP</label>
                <div className="input-group">
                  <input
                    type="number"
                    className="input-costum form-control"
                    placeholder="Masukkan Nomor HP"
                    {...register("NoHp", { required: true })}
                  />
                </div>
                {errors.NoHp && (
                  <span className="alert-input">
                    Nomor HP tidak boleh kosong
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="label-input mb-2">Email</label>
                <div className="input-group">
                  <input
                    type="email"
                    className="input-costum form-control"
                    placeholder="Masukkan Email"
                    {...register("Email", { required: true })}
                  />
                </div>
                {errors.Email && (
                  <span className="alert-input">Email tidak boleh kosong</span>
                )}
              </div>
            </div>

            {/* hr */}
            <div className="col-md-12">
              <hr style={{ color: "#afafaf" }} />
            </div>
            <div className="col-md-12">
              <div className="mb-3">
                <label className="label-input mb-2">Tempat Tinggal</label>
                <div className="wrapper-radiobtn">
                  <div className="form-check me-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="TempatTinggal"
                      id="TempatTinggalIbu"
                      {...register("TempatTinggal")}
                      onChange={(e) => onTempatTinggal(e.target.checked)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="TempatTinggalIbu"
                    >
                      Sama dengan ayah kandung
                    </label>
                  </div>
                </div>
                {errors.TempatTinggal && (
                  <span className="alert-input">
                    Tempat Tinggal tidak boleh kosong
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb-3">
                <label className="label-input mb-2">Domisili</label>
                <div className="wrapper-radiobtn">
                  <div className="form-check me-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="IdDomisili"
                      id="dmsDn"
                      value={1}
                      defaultChecked={getValues("IdDomisili") === "1"}
                      {...register("IdDomisili")}
                      onChange={() => setIsDomisili(1)}
                    />
                    <label className="form-check-label" htmlFor="dmsDn">
                      Dalam Negeri
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="IdDomisili"
                      id="dmsLn"
                      value={2}
                      defaultChecked={getValues("IdDomisili") === "2"}
                      {...register("IdDomisili")}
                      onChange={() => setIsDomisili(2)}
                    />
                    <label className="form-check-label" htmlFor="dmsLn">
                      Luar Negeri
                    </label>
                  </div>
                </div>
                {errors.IdKewarganegaraan && (
                  <span className="alert-input">
                    Warga Negara tidak boleh kosong
                  </span>
                )}
              </div>
            </div>
            {isDomisili === 1 ? (
              <>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label className="label-input mb-2">
                      Status Tempat Tinggal
                    </label>
                    <Controller
                      control={control}
                      defaultValue={getValues("IdJenisTempatTinggal") || null}
                      name="IdJenisTempatTinggal"
                      render={({ field, ref }) => (
                        <ReactSelect
                          inputRef={ref}
                          classNamePrefix="addl-class"
                          options={dataComboOrtuJenisTempatTinggal}
                          value={getValues("IdJenisTempatTinggal")}
                          onChange={(val) => {
                            field.onChange(val);
                          }}
                          isClearable={true}
                        />
                      )}
                      rules={{
                        required: false,
                      }}
                    />
                    {errors.IdJenisTempatTinggal && (
                      <span className="text-danger">Kolom wajib diisi</span>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="label-input mb-2">Provinsi</label>
                    <Controller
                      control={control}
                      defaultValue={getValues("IdProvinsi") || null}
                      name="IdProvinsi"
                      render={({ field, ref }) => (
                        <ReactSelect
                          inputRef={ref}
                          classNamePrefix="addl-class"
                          options={dataComboProvinsi}
                          value={getValues("IdProvinsi")}
                          onChange={(val) => {
                            field.onChange(val);
                            onWilayah(val.value, "Provinsi");
                          }}
                          isClearable={true}
                          isDisabled={dataAlamatAyah}
                        />
                      )}
                      rules={{
                        required: false,
                      }}
                    />
                    {errors.IdProvinsi && (
                      <span className="text-danger">Kolom wajib diisi</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="label-input mb-2">Kabupaten/Kota</label>
                    <Controller
                      control={control}
                      defaultValue={getValues("IdKabkot") || null}
                      name="IdKabkot"
                      render={({ field, ref }) => (
                        <ReactSelect
                          inputRef={ref}
                          classNamePrefix="addl-class"
                          options={dataComboKabkot}
                          value={getValues("IdKabkot")}
                          onChange={(val) => {
                            field.onChange(val);
                            onWilayah(val.value, "Kabkot");
                          }}
                          isClearable={true}
                          isDisabled={dataAlamatAyah}
                        />
                      )}
                      rules={{
                        required: false,
                      }}
                    />
                    {errors.IdKabkot && (
                      <span className="text-danger">Kolom wajib diisi</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="label-input mb-2">Kecamatan</label>
                    <Controller
                      control={control}
                      defaultValue={getValues("IdKecamatan") || null}
                      name="IdKecamatan"
                      render={({ field, ref }) => (
                        <ReactSelect
                          inputRef={ref}
                          classNamePrefix="addl-class"
                          options={dataComboKecamatan}
                          value={getValues("IdKecamatan")}
                          onChange={(val) => {
                            field.onChange(val);
                            onWilayah(val.value, "Kecamatan");
                          }}
                          isClearable={true}
                          isDisabled={dataAlamatAyah}
                        />
                      )}
                      rules={{
                        required: false,
                      }}
                    />
                    {errors.IdKecamatan && (
                      <span className="text-danger">Kolom wajib diisi</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="label-input mb-2">Kelurahan/Desa</label>
                    <Controller
                      control={control}
                      defaultValue={getValues("IdDesa") || null}
                      name="IdDesa"
                      render={({ field, ref }) => (
                        <ReactSelect
                          inputRef={ref}
                          classNamePrefix="addl-class"
                          options={dataComboDesa}
                          value={getValues("IdDesa")}
                          onChange={(val) => {
                            field.onChange(val);
                            onWilayah(val.value, "Desa");
                          }}
                          isClearable={true}
                          isDisabled={dataAlamatAyah}
                        />
                      )}
                      rules={{
                        required: false,
                      }}
                    />
                    {errors.IdDesa && (
                      <span className="text-danger">Kolom wajib diisi</span>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label className="label-input mb-2">Alamat Tunjuk</label>
                    <Controller
                      control={control}
                      defaultValue={getValues("KdAlamatTunjuk") || null}
                      name="KdAlamatTunjuk"
                      render={({ field, ref }) => (
                        <ReactSelect
                          inputRef={ref}
                          classNamePrefix="addl-class"
                          options={dataComboAlamatTunjuk}
                          value={getValues("KdAlamatTunjuk")}
                          onChange={(val) => {
                            field.onChange(val);
                          }}
                          isClearable={true}
                          isDisabled={dataAlamatAyah}
                        />
                      )}
                      rules={{
                        required: false,
                      }}
                    />
                    {errors.KdAlamatTunjuk && (
                      <span className="text-danger">Kolom wajib diisi</span>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label className="label-input mb-2">Alamat Tinggal</label>
                    <div className="input-group">
                      <textarea
                        name="Alamat"
                        rows={5}
                        className="input-costum form-control"
                        placeholder="Masukkan Alamat Tinggal"
                        {...register("Alamat", {
                          required: false,
                          // maxLength: 25,
                        })}
                        disabled={dataAlamatAyah}
                      />
                    </div>
                    {errors.Alamat && (
                      <span className="alert-input">
                        Alamat Tinggal tidak boleh kosong
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="label-input mb-2">RT</label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="input-costum form-control"
                        placeholder="Masukkan RT"
                        {...register("Rt", { required: false })}
                        disabled={dataAlamatAyah}
                      />
                    </div>
                    {errors.Rt && (
                      <span className="alert-input">RT tidak boleh kosong</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="label-input mb-2">RW</label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="input-costum form-control"
                        placeholder="Masukkan RW"
                        {...register("Rw", { required: false })}
                        disabled={dataAlamatAyah}
                      />
                    </div>
                    {errors.Rw && (
                      <span className="alert-input">RW tidak boleh kosong</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="label-input mb-2">Latitude</label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="input-costum form-control"
                        placeholder="Masukkan Latitude"
                        {...register("Latitude", { required: false })}
                        disabled={dataAlamatAyah}
                      />
                    </div>
                    {errors.Latitude && (
                      <span className="alert-input">
                        Latitude tidak boleh kosong
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="label-input mb-2">Longitude</label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="input-costum form-control"
                        placeholder="Masukkan Longitude"
                        {...register("Longitude", { required: false })}
                        disabled={dataAlamatAyah}
                      />
                    </div>
                    {errors.Longitude && (
                      <span className="alert-input">
                        Longitude tidak boleh kosong
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="label-input mb-2">Dusun</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="input-costum form-control"
                        placeholder="Masukkan Dusun"
                        {...register("Dusun", { required: false })}
                        disabled={dataAlamatAyah}
                      />
                    </div>
                    {errors.Dusun && (
                      <span className="alert-input">
                        Dusun tidak boleh kosong
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="label-input mb-2">Kode Pos</label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="input-costum form-control"
                        placeholder="Masukkan Kode Pos"
                        {...register("KdPos", { required: false })}
                        disabled={dataAlamatAyah}
                      />
                    </div>
                    {errors.KdPos && (
                      <span className="alert-input">
                        Kode Pos tidak boleh kosong
                      </span>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label className="label-input mb-2">Alamat </label>
                    <div className="input-group">
                      <textarea
                        name="Address"
                        rows={5}
                        className="input-costum form-control"
                        placeholder="Masukkan Alamat "
                        {...register("Alamat", {
                          required: false,
                        })}
                      />
                    </div>
                    {errors.Alamat && (
                      <span className="alert-input">
                        Alamat tidak boleh kosong
                      </span>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
          <div>
            <button type="submit" className="btn btn-primary w-100 mt-3">
              Simpan Pembaruan
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const ItemFormFather = () => {
  const { dataInfoAyah, dataAlamatAyah } = useSelector(
    (state) => state.userReducer
  );
  const {
    dataComboJenisPekerjaan,
    dataComboJenjangPendidikan,
    dataComboStatusOrtu,
    dataComboProvinsi,
    dataComboKabkot,
    dataComboKecamatan,
    dataComboDesa,
    dataComboOrtuJenisTempatTinggal,
    dataComboAlamatTunjuk,
  } = useSelector((state) => state.comboReducer);
  const dispatch = useDispatch();
  const {
    control,
    getValues,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const [isWna, setIsWna] = useState(false);
  const [isDomisili, setIsDomisili] = useState(1);

  useEffect(() => {
    setValue("IdSiswaOrtu", dataInfoAyah?.IdSiswaOrtu);
    setValue("Nik", dataInfoAyah?.Nik);
    setValue("Nama", dataInfoAyah?.Nama);
    setValue(
      "IdStatus",
      dataInfoAyah?.Status?.Nama && {
        value: dataInfoAyah?.Status?.Id,
        label: dataInfoAyah?.Status?.Nama,
      }
    );
    setValue("IdKewarganegaraan", `${dataInfoAyah?.Kewarganegaraan?.Id || 1}`);
    if (dataInfoAyah?.Kewarganegaraan?.Id === 2) setIsWna(true);
    setValue("AsalNegara", dataInfoAyah?.AsalNegara);
    setValue("NoKitas", dataInfoAyah?.NoKitas);
    var TanggalLahir = new Date("1900-01-01");
    if (dataInfoAyah?.TanggalLahir)
      TanggalLahir = new Date(FUNCDateDmytoYmd(dataInfoAyah?.TanggalLahir));
    setValue("TanggalLahir", TanggalLahir);
    setValue("TempatLahir", dataInfoAyah?.TempatLahir);
    setValue(
      "IdJenisJenjangPendidikan",
      dataInfoAyah?.JenisJenjangPendidikan?.Nama && {
        value: dataInfoAyah?.JenisJenjangPendidikan?.Id,
        label: dataInfoAyah?.JenisJenjangPendidikan?.Nama,
      }
    );
    setValue(
      "IdJenisPekerjaan",
      dataInfoAyah?.JenisPekerjaan?.Nama && {
        value: dataInfoAyah?.JenisPekerjaan?.Id,
        label: dataInfoAyah?.JenisPekerjaan?.Nama,
      }
    );
    setValue("NamaInstansi", dataInfoAyah?.NamaInstansi);
    setValue("Penghasilan", dataInfoAyah?.Penghasilan);
    setValue("IdDomisili", `${dataInfoAyah?.Domisili?.Id}`);
    setValue(
      "IdJenisTempatTinggal",
      dataInfoAyah?.JenisTempatTinggal?.Nama && {
        value: dataInfoAyah?.JenisTempatTinggal?.Id,
        label: dataInfoAyah?.JenisTempatTinggal?.Nama,
      }
    );
    setValue(
      "IdProvinsi",
      dataInfoAyah?.Alamat?.Provinsi?.Nama && {
        value: dataInfoAyah?.Alamat?.Provinsi?.Id,
        label: dataInfoAyah?.Alamat?.Provinsi?.Nama,
      }
    );
    setValue(
      "IdKabkot",
      dataInfoAyah?.Alamat?.Kabkot?.Nama && {
        value: dataInfoAyah?.Alamat?.Kabkot?.Id,
        label: dataInfoAyah?.Alamat?.Kabkot?.Nama,
      }
    );
    setValue(
      "IdKecamatan",
      dataInfoAyah?.Alamat?.Kecamatan?.Nama && {
        value: dataInfoAyah?.Alamat?.Kecamatan?.Id,
        label: dataInfoAyah?.Alamat?.Kecamatan?.Nama,
      }
    );
    setValue(
      "IdDesa",
      dataInfoAyah?.Alamat?.Desa?.Nama && {
        value: dataInfoAyah?.Alamat?.Desa?.Id,
        label: dataInfoAyah?.Alamat?.Desa?.Nama,
      }
    );
    setValue(
      "KdAlamatTunjuk",
      dataInfoAyah?.Alamat?.AlamatTunjuk?.Nama && {
        value: dataInfoAyah?.Alamat?.AlamatTunjuk?.Kode,
        label: dataInfoAyah?.Alamat?.AlamatTunjuk?.Nama,
      }
    );
    setValue("Alamat", dataInfoAyah?.Alamat?.Alamat);
    setValue("Longitude", dataInfoAyah?.Alamat?.Longitude);
    setValue("Latitude", dataInfoAyah?.Alamat?.Latitude);
    setValue("KdPos", dataInfoAyah?.Alamat?.KdPos);
    setValue("Rt", dataInfoAyah?.Alamat?.Rt);
    setValue("Rw", dataInfoAyah?.Alamat?.Rw);
    setValue("Dusun", dataInfoAyah?.Alamat?.Dusun);
    setValue("NoHp", dataInfoAyah?.NoHp);
    setValue("Email", dataInfoAyah?.Email);
  }, [dataInfoAyah]);

  const onWilayah = (id, type) => {
    if (type === "Provinsi") {
      setValue("IdKabkot", "");
      setValue("IdKecamatan", "");
      setValue("IdDesa", "");
      dispatch(setDataComboKabkot("DATA_COMBO_KABKOT", id));
      return;
    }
    if (type === "Kabkot") {
      setValue("IdKecamatan", "");
      setValue("IdDesa", "");
      dispatch(setDataComboKecamatan("DATA_COMBO_KECAMATAN", id));
      return;
    }
    if (type === "Kecamatan") {
      setValue("IdDesa", "");
      dispatch(setDataComboDesa("DATA_COMBO_DESA", id));
      return;
    }
  };

  const onTempatTinggal = (isTrue) => {
    if (isTrue) {
      dispatch(setDataAlamatAyah(setValue));
      dispatch({ type: "DATA_ALAMAT_AYAH", payload: true });
    } else {
      dispatch({ type: "DATA_ALAMAT_AYAH", payload: false });
    }
  };

  const btnSave = (data) => dispatch(apiInfoUpdateDataAyah(data));

  return (
    <div className="card-data-siswa mt-3">
      <div className="content-data-siswa">
        <form onSubmit={handleSubmit(btnSave)}>
          <div className="row">
            <div className="col-md-12">
              <div className="mb-3">
                <label className="label-input mb-2">NIK Ayah</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="input-costum form-control"
                    placeholder="Masukkan NIK Ayah"
                    {...register("Nik", { required: true })}
                  />
                </div>
                {errors.Nik && (
                  <span className="alert-input">
                    NIK Ayah tidak boleh kosong
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb-3">
                <label className="label-input mb-2">Nama Ayah</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="input-costum form-control"
                    placeholder="Masukkan Nama Ayah"
                    {...register("Nama", { required: true })}
                  />
                </div>
                {errors.Nama && (
                  <span className="alert-input">
                    Nama Ayah tidak boleh kosong
                  </span>
                )}
              </div>
            </div>
            <div className="mb-3">
              <label className="label-input mb-2">Status</label>
              <Controller
                control={control}
                defaultValue={getValues("IdStatus") || null}
                name="IdStatus"
                render={({ field, ref }) => (
                  <ReactSelect
                    inputRef={ref}
                    classNamePrefix="addl-class"
                    options={dataComboStatusOrtu}
                    value={getValues("IdStatus")}
                    onChange={(val) => {
                      field.onChange(val);
                    }}
                    isClearable={true}
                  />
                )}
                rules={{
                  required: false,
                }}
              />
              {errors.IdJenisPekerjaanIbu && (
                <span className="text-danger">Kolom wajib diisi</span>
              )}
            </div>
            <div className="col-md-12">
              <div className="mb-3">
                <label className="label-input mb-2">Warga Negara</label>
                <div className="wrapper-radiobtn">
                  <div className="form-check me-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="IdKewarganegaraan"
                      id="wnI"
                      value={1}
                      defaultChecked={getValues("IdKewarganegaraan") === "1"}
                      {...register("IdKewarganegaraan")}
                      onChange={() => setIsWna(false)}
                    />
                    <label className="form-check-label" htmlFor="wnI">
                      WNI
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="IdKewarganegaraan"
                      id="wnA"
                      value={2}
                      defaultChecked={getValues("IdKewarganegaraan") === "2"}
                      {...register("IdKewarganegaraan")}
                      onChange={() => setIsWna(true)}
                    />
                    <label className="form-check-label" htmlFor="wnA">
                      WNA
                    </label>
                  </div>
                </div>
                {errors.IdKewarganegaraan && (
                  <span className="alert-input">
                    Warga Negara tidak boleh kosong
                  </span>
                )}
              </div>
            </div>
            {isWna && (
              <>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="label-input mb-2">Asal Negara</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="input-costum form-control"
                        placeholder="Masukkan Asal Negara"
                        {...register("AsalNegara", { required: isWna })}
                      />
                    </div>
                    {errors.AsalNegara && (
                      <span className="alert-input">
                        Asal Negara tidak boleh kosong
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="label-input mb-2">
                      No. Izin Tinggal (KITAS)
                    </label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="input-costum form-control"
                        placeholder="Masukkan No. Izin Tinggal (KITAS)"
                        {...register("NoKitas", { required: isWna })}
                      />
                    </div>
                    {errors.NoKitas && (
                      <span className="alert-input">
                        No. Izin Tinggal (KITAS) tidak boleh kosong
                      </span>
                    )}
                  </div>
                </div>
              </>
            )}
            <div className="col-md-6">
              <div className="mb-3">
                <label className="label-input mb-2">Tanggal Lahir Ayah</label>
                <div className="input-group">
                  <Controller
                    control={control}
                    name="TanggalLahir"
                    render={({ field, value }) => (
                      <DatePicker
                        placeholderText="Masukkan Tanggal Lahir..."
                        selected={field.value}
                        value={value}
                        onChange={(val) => field.onChange(val)}
                        dateFormat="dd-MM-yyyy"
                        maxDate={new Date()}
                        customInput={
                          <ReactInputMask
                            mask="99-99-9999"
                            onChange={field.onChange}
                            value={field.value}
                            maskChar=""
                          />
                        }
                      />
                    )}
                    rules={{
                      required: true,
                    }}
                  />
                  {errors.TanggalLahir && (
                    <span className="alert-input">Kolom wajib diisi</span>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="label-input mb-2">Tempat Lahir</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="input-costum form-control"
                    placeholder="Masukkan Tempat Lahir"
                    {...register("TempatLahir", { required: true })}
                  />
                </div>
                {errors.TempatLahir && (
                  <span className="alert-input">
                    Tempat Lahir tidak boleh kosong
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="label-input mb-2">Pendidikan </label>
                <Controller
                  control={control}
                  defaultValue={getValues("IdJenisJenjangPendidikan") || null}
                  name="IdJenisJenjangPendidikan"
                  render={({ field, ref }) => (
                    <ReactSelect
                      inputRef={ref}
                      classNamePrefix="addl-class"
                      options={dataComboJenjangPendidikan}
                      value={getValues("IdJenisJenjangPendidikan")}
                      onChange={(val) => {
                        field.onChange(val);
                      }}
                      isClearable={true}
                    />
                  )}
                  rules={{
                    required: false,
                  }}
                />
                {errors.IdJenisJenjangPendidikan && (
                  <span className="text-danger">Kolom wajib diisi</span>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="label-input mb-2">Jenis Pekerjaan</label>
                <Controller
                  control={control}
                  defaultValue={getValues("IdJenisPekerjaan") || null}
                  name="IdJenisPekerjaan"
                  render={({ field, ref }) => (
                    <ReactSelect
                      inputRef={ref}
                      classNamePrefix="addl-class"
                      options={dataComboJenisPekerjaan}
                      value={getValues("IdJenisPekerjaan")}
                      onChange={(val) => {
                        field.onChange(val);
                      }}
                      isClearable={true}
                    />
                  )}
                  rules={{
                    required: false,
                  }}
                />
                {errors.IdJenisPekerjaan && (
                  <span className="text-danger">Kolom wajib diisi</span>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="label-input mb-2">Nama Instansi</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="input-costum form-control"
                    placeholder="Masukkan Nama Instansi"
                    {...register("NamaInstansi", { required: true })}
                  />
                </div>
                {errors.NamaInstansi && (
                  <span className="alert-input">
                    Nama Instansi tidak boleh kosong
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="label-input mb-2">
                  Penghasilan Rata-rata /bulan
                </label>
                <div className="input-group">
                  <input
                    type="number"
                    className="input-costum form-control"
                    placeholder="Masukkan Penghasilan"
                    {...register("Penghasilan", { required: true })}
                  />
                </div>
                {errors.Penghasilan && (
                  <span className="alert-input">
                    Penghasilan tidak boleh kosong
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="label-input mb-2">Nomor HP</label>
                <div className="input-group">
                  <input
                    type="number"
                    className="input-costum form-control"
                    placeholder="Masukkan Nomor HP"
                    {...register("NoHp", { required: true })}
                  />
                </div>
                {errors.NoHp && (
                  <span className="alert-input">
                    Nomor HP tidak boleh kosong
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="label-input mb-2">Email</label>
                <div className="input-group">
                  <input
                    type="email"
                    className="input-costum form-control"
                    placeholder="Masukkan Email"
                    {...register("Email", { required: true })}
                  />
                </div>
                {errors.Email && (
                  <span className="alert-input">Email tidak boleh kosong</span>
                )}
              </div>
            </div>

            {/* hr */}
            <div className="col-md-12">
              <hr style={{ color: "#afafaf" }} />
            </div>
            <div className="col-md-12">
              <div className="mb-3">
                <label className="label-input mb-2">Domisili</label>
                <div className="wrapper-radiobtn">
                  <div className="form-check me-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="IdDomisili"
                      id="dmsDn"
                      value={1}
                      defaultChecked={getValues("IdDomisili") === "1"}
                      {...register("IdDomisili")}
                      onChange={() => setIsDomisili(1)}
                    />
                    <label className="form-check-label" htmlFor="dmsDn">
                      Dalam Negeri
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="IdDomisili"
                      id="dmsLn"
                      value={2}
                      defaultChecked={getValues("IdDomisili") === "2"}
                      {...register("IdDomisili")}
                      onChange={() => setIsDomisili(2)}
                    />
                    <label className="form-check-label" htmlFor="dmsLn">
                      Luar Negeri
                    </label>
                  </div>
                </div>
                {errors.IdKewarganegaraan && (
                  <span className="alert-input">
                    Warga Negara tidak boleh kosong
                  </span>
                )}
              </div>
            </div>
            {isDomisili === 1 ? (
              <>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label className="label-input mb-2">
                      Status Tempat Tinggal
                    </label>
                    <Controller
                      control={control}
                      defaultValue={getValues("IdJenisTempatTinggal") || null}
                      name="IdJenisTempatTinggal"
                      render={({ field, ref }) => (
                        <ReactSelect
                          inputRef={ref}
                          classNamePrefix="addl-class"
                          options={dataComboOrtuJenisTempatTinggal}
                          value={getValues("IdJenisTempatTinggal")}
                          onChange={(val) => {
                            field.onChange(val);
                          }}
                          isClearable={true}
                        />
                      )}
                      rules={{
                        required: false,
                      }}
                    />
                    {errors.IdJenisTempatTinggal && (
                      <span className="text-danger">Kolom wajib diisi</span>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="label-input mb-2">Provinsi</label>
                    <Controller
                      control={control}
                      defaultValue={getValues("IdProvinsi") || null}
                      name="IdProvinsi"
                      render={({ field, ref }) => (
                        <ReactSelect
                          inputRef={ref}
                          classNamePrefix="addl-class"
                          options={dataComboProvinsi}
                          value={getValues("IdProvinsi")}
                          onChange={(val) => {
                            field.onChange(val);
                            onWilayah(val.value, "Provinsi");
                          }}
                          isClearable={true}
                          isDisabled={dataAlamatAyah}
                        />
                      )}
                      rules={{
                        required: false,
                      }}
                    />
                    {errors.IdProvinsi && (
                      <span className="text-danger">Kolom wajib diisi</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="label-input mb-2">Kabupaten/Kota</label>
                    <Controller
                      control={control}
                      defaultValue={getValues("IdKabkot") || null}
                      name="IdKabkot"
                      render={({ field, ref }) => (
                        <ReactSelect
                          inputRef={ref}
                          classNamePrefix="addl-class"
                          options={dataComboKabkot}
                          value={getValues("IdKabkot")}
                          onChange={(val) => {
                            field.onChange(val);
                            onWilayah(val.value, "Kabkot");
                          }}
                          isClearable={true}
                          isDisabled={dataAlamatAyah}
                        />
                      )}
                      rules={{
                        required: false,
                      }}
                    />
                    {errors.IdKabkot && (
                      <span className="text-danger">Kolom wajib diisi</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="label-input mb-2">Kecamatan</label>
                    <Controller
                      control={control}
                      defaultValue={getValues("IdKecamatan") || null}
                      name="IdKecamatan"
                      render={({ field, ref }) => (
                        <ReactSelect
                          inputRef={ref}
                          classNamePrefix="addl-class"
                          options={dataComboKecamatan}
                          value={getValues("IdKecamatan")}
                          onChange={(val) => {
                            field.onChange(val);
                            onWilayah(val.value, "Kecamatan");
                          }}
                          isClearable={true}
                          isDisabled={dataAlamatAyah}
                        />
                      )}
                      rules={{
                        required: false,
                      }}
                    />
                    {errors.IdKecamatan && (
                      <span className="text-danger">Kolom wajib diisi</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="label-input mb-2">Kelurahan/Desa</label>
                    <Controller
                      control={control}
                      defaultValue={getValues("IdDesa") || null}
                      name="IdDesa"
                      render={({ field, ref }) => (
                        <ReactSelect
                          inputRef={ref}
                          classNamePrefix="addl-class"
                          options={dataComboDesa}
                          value={getValues("IdDesa")}
                          onChange={(val) => {
                            field.onChange(val);
                            onWilayah(val.value, "Desa");
                          }}
                          isClearable={true}
                          isDisabled={dataAlamatAyah}
                        />
                      )}
                      rules={{
                        required: false,
                      }}
                    />
                    {errors.IdDesa && (
                      <span className="text-danger">Kolom wajib diisi</span>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label className="label-input mb-2">Alamat Tunjuk</label>
                    <Controller
                      control={control}
                      defaultValue={getValues("KdAlamatTunjuk") || null}
                      name="KdAlamatTunjuk"
                      render={({ field, ref }) => (
                        <ReactSelect
                          inputRef={ref}
                          classNamePrefix="addl-class"
                          options={dataComboAlamatTunjuk}
                          value={getValues("KdAlamatTunjuk")}
                          onChange={(val) => {
                            field.onChange(val);
                          }}
                          isClearable={true}
                          isDisabled={dataAlamatAyah}
                        />
                      )}
                      rules={{
                        required: false,
                      }}
                    />
                    {errors.KdAlamatTunjuk && (
                      <span className="text-danger">Kolom wajib diisi</span>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label className="label-input mb-2">Alamat Tinggal</label>
                    <div className="input-group">
                      <textarea
                        name="Alamat"
                        rows={5}
                        className="input-costum form-control"
                        placeholder="Masukkan Alamat Tinggal"
                        {...register("Alamat", {
                          required: false,
                          // maxLength: 25,
                        })}
                        disabled={dataAlamatAyah}
                      />
                    </div>
                    {errors.Alamat && (
                      <span className="alert-input">
                        Alamat Tinggal tidak boleh kosong
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="label-input mb-2">RT</label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="input-costum form-control"
                        placeholder="Masukkan RT"
                        {...register("Rt", { required: false })}
                        disabled={dataAlamatAyah}
                      />
                    </div>
                    {errors.Rt && (
                      <span className="alert-input">RT tidak boleh kosong</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="label-input mb-2">RW</label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="input-costum form-control"
                        placeholder="Masukkan RW"
                        {...register("Rw", { required: false })}
                        disabled={dataAlamatAyah}
                      />
                    </div>
                    {errors.Rw && (
                      <span className="alert-input">RW tidak boleh kosong</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="label-input mb-2">Latitude</label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="input-costum form-control"
                        placeholder="Masukkan Latitude"
                        {...register("Latitude", { required: false })}
                        disabled={dataAlamatAyah}
                      />
                    </div>
                    {errors.Latitude && (
                      <span className="alert-input">
                        Latitude tidak boleh kosong
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="label-input mb-2">Longitude</label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="input-costum form-control"
                        placeholder="Masukkan Longitude"
                        {...register("Longitude", { required: false })}
                        disabled={dataAlamatAyah}
                      />
                    </div>
                    {errors.Longitude && (
                      <span className="alert-input">
                        Longitude tidak boleh kosong
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="label-input mb-2">Dusun</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="input-costum form-control"
                        placeholder="Masukkan Dusun"
                        {...register("Dusun", { required: false })}
                        disabled={dataAlamatAyah}
                      />
                    </div>
                    {errors.Dusun && (
                      <span className="alert-input">
                        Dusun tidak boleh kosong
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="label-input mb-2">Kode Pos</label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="input-costum form-control"
                        placeholder="Masukkan Kode Pos"
                        {...register("KdPos", { required: false })}
                        disabled={dataAlamatAyah}
                      />
                    </div>
                    {errors.KdPos && (
                      <span className="alert-input">
                        Kode Pos tidak boleh kosong
                      </span>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label className="label-input mb-2">Alamat </label>
                    <div className="input-group">
                      <textarea
                        name="Address"
                        rows={5}
                        className="input-costum form-control"
                        placeholder="Masukkan Alamat "
                        {...register("Alamat", {
                          required: false,
                        })}
                      />
                    </div>
                    {errors.Alamat && (
                      <span className="alert-input">
                        Alamat tidak boleh kosong
                      </span>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
          <div>
            <button type="submit" className="btn btn-primary w-100 mt-3">
              Simpan Pembaruan
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const ItemFormWali = () => {
  const { dataInfoWali, dataAlamatAyah } = useSelector(
    (state) => state.userReducer
  );
  const {
    dataComboJenisPekerjaan,
    dataComboJenjangPendidikan,
    dataComboStatusOrtu,
    dataComboProvinsi,
    dataComboKabkot,
    dataComboKecamatan,
    dataComboDesa,
    dataComboOrtuJenisTempatTinggal,
    dataComboAlamatTunjuk,
  } = useSelector((state) => state.comboReducer);
  const dispatch = useDispatch();
  const {
    control,
    getValues,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const [isWna, setIsWna] = useState(false);
  const [isDomisili, setIsDomisili] = useState(1);

  useEffect(() => {
    setValue("IdSiswaOrtu", dataInfoWali?.IdSiswaOrtu);
    setValue("Nik", dataInfoWali?.Nik);
    setValue("Nama", dataInfoWali?.Nama);
    setValue(
      "IdStatus",
      dataInfoWali?.Status?.Nama && {
        value: dataInfoWali?.Status?.Id,
        label: dataInfoWali?.Status?.Nama,
      }
    );
    setValue("IdKewarganegaraan", `${dataInfoWali?.Kewarganegaraan?.Id || 1}`);
    if (dataInfoWali?.Kewarganegaraan?.Id === 2) setIsWna(true);
    setValue("AsalNegara", dataInfoWali?.AsalNegara);
    setValue("NoKitas", dataInfoWali?.NoKitas);
    var TanggalLahir = new Date("1900-01-01");
    if (dataInfoWali?.TanggalLahir)
      TanggalLahir = new Date(FUNCDateDmytoYmd(dataInfoWali?.TanggalLahir));
    setValue("TanggalLahir", TanggalLahir);
    setValue("TempatLahir", dataInfoWali?.TempatLahir);
    setValue(
      "IdJenisJenjangPendidikan",
      dataInfoWali?.JenisJenjangPendidikan?.Nama && {
        value: dataInfoWali?.JenisJenjangPendidikan?.Id,
        label: dataInfoWali?.JenisJenjangPendidikan?.Nama,
      }
    );
    setValue(
      "IdJenisPekerjaan",
      dataInfoWali?.JenisPekerjaan?.Nama && {
        value: dataInfoWali?.JenisPekerjaan?.Id,
        label: dataInfoWali?.JenisPekerjaan?.Nama,
      }
    );
    setValue("NamaInstansi", dataInfoWali?.NamaInstansi);
    setValue("Penghasilan", dataInfoWali?.Penghasilan);
    setValue("IdDomisili", `${dataInfoWali?.Domisili?.Id}`);
    setValue(
      "IdJenisTempatTinggal",
      dataInfoWali?.JenisTempatTinggal?.Nama && {
        value: dataInfoWali?.JenisTempatTinggal?.Id,
        label: dataInfoWali?.JenisTempatTinggal?.Nama,
      }
    );
    setValue(
      "IdProvinsi",
      dataInfoWali?.Alamat?.Provinsi?.Nama && {
        value: dataInfoWali?.Alamat?.Provinsi?.Id,
        label: dataInfoWali?.Alamat?.Provinsi?.Nama,
      }
    );
    setValue(
      "IdKabkot",
      dataInfoWali?.Alamat?.Kabkot?.Nama && {
        value: dataInfoWali?.Alamat?.Kabkot?.Id,
        label: dataInfoWali?.Alamat?.Kabkot?.Nama,
      }
    );
    setValue(
      "IdKecamatan",
      dataInfoWali?.Alamat?.Kecamatan?.Nama && {
        value: dataInfoWali?.Alamat?.Kecamatan?.Id,
        label: dataInfoWali?.Alamat?.Kecamatan?.Nama,
      }
    );
    setValue(
      "IdDesa",
      dataInfoWali?.Alamat?.Desa?.Nama && {
        value: dataInfoWali?.Alamat?.Desa?.Id,
        label: dataInfoWali?.Alamat?.Desa?.Nama,
      }
    );
    setValue(
      "KdAlamatTunjuk",
      dataInfoWali?.Alamat?.AlamatTunjuk?.Nama && {
        value: dataInfoWali?.Alamat?.AlamatTunjuk?.Kode,
        label: dataInfoWali?.Alamat?.AlamatTunjuk?.Nama,
      }
    );
    setValue("Alamat", dataInfoWali?.Alamat?.Alamat);
    setValue("Longitude", dataInfoWali?.Alamat?.Longitude);
    setValue("Latitude", dataInfoWali?.Alamat?.Latitude);
    setValue("KdPos", dataInfoWali?.Alamat?.KdPos);
    setValue("Rt", dataInfoWali?.Alamat?.Rt);
    setValue("Rw", dataInfoWali?.Alamat?.Rw);
    setValue("Dusun", dataInfoWali?.Alamat?.Dusun);
    setValue("NoHp", dataInfoWali?.NoHp);
    setValue("Email", dataInfoWali?.Email);
  }, [dataInfoWali]);

  const onWilayah = (id, type) => {
    if (type === "Provinsi") {
      setValue("IdKabkot", "");
      setValue("IdKecamatan", "");
      setValue("IdDesa", "");
      dispatch(setDataComboKabkot("DATA_COMBO_KABKOT", id));
      return;
    }
    if (type === "Kabkot") {
      setValue("IdKecamatan", "");
      setValue("IdDesa", "");
      dispatch(setDataComboKecamatan("DATA_COMBO_KECAMATAN", id));
      return;
    }
    if (type === "Kecamatan") {
      setValue("IdDesa", "");
      dispatch(setDataComboDesa("DATA_COMBO_DESA", id));
      return;
    }
  };

  const onTempatTinggal = (isTrue) => {
    if (isTrue) {
      dispatch(setDataAlamatAyah(setValue));
      dispatch({ type: "DATA_ALAMAT_AYAH", payload: true });
    } else {
      dispatch({ type: "DATA_ALAMAT_AYAH", payload: false });
    }
  };

  const btnSave = (data) => {
    if (dataInfoWali === null) {
      dispatch(apiInfoAddDataWali(data));
    } else {
      dispatch(apiInfoUpdateDataWali(data));
    }
  };

  return (
    <div className="card-data-siswa mt-3">
      <div className="content-data-siswa">
        <form onSubmit={handleSubmit(btnSave)}>
          <div className="row">
            <div className="col-md-12">
              <div className="mb-3">
                <label className="label-input mb-2">NIK Wali</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="input-costum form-control"
                    placeholder="Masukkan NIK Wali"
                    {...register("Nik", { required: true })}
                  />
                </div>
                {errors.Nik && (
                  <span className="alert-input">
                    NIK Wali tidak boleh kosong
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb-3">
                <label className="label-input mb-2">Nama Wali</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="input-costum form-control"
                    placeholder="Masukkan Nama Wali"
                    {...register("Nama", { required: true })}
                  />
                </div>
                {errors.Nama && (
                  <span className="alert-input">
                    Nama Wali tidak boleh kosong
                  </span>
                )}
              </div>
            </div>
            <div className="mb-3">
              <label className="label-input mb-2">Status</label>
              <Controller
                control={control}
                defaultValue={getValues("IdStatus") || null}
                name="IdStatus"
                render={({ field, ref }) => (
                  <ReactSelect
                    inputRef={ref}
                    classNamePrefix="addl-class"
                    options={dataComboStatusOrtu}
                    value={getValues("IdStatus")}
                    onChange={(val) => {
                      field.onChange(val);
                    }}
                    isClearable={true}
                  />
                )}
                rules={{
                  required: false,
                }}
              />
              {errors.IdJenisPekerjaanIbu && (
                <span className="text-danger">Kolom wajib diisi</span>
              )}
            </div>
            <div className="col-md-12">
              <div className="mb-3">
                <label className="label-input mb-2">Warga Negara</label>
                <div className="wrapper-radiobtn">
                  <div className="form-check me-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="IdKewarganegaraan"
                      id="wnI"
                      value={1}
                      defaultChecked={getValues("IdKewarganegaraan") === "1"}
                      {...register("IdKewarganegaraan")}
                      onChange={() => setIsWna(false)}
                    />
                    <label className="form-check-label" htmlFor="wnI">
                      WNI
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="IdKewarganegaraan"
                      id="wnA"
                      value={2}
                      defaultChecked={getValues("IdKewarganegaraan") === "2"}
                      {...register("IdKewarganegaraan")}
                      onChange={() => setIsWna(true)}
                    />
                    <label className="form-check-label" htmlFor="wnA">
                      WNA
                    </label>
                  </div>
                </div>
                {errors.IdKewarganegaraan && (
                  <span className="alert-input">
                    Warga Negara tidak boleh kosong
                  </span>
                )}
              </div>
            </div>
            {isWna && (
              <>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="label-input mb-2">Asal Negara</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="input-costum form-control"
                        placeholder="Masukkan Asal Negara"
                        {...register("AsalNegara", { required: isWna })}
                      />
                    </div>
                    {errors.AsalNegara && (
                      <span className="alert-input">
                        Asal Negara tidak boleh kosong
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="label-input mb-2">
                      No. Izin Tinggal (KITAS)
                    </label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="input-costum form-control"
                        placeholder="Masukkan No. Izin Tinggal (KITAS)"
                        {...register("NoKitas", { required: isWna })}
                      />
                    </div>
                    {errors.NoKitas && (
                      <span className="alert-input">
                        No. Izin Tinggal (KITAS) tidak boleh kosong
                      </span>
                    )}
                  </div>
                </div>
              </>
            )}
            <div className="col-md-6">
              <div className="mb-3">
                <label className="label-input mb-2">Tanggal Lahir Wali</label>
                <div className="input-group">
                  <Controller
                    control={control}
                    name="TanggalLahir"
                    render={({ field, value }) => (
                      <DatePicker
                        placeholderText="Masukkan Tanggal Lahir..."
                        selected={field.value}
                        value={value}
                        onChange={(val) => field.onChange(val)}
                        dateFormat="dd-MM-yyyy"
                        maxDate={new Date()}
                        customInput={
                          <ReactInputMask
                            mask="99-99-9999"
                            onChange={field.onChange}
                            value={field.value}
                            maskChar=""
                          />
                        }
                      />
                    )}
                    rules={{
                      required: true,
                    }}
                  />
                  {errors.TanggalLahir && (
                    <span className="alert-input">Kolom wajib diisi</span>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="label-input mb-2">Tempat Lahir</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="input-costum form-control"
                    placeholder="Masukkan Tempat Lahir"
                    {...register("TempatLahir", { required: true })}
                  />
                </div>
                {errors.TempatLahir && (
                  <span className="alert-input">
                    Tempat Lahir tidak boleh kosong
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="label-input mb-2">Pendidikan </label>
                <Controller
                  control={control}
                  defaultValue={getValues("IdJenisJenjangPendidikan") || null}
                  name="IdJenisJenjangPendidikan"
                  render={({ field, ref }) => (
                    <ReactSelect
                      inputRef={ref}
                      classNamePrefix="addl-class"
                      options={dataComboJenjangPendidikan}
                      value={getValues("IdJenisJenjangPendidikan")}
                      onChange={(val) => {
                        field.onChange(val);
                      }}
                      isClearable={true}
                    />
                  )}
                  rules={{
                    required: false,
                  }}
                />
                {errors.IdJenisJenjangPendidikan && (
                  <span className="text-danger">Kolom wajib diisi</span>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="label-input mb-2">Jenis Pekerjaan</label>
                <Controller
                  control={control}
                  defaultValue={getValues("IdJenisPekerjaan") || null}
                  name="IdJenisPekerjaan"
                  render={({ field, ref }) => (
                    <ReactSelect
                      inputRef={ref}
                      classNamePrefix="addl-class"
                      options={dataComboJenisPekerjaan}
                      value={getValues("IdJenisPekerjaan")}
                      onChange={(val) => {
                        field.onChange(val);
                      }}
                      isClearable={true}
                    />
                  )}
                  rules={{
                    required: false,
                  }}
                />
                {errors.IdJenisPekerjaan && (
                  <span className="text-danger">Kolom wajib diisi</span>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="label-input mb-2">Nama Instansi</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="input-costum form-control"
                    placeholder="Masukkan Nama Instansi"
                    {...register("NamaInstansi", { required: true })}
                  />
                </div>
                {errors.NamaInstansi && (
                  <span className="alert-input">
                    Nama Instansi tidak boleh kosong
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="label-input mb-2">
                  Penghasilan Rata-rata /bulan
                </label>
                <div className="input-group">
                  <input
                    type="number"
                    className="input-costum form-control"
                    placeholder="Masukkan Penghasilan"
                    {...register("Penghasilan", { required: true })}
                  />
                </div>
                {errors.Penghasilan && (
                  <span className="alert-input">
                    Penghasilan tidak boleh kosong
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="label-input mb-2">Nomor HP</label>
                <div className="input-group">
                  <input
                    type="number"
                    className="input-costum form-control"
                    placeholder="Masukkan Nomor HP"
                    {...register("NoHp", { required: true })}
                  />
                </div>
                {errors.NoHp && (
                  <span className="alert-input">
                    Nomor HP tidak boleh kosong
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="label-input mb-2">Email</label>
                <div className="input-group">
                  <input
                    type="email"
                    className="input-costum form-control"
                    placeholder="Masukkan Email"
                    {...register("Email", { required: true })}
                  />
                </div>
                {errors.Email && (
                  <span className="alert-input">Email tidak boleh kosong</span>
                )}
              </div>
            </div>

            {/* hr */}
            <div className="col-md-12">
              <hr style={{ color: "#afafaf" }} />
            </div>
            <div className="col-md-12">
              <div className="mb-3">
                <label className="label-input mb-2">Domisili</label>
                <div className="wrapper-radiobtn">
                  <div className="form-check me-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="IdDomisili"
                      id="dmsDn"
                      value={1}
                      defaultChecked={getValues("IdDomisili") === "1"}
                      {...register("IdDomisili")}
                      onChange={() => setIsDomisili(1)}
                    />
                    <label className="form-check-label" htmlFor="dmsDn">
                      Dalam Negeri
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="IdDomisili"
                      id="dmsLn"
                      value={2}
                      defaultChecked={getValues("IdDomisili") === "2"}
                      {...register("IdDomisili")}
                      onChange={() => setIsDomisili(2)}
                    />
                    <label className="form-check-label" htmlFor="dmsLn">
                      Luar Negeri
                    </label>
                  </div>
                </div>
                {errors.IdKewarganegaraan && (
                  <span className="alert-input">
                    Warga Negara tidak boleh kosong
                  </span>
                )}
              </div>
            </div>
            {isDomisili === 1 ? (
              <>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label className="label-input mb-2">
                      Status Tempat Tinggal
                    </label>
                    <Controller
                      control={control}
                      defaultValue={getValues("IdJenisTempatTinggal") || null}
                      name="IdJenisTempatTinggal"
                      render={({ field, ref }) => (
                        <ReactSelect
                          inputRef={ref}
                          classNamePrefix="addl-class"
                          options={dataComboOrtuJenisTempatTinggal}
                          value={getValues("IdJenisTempatTinggal")}
                          onChange={(val) => {
                            field.onChange(val);
                          }}
                          isClearable={true}
                        />
                      )}
                      rules={{
                        required: false,
                      }}
                    />
                    {errors.IdJenisTempatTinggal && (
                      <span className="text-danger">Kolom wajib diisi</span>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="label-input mb-2">Provinsi</label>
                    <Controller
                      control={control}
                      defaultValue={getValues("IdProvinsi") || null}
                      name="IdProvinsi"
                      render={({ field, ref }) => (
                        <ReactSelect
                          inputRef={ref}
                          classNamePrefix="addl-class"
                          options={dataComboProvinsi}
                          value={getValues("IdProvinsi")}
                          onChange={(val) => {
                            field.onChange(val);
                            onWilayah(val.value, "Provinsi");
                          }}
                          isClearable={true}
                          isDisabled={dataAlamatAyah}
                        />
                      )}
                      rules={{
                        required: false,
                      }}
                    />
                    {errors.IdProvinsi && (
                      <span className="text-danger">Kolom wajib diisi</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="label-input mb-2">Kabupaten/Kota</label>
                    <Controller
                      control={control}
                      defaultValue={getValues("IdKabkot") || null}
                      name="IdKabkot"
                      render={({ field, ref }) => (
                        <ReactSelect
                          inputRef={ref}
                          classNamePrefix="addl-class"
                          options={dataComboKabkot}
                          value={getValues("IdKabkot")}
                          onChange={(val) => {
                            field.onChange(val);
                            onWilayah(val.value, "Kabkot");
                          }}
                          isClearable={true}
                          isDisabled={dataAlamatAyah}
                        />
                      )}
                      rules={{
                        required: false,
                      }}
                    />
                    {errors.IdKabkot && (
                      <span className="text-danger">Kolom wajib diisi</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="label-input mb-2">Kecamatan</label>
                    <Controller
                      control={control}
                      defaultValue={getValues("IdKecamatan") || null}
                      name="IdKecamatan"
                      render={({ field, ref }) => (
                        <ReactSelect
                          inputRef={ref}
                          classNamePrefix="addl-class"
                          options={dataComboKecamatan}
                          value={getValues("IdKecamatan")}
                          onChange={(val) => {
                            field.onChange(val);
                            onWilayah(val.value, "Kecamatan");
                          }}
                          isClearable={true}
                          isDisabled={dataAlamatAyah}
                        />
                      )}
                      rules={{
                        required: false,
                      }}
                    />
                    {errors.IdKecamatan && (
                      <span className="text-danger">Kolom wajib diisi</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="label-input mb-2">Kelurahan/Desa</label>
                    <Controller
                      control={control}
                      defaultValue={getValues("IdDesa") || null}
                      name="IdDesa"
                      render={({ field, ref }) => (
                        <ReactSelect
                          inputRef={ref}
                          classNamePrefix="addl-class"
                          options={dataComboDesa}
                          value={getValues("IdDesa")}
                          onChange={(val) => {
                            field.onChange(val);
                            onWilayah(val.value, "Desa");
                          }}
                          isClearable={true}
                          isDisabled={dataAlamatAyah}
                        />
                      )}
                      rules={{
                        required: false,
                      }}
                    />
                    {errors.IdDesa && (
                      <span className="text-danger">Kolom wajib diisi</span>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label className="label-input mb-2">Alamat Tunjuk</label>
                    <Controller
                      control={control}
                      defaultValue={getValues("KdAlamatTunjuk") || null}
                      name="KdAlamatTunjuk"
                      render={({ field, ref }) => (
                        <ReactSelect
                          inputRef={ref}
                          classNamePrefix="addl-class"
                          options={dataComboAlamatTunjuk}
                          value={getValues("KdAlamatTunjuk")}
                          onChange={(val) => {
                            field.onChange(val);
                          }}
                          isClearable={true}
                          isDisabled={dataAlamatAyah}
                        />
                      )}
                      rules={{
                        required: false,
                      }}
                    />
                    {errors.KdAlamatTunjuk && (
                      <span className="text-danger">Kolom wajib diisi</span>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label className="label-input mb-2">Alamat Tinggal</label>
                    <div className="input-group">
                      <textarea
                        name="Alamat"
                        rows={5}
                        className="input-costum form-control"
                        placeholder="Masukkan Alamat Tinggal"
                        {...register("Alamat", {
                          required: false,
                          // maxLength: 25,
                        })}
                        disabled={dataAlamatAyah}
                      />
                    </div>
                    {errors.Alamat && (
                      <span className="alert-input">
                        Alamat Tinggal tidak boleh kosong
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="label-input mb-2">RT</label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="input-costum form-control"
                        placeholder="Masukkan RT"
                        {...register("Rt", { required: false })}
                        disabled={dataAlamatAyah}
                      />
                    </div>
                    {errors.Rt && (
                      <span className="alert-input">RT tidak boleh kosong</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="label-input mb-2">RW</label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="input-costum form-control"
                        placeholder="Masukkan RW"
                        {...register("Rw", { required: false })}
                        disabled={dataAlamatAyah}
                      />
                    </div>
                    {errors.Rw && (
                      <span className="alert-input">RW tidak boleh kosong</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="label-input mb-2">Latitude</label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="input-costum form-control"
                        placeholder="Masukkan Latitude"
                        {...register("Latitude", { required: false })}
                        disabled={dataAlamatAyah}
                      />
                    </div>
                    {errors.Latitude && (
                      <span className="alert-input">
                        Latitude tidak boleh kosong
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="label-input mb-2">Longitude</label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="input-costum form-control"
                        placeholder="Masukkan Longitude"
                        {...register("Longitude", { required: false })}
                        disabled={dataAlamatAyah}
                      />
                    </div>
                    {errors.Longitude && (
                      <span className="alert-input">
                        Longitude tidak boleh kosong
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="label-input mb-2">Dusun</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="input-costum form-control"
                        placeholder="Masukkan Dusun"
                        {...register("Dusun", { required: false })}
                        disabled={dataAlamatAyah}
                      />
                    </div>
                    {errors.Dusun && (
                      <span className="alert-input">
                        Dusun tidak boleh kosong
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="label-input mb-2">Kode Pos</label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="input-costum form-control"
                        placeholder="Masukkan Kode Pos"
                        {...register("KdPos", { required: false })}
                        disabled={dataAlamatAyah}
                      />
                    </div>
                    {errors.KdPos && (
                      <span className="alert-input">
                        Kode Pos tidak boleh kosong
                      </span>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label className="label-input mb-2">Alamat </label>
                    <div className="input-group">
                      <textarea
                        name="Address"
                        rows={5}
                        className="input-costum form-control"
                        placeholder="Masukkan Alamat "
                        {...register("Alamat", {
                          required: false,
                        })}
                      />
                    </div>
                    {errors.Alamat && (
                      <span className="alert-input">
                        Alamat tidak boleh kosong
                      </span>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
          <div>
            <button type="submit" className="btn btn-primary w-100 mt-3">
              Simpan Pembaruan
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SectionParent;
