import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { IoIosClose } from "react-icons/io";
import { IoAddSharp, IoPencilSharp } from "react-icons/io5";
import ReactInputMask from "react-input-mask";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import { DatePicker, ReactSelect } from "src/components";
import { FUNCDateDmytoYmd } from "src/config";
import {
  apiInfoAddBeasiswa,
  apiInfoEditBeasiswa,
} from "src/config/redux/action";

const SectionModalBeasiswa = ({
  isOpen,
  onModal,
  isAdd = false,
  dataBeasiswa = null,
}) => {
  const { dataComboBeasiswaKategori, dataComboBeasiswaJenisPemberi } =
    useSelector((state) => state.comboReducer);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    reset,
  } = useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    setValue("IdSiswaBeasiswa", dataBeasiswa?.IdSiswaBeasiswa);
    setValue("Nama", dataBeasiswa?.Nama);
    setValue("Tahun", dataBeasiswa?.Tahun);
    setValue(
      "IdKategori",
      dataBeasiswa?.Kategori?.Nama && {
        value: dataBeasiswa?.Kategori?.Id,
        label: dataBeasiswa?.Kategori?.Nama,
      }
    );
    setValue(
      "IdJenisPemberi",
      dataBeasiswa?.JenisPemberi?.Nama && {
        value: dataBeasiswa?.JenisPemberi?.Id,
        label: dataBeasiswa?.JenisPemberi?.Nama,
      }
    );
    setValue("NamaPemberi", dataBeasiswa?.NamaPemberi);
    var TanggalMulai = new Date("1900-01-01");
    if (dataBeasiswa?.TanggalMulai)
      TanggalMulai = new Date(FUNCDateDmytoYmd(dataBeasiswa?.TanggalMulai));
    setValue("TanggalMulai", TanggalMulai);
    var TanggalSelesai = new Date("1900-01-01");
    if (dataBeasiswa?.TanggalSelesai)
      TanggalSelesai = new Date(FUNCDateDmytoYmd(dataBeasiswa?.TanggalSelesai));
    setValue("TanggalSelesai", TanggalSelesai);
    setValue("NilaiNominal", dataBeasiswa?.NilaiNominal);
  }, [dataBeasiswa]);

  const btnSave = (data) => {
    if (isAdd) dispatch(apiInfoAddBeasiswa(data, onModal, reset));
    if (!isAdd) dispatch(apiInfoEditBeasiswa(data, onModal, reset));
  };

  const closeModal = () => {
    onModal();
    reset();
  };

  return (
    <Modal centered isOpen={isOpen} toggle={closeModal}>
      <ModalBody>
        <div className="mb-2">
          <div className="close-modal">
            <span onClick={() => onModal()}>
              <IoIosClose size={30} color="#5F5D7E" />
            </span>
          </div>
        </div>
        <div className="p-3">
          <div className="modal-filter-mapel-date">
            <div className="d-flex align-items-center">
              {isAdd ? (
                <>
                  <IoAddSharp color="#3F3D56" size={20} />
                  <h4>Tambah Beasiswa</h4>
                </>
              ) : (
                <>
                  <IoPencilSharp color="#3F3D56" size={20} />
                  <h4>Perbarui Beasiswa</h4>
                </>
              )}
            </div>
            <form className="mt-3" onSubmit={handleSubmit(btnSave)}>
              <div className="row">
                <div className="col-md-8">
                  <div className="mb-3">
                    <label className="label-input mb-2">Nama</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="input-costum form-control"
                        placeholder="Masukkan Nama "
                        {...register("Nama", { required: true })}
                      />
                    </div>
                    {errors.Nama && (
                      <span className="alert-input">Kolom wajib diisi</span>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label className="label-input mb-2">Tahun</label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="input-costum form-control"
                        placeholder="Masukkan Tahun"
                        {...register("Tahun", {
                          required: true,
                          minLength: 4,
                          maxLength: 4,
                        })}
                      />
                    </div>
                    {errors.Tahun && (
                      <>
                        {errors.Tahun?.type === "required" && (
                          <span className="alert-input">Kolom wajib diisi</span>
                        )}
                        {errors.Tahun?.type === "minLength" && (
                          <span className="alert-input">Minimal 4 Angka</span>
                        )}
                        {errors.Tahun?.type === "maxLength" && (
                          <span className="alert-input">Maksimal 4 Angka</span>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className="col-md-12 ">
                  <div className="mb-3">
                    <label className="label-input mb-2">Kategori</label>
                    <Controller
                      control={control}
                      defaultValue={getValues("IdKategori") || ""}
                      name="IdKategori"
                      render={({ field, ref }) => (
                        <ReactSelect
                          inputRef={ref}
                          classNamePrefix="addl-class"
                          options={dataComboBeasiswaKategori}
                          value={getValues("IdKategori")}
                          onChange={(val) => {
                            field.onChange(val);
                          }}
                          isClearable={true}
                        />
                      )}
                      rules={{
                        required: false,
                      }}
                    />
                    {errors.IdKategori && (
                      <span className="text-danger">Kolom wajib diisi</span>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label className="label-input mb-2">Jenis Pemberi</label>
                    <Controller
                      control={control}
                      defaultValue={getValues("IdJenisPemberi") || ""}
                      name="IdJenisPemberi"
                      render={({ field, ref }) => (
                        <ReactSelect
                          inputRef={ref}
                          classNamePrefix="addl-class"
                          options={dataComboBeasiswaJenisPemberi}
                          value={getValues("IdJenisPemberi")}
                          onChange={(val) => {
                            field.onChange(val);
                          }}
                          isClearable={true}
                        />
                      )}
                      rules={{
                        required: false,
                      }}
                    />
                    {errors.IdJenisPemberi && (
                      <span className="text-danger">Kolom wajib diisi</span>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label className="label-input mb-2">Nama Pemberi</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="input-costum form-control"
                        placeholder="Masukkan Nama Pemberi"
                        {...register("NamaPemberi", { required: true })}
                      />
                    </div>
                    {errors.NamaPemberi && (
                      <span className="alert-input">Kolom wajib diisi</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="label-input mb-2">Tanggal Mulai</label>
                  <div className="input-group mb-3">
                    <Controller
                      control={control}
                      name="TanggalMulai"
                      render={({ field, onChange, value }) => (
                        <DatePicker
                          placeholderText="Masukkan Tanggal Mulai..."
                          selected={field.value}
                          value={getValues("TanggalMulai")}
                          onChange={(val) => field.onChange(val)}
                          dateFormat="dd-MM-yyyy"
                          maxDate={new Date()}
                          customInput={
                            <ReactInputMask
                              mask="99-99-9999"
                              onChange={field.onChange}
                              value={field.value}
                              maskChar=""
                            />
                          }
                        />
                      )}
                      rules={{
                        required: true,
                      }}
                    />
                    {errors.TanggalMulai && (
                      <span className="alert-input">Kolom wajib diisi</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="label-input mb-2">Tanggal Selesai</label>
                  <div className="input-group mb-3">
                    <Controller
                      control={control}
                      name="TanggalSelesai"
                      render={({ field, onChange, value }) => (
                        <DatePicker
                          placeholderText="Masukkan Tanggal Selesai..."
                          selected={field.value}
                          value={getValues("TanggalSelesai")}
                          onChange={(val) => field.onChange(val)}
                          dateFormat="dd-MM-yyyy"
                          maxDate={new Date()}
                          customInput={
                            <ReactInputMask
                              mask="99-99-9999"
                              onChange={field.onChange}
                              value={field.value}
                              maskChar=""
                            />
                          }
                        />
                      )}
                      rules={{
                        required: true,
                      }}
                    />
                    {errors.TanggalSelesai && (
                      <span className="alert-input">Kolom wajib diisi</span>
                    )}
                  </div>
                </div>
                <div className="col-md-12 ">
                  <div className="mb-3">
                    <label className="label-input mb-2">Nilai Nominal</label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="input-costum form-control"
                        placeholder="Masukkan Nilai Nominal"
                        {...register("NilaiNominal", { required: true })}
                      />
                    </div>
                    {errors.NilaiNominal && (
                      <span className="alert-input">Kolom wajib diisi</span>
                    )}
                  </div>
                </div>
              </div>
              <button type="submit" className="btn btn-primary w-100 mt-3">
                Simpan
              </button>
            </form>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default SectionModalBeasiswa;
