import { http, URLSVC, swalError } from "src/config";
import Swal from "sweetalert2";

export const setDataInboxs = () => {
  return (dispatch) => {
    http.get(`${URLSVC}/Siswas/GetSiswaInboxes?allInbox=true`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        dispatch({ type: "DATA_INBOXS", payload: data.Data });
      } else {
        swalError(data.ReturnMessage);
      }
    });
  };
};
export const setDataInbox = (id) => {
  return (dispatch) => {
    http
      .get(`${URLSVC}/Siswas/GetSiswaInbox?idSiswaInbox=${id}`)
      .then((res) => {
        let data = res.data;
        if (data.IsSuccess) {
          dispatch({ type: "DATA_INBOX", payload: data.Data });
          dispatch({ type: "MODAL_INBOX", payload: true });
          dispatch(apiReadSiswaInbox(id));
        } else {
          swalError(data.ReturnMessage);
        }
      });
  };
};

export const apiReadSiswaInbox = (id) => {
  return (dispatch) => {
    var fd = new FormData();

    fd.append("idSiswaInbox", id);

    http.post(`${URLSVC}/Siswas/ReadSiswaInbox`, fd).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        dispatch(setDataInboxs());
      } else {
        swalError(res.ReturnMessage);
      }
    });
  };
};
