import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DataTable } from "src/components";
import { FUNCIndoDate } from "src/config";
import {
  apiInfoDeleteBeasiswa,
  setDataComboBeasiswaJenisPemberi,
  setDataComboBeasiswaKategori,
  setDataInfoBeasiswa,
  setDataInfoBeasiswas,
} from "src/config/redux/action";
import SectionModalBeasiswa from "./SectionModalBeasiswa";

const SectionBeasiswa = () => {
  const { dataInfoBeasiswas, dataInfoBeasiswa, modalBeasiswa } = useSelector(
    (state) => state.userReducer
  );
  const [isAdd, setIsAdd] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setDataInfoBeasiswas());
    dispatch(setDataComboBeasiswaKategori("DATA_COMBO_BEASISWA_KATEGORI"));
    dispatch(
      setDataComboBeasiswaJenisPemberi("DATA_COMBO_BEASISWA_JENIS_PEMBERI")
    );
  }, [dispatch]);

  const columns = [
    {
      title: "AKSI",
      field: "Tahun",
      cellStyle: {
        width: 160,
        minWidth: 160,
      },
      headerStyle: {
        width: 160,
        minWidth: 160,
      },
      render: (rowData) => (
        <>
          <button
            type="button"
            onClick={() => addEditBeasiswa(false, rowData.IdSiswaBeasiswa)}
            className="btn btn-warning me-1"
          >
            Edit
          </button>
          <button
            type="button"
            onClick={() =>
              dispatch(
                apiInfoDeleteBeasiswa(
                  rowData.IdSiswaBeasiswa,
                  `${rowData.Nama}`
                )
              )
            }
            className="btn btn-danger"
          >
            Hapus
          </button>
        </>
      ),
    },
    {
      title: "TAHUN",
      field: "Tahun",
    },
    {
      title: "NAMA",
      field: "Nama",
    },
    {
      title: "KATEGORI",
      field: "Jenis",
      render: (rowData) => (
        <>
          <span>{rowData.Kategori.Nama}</span>
        </>
      ),
    },
    {
      title: "JENIS PEMBERI",
      field: "Jenis",
      cellStyle: {
        width: 190,
        minWidth: 190,
      },
      headerStyle: {
        width: 190,
        minWidth: 190,
      },
      render: (rowData) => (
        <>
          <span>{rowData.JenisPemberi.Nama}</span>
        </>
      ),
    },
    {
      title: "NAMA PEMBERI",
      field: "NamaPemberi",
      cellStyle: {
        width: 190,
        minWidth: 190,
      },
      headerStyle: {
        width: 190,
        minWidth: 190,
      },
    },
    {
      title: "TANGGAL",
      field: "Tanggal",
      cellStyle: {
        width: 270,
        minWidth: 270,
      },
      headerStyle: {
        width: 270,
        minWidth: 270,
      },
      render: (rowData) => (
        <>
          <span>{FUNCIndoDate(rowData.TanggalMulai)}</span> s/d{" "}
          <span>{FUNCIndoDate(rowData.TanggalSelesai)}</span>
        </>
      ),
    },

    {
      title: "NOMINAL",
      field: "NilaiNominal",
    },
    {
      title: "JANGKA WAKTU BULAN",
      field: "JangkaWaktuBulan",
      cellStyle: {
        width: 270,
        minWidth: 270,
      },
      headerStyle: {
        width: 270,
        minWidth: 270,
      },
    },
    {
      title: "JANGKA WAKTU TAHUN",
      field: "JanganWaktuTahun",
      cellStyle: {
        width: 270,
        minWidth: 270,
      },
      headerStyle: {
        width: 270,
        minWidth: 270,
      },
    },
  ];

  const showModal = () =>
    dispatch({
      type: "MODAL_BEASISWA",
      payload: !modalBeasiswa,
    });

  const addEditBeasiswa = (isAdd, id) => {
    setIsAdd(isAdd);
    if (!isAdd) {
      dispatch(setDataInfoBeasiswa(id));
    } else {
      showModal();
    }
  };
  return (
    <>
      <div className="mb-3">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => addEditBeasiswa(true)}
        >
          Tambah Beasiswa
        </button>
      </div>
      <DataTable
        title="Data Tabel"
        columns={columns}
        data={dataInfoBeasiswas}
      />
      <SectionModalBeasiswa
        isOpen={modalBeasiswa}
        onModal={showModal}
        isAdd={isAdd}
        dataBeasiswa={dataInfoBeasiswa}
      />
    </>
  );
};

export default SectionBeasiswa;
