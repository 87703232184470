import React, { useState } from "react";
import {
  IoArrowForward,
  IoCardOutline,
  IoListOutline,
  IoTimeOutline,
} from "react-icons/io5";
import { Header } from "src/components";
import SectionTime from "./parts/SectionTime";
import SectionProgress from "./parts/SectionProgress";
import SectionResult from "./parts/SectionResult";
import "./style.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import SectionModalFilter from "./parts/SectionModalFilter";
import { setQiroatyModalFilter } from "src/config/redux/action";

const jsonTabs = [
  {
    tab: "time",
    title: "Daftar Jadwal",
    icon: <IoTimeOutline color="#11A887" />,
  },
  {
    tab: "progress",
    title: "Progress Harian",
    icon: <IoListOutline color="#11A887" />,
  },
  {
    tab: "result",
    title: "Hasil Test",
    icon: <IoCardOutline color="#11A887" />,
  },
];

const Qiroaty = (props) => {
  const [tab, setTab] = useState("time");
  const { modalFilterQiroaty } = useSelector((state) => state.qiroatyReducer);
  const dispatch = useDispatch();
  const showModalFilterQiroaty = () =>
    dispatch(setQiroatyModalFilter(modalFilterQiroaty));
  return (
    <>
      <Header name={props.name} />
      <div className="row">
        <div className="col-md-4">
          <div className="list-profile">
            {jsonTabs.map((v, i) => {
              return (
                <div
                  key={i}
                  className={`tab-profile ${tab === v.tab ? "active" : ""}`}
                  onClick={() => setTab(v.tab)}
                >
                  <div className="d-flex align-items-center">
                    {v.icon}
                    <span className="ms-3"> {v.title} </span>
                  </div>
                  <div>
                    <IoArrowForward color="#3F3D56" />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-md-8">
          {tab === "time" && <SectionTime onModal={showModalFilterQiroaty} />}
          {tab === "progress" && <SectionProgress />}
          {tab === "result" && <SectionResult />}
        </div>
      </div>
      <SectionModalFilter
        isOpen={modalFilterQiroaty}
        onModal={showModalFilterQiroaty}
      />
    </>
  );
};

export default Qiroaty;
