import React from "react";
import Select, { components } from "react-select";
import makeAnimated from "react-select/animated";

const NoOptionsMessage = (props) => {
  return (
    <components.NoOptionsMessage {...props}>
      <p style={{ fontSize: 12 }}>Tidak Ada</p>
    </components.NoOptionsMessage>
  );
};

function ReactSelect({ ...props }) {
  const animatedComponents = makeAnimated();

  return (
    <Select
      closeMenuOnSelect={true}
      components={{ animatedComponents, NoOptionsMessage }}
      placeholder={"Select One"}
      styles={{
        menu: (provided) => ({ ...provided, zIndex: 9999 }),
        option: (provided, state) => ({
          ...provided,
          color: "#3f3d56df",
          fontFamily: "Poppins",
          fontSize: 13,
          backgroundColor: state.isSelected ? "rgba(189,197,209,.3)" : "white",
        }),
        control: (provided) => ({
          ...provided,
          color: "#fff",
          boxShadow: "none",
          borderRadius: 10,
          fontFamily: "Poppins",
          fontSize: 13,
          borderColor: "#ededed",
          borderWidth: 0.5,
        }),
        singleValue: (provided) => ({
          ...provided,
          color: "#3f3d56df",
        }),
      }}
      {...props}
    />
  );
}

export default ReactSelect;
