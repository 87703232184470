import React from "react";
import { IoCloudDownloadOutline } from "react-icons/io5";

const ItemTabTask = ({ data }) => {
  return (
    <div className="tab-task">{data?.Soal && <Item data={data?.Soal} />}</div>
  );
};

const Item = ({ data }) => {
  return (
    <>
      <div className="mb-3">
        <h6>Judul Soal : </h6>
        <p>{data?.Judul || "Tidak ada judul"}</p>
      </div>
      <div className="mb-3">
        <h6>Deskripsi : </h6>
        <p
          dangerouslySetInnerHTML={{
            __html: `${data?.Deskripsi || "Tidak ada deskripsi"}`,
          }}
        ></p>
      </div>

      {data.NamaFileUrl && (
        <div className="mb-3">
          <h6>Lampiran : </h6>
          <button
            className="btn btn-custom-download"
            onClick={() => window.open(data.NamaFileUrl, "_blank")}
          >
            <IoCloudDownloadOutline color="#fff" size={20} className="me-2" />
            Download Soal
          </button>
        </div>
      )}
    </>
  );
};

export default ItemTabTask;
