import React from "react";
import { Header } from "src/components";
import SectionForm from "./parts/SectionForm";
import SectionImage from "./parts/SectionImage";
import "./style.css";

const Security = (props) => {
  return (
    <>
      <Header name={props.name} />
      <div className="row">
        <SectionForm />
        <SectionImage />
      </div>
    </>
  );
};

export default Security;
