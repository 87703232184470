const initialState = {
  formLogin: {
    Nisn: "",
    Password: "",
    isLoading: false,
  },
  formResetPassword: {
    Nisn: "",
  },
  formChangePassword: {
    isLoading: false,
  },
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FORM_LOGIN":
      return {
        ...state,
        formLogin: {
          ...state.formLogin,
          [action.formType]: action.formValue,
        },
      };
    case "RESET_FORM_LOGIN":
      return { ...state, formLogin: initialState.formLogin };
    case "FORM_RESET_PASSWORD":
      return {
        ...state,
        formResetPassword: {
          ...state.formResetPassword,
          [action.formType]: action.formValue,
        },
      };
    case "RESET_FORM_RESET_PASSWORD":
      return { ...state, formResetPassword: initialState.formResetPassword };
    case "FORM_CHANGE_PASSWORD":
      return {
        ...state,
        formChangePassword: {
          ...state.formChangePassword,
          [action.formType]: action.formValue,
        },
      };
    case "RESET_FORM_CHANGE_PASSWORD":
      return { ...state, formChangePassword: initialState.formChangePassword };

    default:
      return state;
  }
};

export default authReducer;
