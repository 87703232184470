import React, { useEffect } from "react";
import {
  IoArrowBackOutline,
  IoCheckmarkSharp,
  IoCloseSharp,
  IoCloudDownloadOutline,
  IoOpenOutline,
  IoWarningOutline,
} from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, Header } from "src/components";
import "./style.css";
import SectionContent from "./parts/SectionContent";
import SectionHero from "./parts/SectionHero";
import ItemAlertDanger from "./parts/components/ItemAlertDanger";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  setDataRuangBelajarDetail,
  setFormElearning,
  setStudyModalFile,
  setStudyModalImage,
  setStudyModalLink,
  setStudyModalVideo,
} from "src/config/redux/action";
import { Modal, ModalBody } from "reactstrap";
import { IoIosClose } from "react-icons/io";
import { IMGNotFoundLandscape } from "src/assets";

const Study = (props) => {
  let navigate = useNavigate();
  let { id } = useParams();
  const {
    dataElearning,
    formElearning,
    modalStudyLink,
    modalStudyImage,
    modalStudyVideo,
    modalStudyFile,
  } = useSelector((state) => state.elearningReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    // dispatch({ type: "RESET_FORM_ELEARNING" });
    // resetDetailStudy();
    dispatch(setDataRuangBelajarDetail(id));
  }, [dispatch]);

  // const resetDetailStudy = () => {
  //   dispatch(setFormElearning("AttachmentLink", null));
  //   dispatch(setFormElearning("AttachmentImage", null));
  //   dispatch(setFormElearning("AttachmentVideo", null));
  //   dispatch(setFormElearning("AttachmentFile", null));
  // };

  const onModalLink = () => {
    dispatch(setStudyModalLink(modalStudyLink));
  };
  const onModalImage = () => {
    dispatch(setStudyModalImage(modalStudyImage));
  };
  const onModalVideo = () => {
    dispatch(setStudyModalVideo(modalStudyVideo));
  };
  const onModalFile = () => {
    dispatch(setStudyModalFile(modalStudyFile));
  };

  return (
    <>
      <Header name={props.name} />
      <div
        className="button-back"
        onClick={() => navigate("/clientarea/elearning")}
      >
        <IoArrowBackOutline color="#fff" /> Kembali
      </div>
      {dataElearning?.Hasil === "Tuntas" && (
        <Alert
          icon={<IoCheckmarkSharp color="#fff" size={25} />}
          type="success"
          desc="Tuntas !"
        />
      )}
      {dataElearning?.Hasil === null && (
        <Alert
          icon={<IoWarningOutline color="#fff" size={25} />}
          type="warning"
          desc="Tugas belum dinilai !"
        />
      )}
      {dataElearning?.Hasil === "Remedial" && (
        <Alert
          icon={<IoCloseSharp color="#fff" size={25} />}
          type="danger"
          desc="Anda harus mengulang !"
        />
      )}
      <div className="card-study-play">
        <div className="row">
          <div className="col-md-12">
            <SectionHero data={dataElearning?.Header} />

            <SectionContent
              data={dataElearning}
              modalLink={modalStudyLink}
              modalImage={modalStudyImage}
              modalVideo={modalStudyVideo}
              modalFile={modalStudyFile}
            />
          </div>
        </div>
      </div>

      <Modal centered isOpen={modalStudyLink} toggle={onModalLink}>
        <ModalBody>
          <div className="mb-2">
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <div className="icon-global"></div>
                <h5 className="title-global">Materi Link</h5>
              </div>
              <div className="close-modal">
                <span
                  onClick={() => dispatch(setStudyModalLink(modalStudyLink))}
                >
                  <IoIosClose size={30} color="#5F5D7E" />
                </span>
              </div>
            </div>
          </div>
          <div className="p-3">
            {formElearning?.AttachmentLink?.map((v, i) => {
              return (
                <div key={i} className="item-study-link">
                  <a href={v.data || "#"} target="_blank">
                    {v.data}
                    <IoOpenOutline className="ms-2" />
                  </a>
                </div>
              );
            })}
          </div>
        </ModalBody>
      </Modal>

      <Modal centered size="lg" isOpen={modalStudyImage} toggle={onModalImage}>
        <ModalBody>
          <div className="mb-2">
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <div className="icon-global"></div>
                <h5 className="title-global">Materi Gambar</h5>
              </div>
              <div className="close-modal">
                <span
                  onClick={() => dispatch(setStudyModalImage(modalStudyImage))}
                >
                  <IoIosClose size={30} color="#5F5D7E" />
                </span>
              </div>
            </div>
          </div>
          <div className="p-3">
            <div className="row">
              {formElearning?.AttachmentImage?.map((v, i) => {
                return (
                  <div key={i} className="col-md-6 mb-3">
                    <div className="item-study-image">
                      <img
                        src={v.data}
                        width={300}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = IMGNotFoundLandscape;
                        }}
                        className="img-fluid rounded"
                      />
                      <button className="btn btn-costum-secondary w-100 mt-3">
                        Unduh Gambar
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal centered size="lg" isOpen={modalStudyVideo} toggle={onModalVideo}>
        <ModalBody>
          <div className="mb-2">
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <div className="icon-global"></div>
                <h5 className="title-global">Materi Vidio</h5>
              </div>
              <div className="close-modal">
                <span
                  onClick={() => dispatch(setStudyModalVideo(modalStudyVideo))}
                >
                  <IoIosClose size={30} color="#5F5D7E" />
                </span>
              </div>
            </div>
          </div>
          <div className="p-3">
            <div className="row">
              {formElearning?.AttachmentVideo?.map((v, i) => {
                return (
                  <div className="col-md-6 mb-3">
                    <div className="item-study-video">
                      <iframe
                        src={`${v.data}?origin=https://plyr.io&amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1`}
                        allowfullscreen=""
                        allowtransparency=""
                        frameborder="0"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal centered isOpen={modalStudyFile} toggle={onModalFile}>
        <ModalBody>
          <div className="mb-2">
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <div className="icon-global"></div>
                <h5 className="title-global">Materi File</h5>
              </div>
              <div className="close-modal">
                <span
                  onClick={() => dispatch(setStudyModalFile(modalStudyFile))}
                >
                  <IoIosClose size={30} color="#5F5D7E" />
                </span>
              </div>
            </div>
          </div>
          <div className="p-3">
            {formElearning?.AttachmentFile?.map((v, i) => {
              return (
                <div key={i} className="item-study-file">
                  <a href={v.data} target="_blank">
                    <IoCloudDownloadOutline className="me-2" />
                    Download Materi {i + 1}
                  </a>
                </div>
              );
            })}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Study;
