import React from "react";
import { useNavigate } from "react-router-dom";
import { IoCalendarOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import ItemCardMapel from "./components/ItemCardMapel";
import { useDispatch } from "react-redux";
import { setDataRuangBelajar, setFormElearning } from "src/config/redux/action";
import { Skeleton } from "@mui/material";
import { ILStudyNotfound } from "src/assets";

const SectionMapel = ({
  onFilterModal,
  dataElearnings,
  dataElearningDates,
}) => {
  let navigate = useNavigate();
  const { formElearning } = useSelector((state) => state.elearningReducer);
  const dispatch = useDispatch();

  const btnSchedule = (data) => {
    dispatch(setFormElearning("SelectTabDay", data.Hari));
    dispatch(setDataRuangBelajar(data.Tanggal));
  };

  const btnPlay = (id) => {
    // navigate(`play/${id}`);
    window.location.href = `/clientarea/elearning/play/${id}`;
  };

  return (
    <div className="row">
      <div className="col-md-3">
        <div className="d-none d-md-block filter-mapel" onClick={onFilterModal}>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h4>Filter Tanggal</h4>
            <IoCalendarOutline color="#3F3D56" size={20} />
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="text-box">
              <span>{formElearning.TanggalAwal}</span>
            </div>
            <span className="strip">s/d</span>
            <div className="text-box">
              <span>{formElearning.TanggalAkhir}</span>
            </div>
          </div>
        </div>

        <div className="list-day">
          {dataElearningDates?.length > 1 &&
            dataElearningDates?.map((v, i) => {
              var nameDay = v.Hari;
              return (
                <div
                  key={i}
                  className={`tab-day ${
                    nameDay === formElearning.SelectTabDay ? "active" : ""
                  }`}
                  onClick={() => btnSchedule(v)}
                >
                  {v.Hari}
                </div>
              );
            })}
          {dataElearningDates?.length === 0 && (
            <LoadingSkeleton height={40} count={5} />
          )}
        </div>
      </div>
      <div className="col-md-9 list-mapel">
        {dataElearnings?.length > 0 && (
          <>
            {dataElearnings?.map((v, i) => {
              return (
                <ItemCardMapel
                  key={i}
                  time={v.JamMulai}
                  title={v.Judul || "Tidak ada judul"}
                  mapel={v.Mapel}
                  isTask={false}
                  scoreCat={v.KategoriTugas}
                  scoreRes={v.NilaiTugas === "0" ? null : v.NilaiTugas}
                  teach={v.Guru}
                  onClick={() => btnPlay(v.IdKbmMateri)}
                />
              );
            })}
          </>
        )}

        {dataElearnings?.length === 0 && (
          <LoadingSkeleton height={130} count={4} />
        )}
        {!dataElearnings && (
          <div className="study-notfound">
            <img
              src={ILStudyNotfound}
              width={400}
              className="img-fluid"
              alt=""
            />
            <h5>Yey, tidak ada pelajaran </h5>
          </div>
        )}
      </div>
    </div>
  );
};

const LoadingSkeleton = ({ height, count }) => {
  var loading = [];

  for (let i = 0; i < count; i++) {
    loading.push(
      <Skeleton
        key={i}
        variant="rectangular"
        height={height}
        style={{ borderRadius: 20, marginBottom: 10, marginRight: 5 }}
      />
    );
  }
  return <>{loading}</>;
};

export default SectionMapel;
