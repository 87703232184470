const initialState = {
  dataElearnings: [],
  dataElearningDates: [],
  dataElearning: false,
  modalFilterElearning: false,
  formElearning: {
    TanggalFilter: new Date(),
    TanggalAwal: "loading",
    TanggalAkhir: "loading",
    SelectTabDay: "Senin",
  },
  modalStudyLink: false,
  modalStudyImage: false,
  modalStudyVideo: false,
  modalStudyFile: false,
};

const elearningReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DATA_ELEARNINGS":
      return { ...state, dataElearnings: action.payload };
    case "DATA_ELEARNING_DATES":
      return { ...state, dataElearningDates: action.payload };
    case "DATA_ELEARNING":
      return { ...state, dataElearning: action.payload };
    case "MODAL_FILTER_ELEARNING":
      return { ...state, modalFilterElearning: action.payload };
    case "MODAL_STUDY_LINK":
      return { ...state, modalStudyLink: action.payload };
    case "MODAL_STUDY_IMAGE":
      return { ...state, modalStudyImage: action.payload };
    case "MODAL_STUDY_VIDEO":
      return { ...state, modalStudyVideo: action.payload };
    case "MODAL_STUDY_FILE":
      return { ...state, modalStudyFile: action.payload };
    case "FORM_ELEARNING":
      return {
        ...state,
        formElearning: {
          ...state.formElearning,
          [action.formType]: action.formValue,
        },
      };
    case "RESET_FORM_ELEARNING":
      return { ...state, formElearning: initialState.formElearning };
    default:
      return state;
  }
};

export default elearningReducer;
