import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoCalendarOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { Skeleton } from "@mui/material";
import {
  apiSaveMutabaah,
  setDataMutabaah,
  setFormMutabaah,
} from "src/config/redux/action";
import { useDispatch } from "react-redux";

const SectionMutabaah = ({
  dataMutabaahs,
  dataMutabaahDates,
  onFilterModal,
}) => {
  const { formMutabaah } = useSelector((state) => state.mutabaahReducer);
  const dispatch = useDispatch();

  const btnSchedule = (data) => {
    dispatch(setFormMutabaah("SelectTabDay", data.Hari));
    dispatch(setFormMutabaah("TanggalSelect", data.Tanggal));
    dispatch(setDataMutabaah(data.Tanggal));
  };

  const onIsDone = (data, isCheck) => {
    let newArr = dataMutabaahs.map((item, i) => {
      if (data.IdJenisEvaluasiHarian === item.IdJenisEvaluasiHarian) {
        return { ...item, ["IsDone"]: isCheck ? "Y" : "T" };
      } else {
        return { ...item };
      }
    });
    dispatch({ type: "DATA_MUTABAAHS", payload: newArr });
  };

  const btnSaveMutabaah = () => {
    dispatch(apiSaveMutabaah(dataMutabaahs, formMutabaah.TanggalSelect));
  };

  return (
    <>
      <div className="row">
        <div className="col-md-3">
          <div
            className="d-none d-md-block filter-mutabaah"
            onClick={onFilterModal}
          >
            <div className="d-flex justify-content-between align-items-center mb-2">
              <h4>Filter Tanggal</h4>
              <IoCalendarOutline color="#3F3D56" size={20} />
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="text-box">
                <span>{formMutabaah.TanggalAwal}</span>
              </div>
              <span className="strip">s/d</span>
              <div className="text-box">
                <span>{formMutabaah.TanggalAkhir}</span>
              </div>
            </div>
          </div>

          <div className="list-day-mutabaah">
            {dataMutabaahDates?.length > 1 &&
              dataMutabaahDates?.map((v, i) => {
                var nameDay = v.Hari;
                return (
                  <div
                    key={i}
                    className={`tab-day ${
                      nameDay === formMutabaah.SelectTabDay ? "active" : ""
                    }`}
                    onClick={() => btnSchedule(v)}
                  >
                    {v.Hari}
                  </div>
                );
              })}
            {dataMutabaahDates?.length === 0 && (
              <LoadingSkeleton height={40} count={5} />
            )}
          </div>
        </div>
        <div className="col-md-9">
          <div className="card-global ">
            <div className="title-card-mutabaah">
              <div className="d-flex align-items-center">
                <div className="icon-global"></div>
                <h5 className="title-global">Daftar Mutabaah</h5>
              </div>
              <h6 onClick={() => btnSaveMutabaah()}>Simpan</h6>
            </div>
            <div className="mt-3 list-mutabaah">
              {dataMutabaahs?.length > 1 && (
                <>
                  {dataMutabaahs?.map((v, i) => {
                    return (
                      <div key={i} className="item-mutabaah">
                        <p>
                          {i + 1}. {v.Pertanyaan}
                        </p>
                        <div className="round">
                          <input
                            type="checkbox"
                            id={`cbxIsDone${v.IdJenisEvaluasiHarian}`}
                            defaultChecked={v.IsDone === "Y"}
                            onChange={(e) => onIsDone(v, e.target.checked)}
                          />
                          <label
                            htmlFor={`cbxIsDone${v.IdJenisEvaluasiHarian}`}
                          ></label>
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
              {dataMutabaahs?.length === 0 && (
                <LoadingSkeleton height={60} count={6} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const LoadingSkeleton = ({ height, count }) => {
  var loading = [];

  for (let i = 0; i < count; i++) {
    loading.push(
      <Skeleton
        key={i}
        variant="rectangular"
        height={height}
        style={{ borderRadius: 20, marginBottom: 10, marginRight: 5 }}
      />
    );
  }
  return <>{loading}</>;
};

export default SectionMutabaah;
