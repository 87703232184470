import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { IoIosClose } from "react-icons/io";
import { IoAddSharp, IoPencilSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import { ReactSelect } from "src/components";
import {
  FUNCValidateUploadFileExtension,
  FUNCValidateUploadFileSize,
} from "src/config";
import {
  apiInfoAddPrestasi,
  apiInfoEditPrestasi,
} from "src/config/redux/action";

const SectionModalPrestasi = ({
  isOpen,
  onModal,
  isAdd = false,
  dataPrestasi = null,
}) => {
  const {
    dataComboPrestasiJenis,
    dataComboPrestasiTingkat,
    dataComboPrestasiPeringkat,
    dataComboPrestasiKategori,
  } = useSelector((state) => state.comboReducer);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    reset,
  } = useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    setValue("IdSiswaPrestasi", dataPrestasi?.IdSiswaPrestasi);
    setValue("Nama", dataPrestasi?.Nama);
    setValue("Tahun", dataPrestasi?.Tahun);
    setValue("NamaPenyelenggara", dataPrestasi?.NamaPenyelenggara);
    setValue(
      "IdKategori",
      dataPrestasi?.Kategori?.Nama && {
        value: dataPrestasi?.Kategori?.Id,
        label: dataPrestasi?.Kategori?.Nama,
      }
    );
    setValue(
      "IdJenisPrestasi",
      dataPrestasi?.JenisPrestasi?.Nama && {
        value: dataPrestasi?.JenisPrestasi?.Id,
        label: dataPrestasi?.JenisPrestasi?.Nama,
      }
    );
    setValue(
      "IdJenisPeringkat",
      dataPrestasi?.JenisPeringkat?.Nama && {
        value: dataPrestasi?.JenisPeringkat?.Id,
        label: dataPrestasi?.JenisPeringkat?.Nama,
      }
    );
    setValue(
      "IdJenisTingkatPrestasi",
      dataPrestasi?.JenisTingkatPrestasi?.Nama && {
        value: dataPrestasi?.JenisTingkatPrestasi?.Id,
        label: dataPrestasi?.JenisTingkatPrestasi?.Nama,
      }
    );
    setValue("ViewFileSertifikat", dataPrestasi?.FileSertifikat);
  }, [dataPrestasi]);

  const btnSave = (data) => {
    if (isAdd) dispatch(apiInfoAddPrestasi(data, onModal, reset));
    if (!isAdd) dispatch(apiInfoEditPrestasi(data, onModal, reset));
  };

  const onFile = (e) => {
    const file = e.target.files[0];
    FUNCValidateUploadFileSize(e.target, 5120, "5MB");
    FUNCValidateUploadFileExtension(e.target, [
      ".jpg",
      ".jpeg",
      ".png",
      ".pdf",
    ]);
    setValue("FileSertifikat", file);
  };

  const closeModal = () => {
    onModal();
    reset();
  };

  return (
    <Modal centered isOpen={isOpen} toggle={closeModal}>
      <ModalBody>
        <div className="mb-2">
          <div className="close-modal">
            <span onClick={() => onModal()}>
              <IoIosClose size={30} color="#5F5D7E" />
            </span>
          </div>
        </div>
        <div className="p-3">
          <div className="modal-filter-mapel-date">
            <div className="d-flex align-items-center">
              {isAdd ? (
                <>
                  <IoAddSharp color="#3F3D56" size={20} />
                  <h4>Tambah Prestasi</h4>
                </>
              ) : (
                <>
                  <IoPencilSharp color="#3F3D56" size={20} />
                  <h4>Perbarui Prestasi</h4>
                </>
              )}
            </div>
            <form className="mt-3" onSubmit={handleSubmit(btnSave)}>
              <div className="row">
                <div className="col-md-8">
                  <div className="mb-3">
                    <label className="label-input mb-2">Nama</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="input-costum form-control"
                        placeholder="Masukkan Nama"
                        {...register("Nama", { required: true })}
                      />
                    </div>
                    {errors.Nama && (
                      <span className="alert-input">Kolom wajib diisi</span>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label className="label-input mb-2">Tahun</label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="input-costum form-control"
                        placeholder="Masukkan Tahun"
                        {...register("Tahun", {
                          required: true,
                          minLength: 4,
                          maxLength: 4,
                        })}
                      />
                    </div>
                    {errors.Tahun && (
                      <>
                        {errors.Tahun?.type === "required" && (
                          <span className="alert-input">Kolom wajib diisi</span>
                        )}
                        {errors.Tahun?.type === "minLength" && (
                          <span className="alert-input">Minimal 4 Angka</span>
                        )}
                        {errors.Tahun?.type === "maxLength" && (
                          <span className="alert-input">Maksimal 4 Angka</span>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className="col-md-12 ">
                  <div className="mb-3">
                    <label className="label-input mb-2">Jenis Prestasi</label>
                    <Controller
                      control={control}
                      defaultValue={getValues("IdJenisPrestasi") || ""}
                      name="IdJenisPrestasi"
                      render={({ field, ref }) => (
                        <ReactSelect
                          inputRef={ref}
                          classNamePrefix="addl-class"
                          options={dataComboPrestasiJenis}
                          value={getValues("IdJenisPrestasi")}
                          onChange={(val) => {
                            field.onChange(val);
                          }}
                          isClearable={true}
                        />
                      )}
                      rules={{
                        required: false,
                      }}
                    />
                    {errors.IdJenisPrestasi && (
                      <span className="text-danger">Kolom wajib diisi</span>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label className="label-input mb-2">Jenis Tingkat</label>
                    <Controller
                      control={control}
                      defaultValue={getValues("IdJenisTingkatPrestasi") || ""}
                      name="IdJenisTingkatPrestasi"
                      render={({ field, ref }) => (
                        <ReactSelect
                          inputRef={ref}
                          classNamePrefix="addl-class"
                          options={dataComboPrestasiTingkat}
                          value={getValues("IdJenisTingkatPrestasi")}
                          onChange={(val) => {
                            field.onChange(val);
                          }}
                          isClearable={true}
                        />
                      )}
                      rules={{
                        required: false,
                      }}
                    />
                    {errors.IdJenisTingkatPrestasi && (
                      <span className="text-danger">Kolom wajib diisi</span>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label className="label-input mb-2">Jenis Peringkat</label>
                    <Controller
                      control={control}
                      defaultValue={getValues("IdJenisPeringkat") || ""}
                      name="IdJenisPeringkat"
                      render={({ field, ref }) => (
                        <ReactSelect
                          inputRef={ref}
                          classNamePrefix="addl-class"
                          options={dataComboPrestasiPeringkat}
                          value={getValues("IdJenisPeringkat")}
                          onChange={(val) => {
                            field.onChange(val);
                          }}
                          isClearable={true}
                        />
                      )}
                      rules={{
                        required: false,
                      }}
                    />
                    {errors.IdJenisPeringkat && (
                      <span className="text-danger">Kolom wajib diisi</span>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label className="label-input mb-2">Kategori</label>
                    <Controller
                      control={control}
                      defaultValue={getValues("IdKategori") || ""}
                      name="IdKategori"
                      render={({ field, ref }) => (
                        <ReactSelect
                          inputRef={ref}
                          classNamePrefix="addl-class"
                          options={dataComboPrestasiKategori}
                          value={getValues("IdKategori")}
                          onChange={(val) => {
                            field.onChange(val);
                          }}
                          isClearable={true}
                        />
                      )}
                      rules={{
                        required: false,
                      }}
                    />
                    {errors.IdKategori && (
                      <span className="text-danger">Kolom wajib diisi</span>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label className="label-input mb-2">
                      Nama Penyelenggara
                    </label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="input-costum form-control"
                        placeholder="Masukkan Nama Penyelenggara"
                        {...register("NamaPenyelenggara", { required: true })}
                      />
                    </div>
                    {errors.NamaPenyelenggara && (
                      <span className="alert-input">Kolom wajib diisi</span>
                    )}
                  </div>
                </div>
                <div className="col-md-12 ">
                  <div className="mb-3">
                    <label className="label-input mb-2">File Sertifikat</label>
                    <div className="input-group">
                      <input
                        type="file"
                        className="input-costum form-control"
                        onChange={(e) => onFile(e)}
                        // {...register("FileSertifikat", { required: true })}
                      />
                    </div>
                    <span className="alert-input text-primary">
                      {getValues("ViewFileSertifikat") && (
                        <a
                          href={getValues("ViewFileSertifikat")}
                          className="inherit-anchor"
                          target="_blank"
                        >
                          lihat file
                        </a>
                      )}
                    </span>
                    {errors.FileSertifikat && (
                      <span className="alert-input">Kolom wajib diisi</span>
                    )}
                  </div>
                </div>
              </div>
              <button type="submit" className="btn btn-primary w-100 mt-3">
                Simpan
              </button>
            </form>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default SectionModalPrestasi;
