const initialState = {
  formUser: {
    NamaPanggilan: "",
    Email: "",
    NomorHp: "",
  },
  dataInfoProfil: null,
  dataInfoOrtuWali: null,
  dataInfoKeluarga: null,
  dataInfoKesehatan: null,
  dataInfoSekolahAsal: null,
  dataInfoKip: null,
  dataInfoIbu: null,
  dataInfoAyah: null,
  dataInfoWali: null,
  dataAlamatOrtuWali: false,
  dataAlamatAyah: false,
  dataInfoBeasiswas: [],
  dataInfoBeasiswa: null,
  dataInfoPrestasis: [],
  dataInfoPrestasi: null,
  modalBeasiswa: false,
  modalPrestasi: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FORM_USER":
      return {
        ...state,
        formUser: {
          ...state.formUser,
          [action.formType]: action.formValue,
        },
      };
    case "RESET_FORM_USER":
      return { ...state, formUser: initialState.formUser };
    case "DATA_INFO_PROFIL":
      return { ...state, dataInfoProfil: action.payload };
    case "DATA_INFO_ORTU_WALI":
      return { ...state, dataInfoOrtuWali: action.payload };
    case "DATA_INFO_KELUARGA":
      return { ...state, dataInfoKeluarga: action.payload };
    case "DATA_INFO_KESEHATAN":
      return { ...state, dataInfoKesehatan: action.payload };
    case "DATA_INFO_SEKOLAH_ASAL":
      return { ...state, dataInfoSekolahAsal: action.payload };
    case "DATA_INFO_KIP":
      return { ...state, dataInfoKip: action.payload };
    case "DATA_INFO_IBU":
      return { ...state, dataInfoIbu: action.payload };
    case "DATA_INFO_AYAH":
      return { ...state, dataInfoAyah: action.payload };
    case "DATA_INFO_WALI":
      return { ...state, dataInfoWali: action.payload };
    case "DATA_ALAMAT_ORTU_WALI":
      return { ...state, dataAlamatOrtuWali: action.payload };
    case "DATA_ALAMAT_AYAH":
      return { ...state, dataAlamatAyah: action.payload };
    case "DATA_INFO_BEASISWAS":
      return { ...state, dataInfoBeasiswas: action.payload };
    case "DATA_INFO_BEASISWA":
      return { ...state, dataInfoBeasiswa: action.payload };
    case "DATA_INFO_PRESTASIS":
      return { ...state, dataInfoPrestasis: action.payload };
    case "DATA_INFO_PRESTASI":
      return { ...state, dataInfoPrestasi: action.payload };
    case "MODAL_BEASISWA":
      return { ...state, modalBeasiswa: action.payload };
    case "MODAL_PRESTASI":
      return { ...state, modalPrestasi: action.payload };
    default:
      return state;
  }
};

export default userReducer;
