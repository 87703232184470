import React, { useState, useEffect, useRef } from "react";
import "./style.css";
import { Header, LockPage } from "src/components";

import SectionHello from "./parts/SectionHello";
import SectionBanner from "./parts/SectionBanner";
import SectionCalendar from "./parts/SectionCalendar";
import SectionProfile from "./parts/SectionProfile";
import SectionMateri from "./parts/SectionMateri";
import SectionMutabaah from "./parts/SectionMutabaah";
import SectionTime from "./parts/SectionTime";
import { useSelector, useDispatch } from "react-redux";
import { setDataHome } from "src/config/redux/action";
import SectionLink from "./parts/SectionLink";
const Home = (props) => {
  const { gbDataUser } = useSelector((state) => state.globalReducer);
  const { dataHome } = useSelector((state) => state.homeReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setDataHome());
  }, []);

  return (
    <>
      <Header name={props.name} />
      {/* {gbDataUser.IsProfileDone &&
      gbDataUser.KelasParalel.IdKelasParalel !== 0 ? ( */}
      <div className="row mt-3">
        <div className="col-md-5 mb-3">
          <SectionHello data={dataHome?.Greeting} />

          <SectionBanner data={dataHome?.Banners} />

          <SectionCalendar data={dataHome?.Kalenders} />
        </div>
        <div className="col-md-4 mb-3">
          <SectionProfile data={dataHome} />

          <SectionMateri data={dataHome?.Materis} />
        </div>
        <div className="col-md-3 mb-3">
          <div className="row">
            <SectionMutabaah data={dataHome?.Mutabaahs} />

            <SectionLink />

            <SectionTime />
          </div>
        </div>
      </div>
      {/* ) : (
        <LockPage />
      )} */}
    </>
  );
};

export default Home;
