import { http, URLSVC, swalError, FUNCDateToString } from "src/config";
import Swal from "sweetalert2";

export const setFormQiroaty = (formType, formValue) => {
  return { type: "FORM_QIROATY", formType, formValue };
};

export const setQiroatyModalFilter = (bool) => {
  return { type: "MODAL_FILTER_QIROATY", payload: !bool };
};

export const getDateQiroatyFilterFirstLast = (date, name = "time") => {
  return (dispatch) => {
    if (name === "time") dispatch(setFormQiroaty("TanggalFilter", date));
    if (name === "progres")
      dispatch(setFormQiroaty("TanggalFilterProgres", date));
    date = FUNCDateToString(date);
    date = date.split("-").reverse().join("-");
    date = new Date(date);
    var firstDate = new Date(date.setDate(date.getDate() + 1 - date.getDay()));
    var lastDate = new Date(date.setDate(date.getDate() - date.getDay() + 5));

    if (name === "time") {
      dispatch(setFormQiroaty("TanggalAwal", FUNCDateToString(firstDate)));
      dispatch(setFormQiroaty("TanggalAkhir", FUNCDateToString(lastDate)));
    }
    if (name === "progres") {
      dispatch(
        setFormQiroaty("TanggalAwalProgres", FUNCDateToString(firstDate))
      );
      dispatch(
        setFormQiroaty("TanggalAkhirProgres", FUNCDateToString(lastDate))
      );
    }
  };
};

export const setDataFilterQiroaty = (date, name = "time") => {
  return (dispatch) => {
    if (name === "time")
      dispatch(setDataQiroatyJadwals(FUNCDateToString(date)));
    if (name === "progres")
      dispatch(setDataQiroatyProgress(FUNCDateToString(date)));
    dispatch(getDateQiroatyFilterFirstLast(date));
  };
};

export const setDataQiroatyJadwals = (date) => {
  return (dispatch) => {
    http
      .get(`${URLSVC}/Siswas/GetQiroatyJadwal?Currdate=${date}`)
      .then((res) => {
        let data = res.data;
        if (data.IsSuccess) {
          setTimeout(() => {
            if (data.Data?.length !== 0) {
              dispatch({
                type: "DATA_QIROATY_JADWALS",
                payload: data.Data,
              });
            } else {
              dispatch({ type: "DATA_QIROATY_JADWALS", payload: false });
            }
          }, 3000);
        } else {
          swalError(res.ReturnMessage);
        }
      });
  };
};

export const setDataQiroatyProgress = (date) => {
  return (dispatch) => {
    http
      .get(`${URLSVC}/Siswas/GetQiroatyProgres?Currdate=${date}`)
      .then((res) => {
        let data = res.data;
        if (data.IsSuccess) {
          dispatch({ type: "DATA_QIROATY_PROGRESS", payload: data.Data });
        } else {
          if (data.ReturnMessage !== "no data found")
            swalError(data.ReturnMessage);
        }
      });
  };
};

export const setDataQiroatyResults = () => {
  return (dispatch) => {
    http.get(`${URLSVC}/Siswas/GetQiroatyTest`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        dispatch({ type: "DATA_QIROATY_RESULTS", payload: data.Data });
      } else {
        if (data.ReturnMessage !== "no data found")
          swalError(data.ReturnMessage);
      }
    });
  };
};
