const initialState = {
  gbDataUser: {
    Nama: null,
    NamaPanggilan: null,
    FotoProfile: null,
    IsProfileDone: false,
    KelasParalel: {
      IdKelasParalel: 0,
    },
  },
  gbDataBanner: false,
};

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GB_DATA_USER":
      return { ...state, gbDataUser: action.payload };
    case "GB_DATA_BANNER":
      return { ...state, gbDataBanner: action.payload };

    default:
      return state;
  }
};

export default globalReducer;
