const initialState = {
  formQiroaty: {
    TanggalFilter: new Date(),
    TanggalAwal: "loading",
    TanggalAkhir: "loading",

    TanggalFilterProgres: new Date(),
    TanggalAwalProgres: "loading",
    TanggalAkhirProgres: "loading",

    SelectType: "time",
  },
  dataQiroatyJadwals: [],
  dataQiroatyProgress: [],
  dataQiroatyResults: [],
  modalFilterQiroaty: false,
};

const qiroatyReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FORM_QIROATY":
      return {
        ...state,
        formQiroaty: {
          ...state.formQiroaty,
          [action.formType]: action.formValue,
        },
      };
    case "RESET_FORM_QIROATY":
      return { ...state, formQiroaty: initialState.formQiroaty };
    case "DATA_QIROATY_JADWALS":
      return { ...state, dataQiroatyJadwals: action.payload };
    case "DATA_QIROATY_PROGRESS":
      return { ...state, dataQiroatyProgress: action.payload };
    case "DATA_QIROATY_RESULTS":
      return { ...state, dataQiroatyResults: action.payload };
    case "MODAL_FILTER_QIROATY":
      return { ...state, modalFilterQiroaty: action.payload };

    default:
      return state;
  }
};

export default qiroatyReducer;
