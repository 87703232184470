import React, { useEffect, useState } from "react";
import Clock from "react-clock";

const SectionTime = () => {
  const [clock, setClock] = useState(new Date());
  useEffect(() => {
    const interval = setInterval(() => setClock(new Date()), 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <div className="col-md-12 mb-4">
      <div className="card-global">
        <div className="d-flex align-items-center">
          <div className="icon-global"></div>
          <h5 className="title-global">Jam</h5>
        </div>
        <div className="mt-4">
          <div>
            <center>
              <Clock value={clock} />
            </center>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionTime;
