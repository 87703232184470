import React from "react";
import { ILForgotPassword } from "src/assets";

const SectionImage = () => {
  return (
    <div className="col-md-7 align-self-center d-none d-sm-block">
      <img src={ILForgotPassword} className="img-fluid" />
    </div>
  );
};

export default SectionImage;
