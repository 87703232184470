import React, { useEffect } from "react";
import { ILNotFound } from "src/assets";
import { useNavigate } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import "./style.css";

const NotFound = () => {
  let navigate = useNavigate();
  return (
    <>
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div>
          <img src={ILNotFound} width={400} className="img-fluid" alt="" />
          <br />
          <div className="card-notfound">
            <h5>Maaf, Halaman tidak ditemukan...</h5>
            <button
              type="button"
              className="button-transparent"
              onClick={() => navigate("/login")}
            >
              <IoArrowBack color="#11A887" size={20} className="me-2" />
              Kembali
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
