import Kalend, { CalendarView } from "kalend";
import React, { useEffect, useState } from "react";
import { FUNCDateDmytoYmd } from "src/config";

const SectionCalendar = ({ data }) => {
  const [events, setEvents] = useState([]);
  useEffect(() => {
    let x = [];
    data?.map((v, i) => {
      const tglMulai = v.TanggalMulai.split(" ");
      const tglSelesai = v.TanggalSelesai.split(" ");

      x.push({
        id: i + 1,
        startAt: new Date(FUNCDateDmytoYmd(tglMulai[0])).toISOString(),
        endAt: new Date(FUNCDateDmytoYmd(tglSelesai[0])).toISOString(),
        timezoneStartAt: "Asia/Jakarta", // optional
        summary: v.Kegiatan,
        color: v.Warna,
      });
    });

    setEvents(x);
  }, [data]);

  return (
    <div className="card-global" style={{ height: "69vh" }}>
      <div className="d-flex align-items-center">
        <div className="icon-global"></div>
        <h5 className="title-global">Kalender</h5>
      </div>
      <div className="mt-4" style={{ height: "55vh" }}>
        <Kalend
          events={events}
          initialDate={new Date().toISOString()}
          hourHeight={60}
          initialView={CalendarView.MONTH}
          disabledViews={[
            CalendarView.DAY,
            CalendarView.WEEK,
            CalendarView.THREE_DAYS,
          ]}
          timeFormat={"24"}
          weekDayStart={"Monday"}
          language={"en"}
        />
      </div>
    </div>
  );
};

export default SectionCalendar;
