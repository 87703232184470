import React, { Suspense, useEffect, useState } from "react";
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import route_client from "src/routes/route_client";
import { NotFound } from "src/pages/guest";
import "./style.css";
import {
  IoCalendarOutline,
  IoEaselOutline,
  IoHomeOutline,
  IoListCircleOutline,
  IoLogOutOutline,
  IoNewspaperOutline,
  IoPersonCircleOutline,
  IoReaderOutline,
  IoSettingsOutline,
} from "react-icons/io5";
import { Logo } from "src/assets";
import { PopoverBody, UncontrolledPopover } from "reactstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setDataUserInfo } from "src/config/redux/action";

const loading = (
  <div className="mt-4 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const LayoutGuest = () => {
  let navigate = useNavigate();
  const { gbDataUser } = useSelector((state) => state.globalReducer);
  const dispatch = useDispatch();

  const isInitiallyVisible = false;
  const [isKeyboardVisible, setKeyboardVisible] = useState(isInitiallyVisible);

  let fullWindowHeight = window.innerHeight;

  window.addEventListener("resize", function () {
    if (window.innerHeight == fullWindowHeight) {
      setKeyboardVisible(false);
    } else if (window.innerHeight < fullWindowHeight * 0.9) {
      setKeyboardVisible(true);
    }
  });

  useEffect(() => {
    dispatch(setDataUserInfo());
  }, [dispatch]);

  const btnLogout = () => {
    localStorage.removeItem("TOKEN");
    navigate("/login");
  };

  const { pathname } = useLocation();

  return (
    <>
      <section className="sidebar d-none d-md-block">
        <div className="content" tabIndex={0}>
          <div className="card-menu bg-primary p-4">
            <center>
              <img src={Logo} width={70} className={"img-fluid mb-3"} />
            </center>
            <div
              className={`mt-4 text-center ${
                pathname === "/clientarea/" ? "active" : ""
              }
              `}
            >
              <Link to={"/clientarea/"}>
                <div>
                  <IoHomeOutline color="white" size={40} />
                </div>
                <label className="text-menu">Utama</label>
              </Link>
            </div>
            <div
              className={`mt-4 text-center ${
                pathname === "/clientarea/elearning" ? "active" : ""
              }
              `}
            >
              <Link to={"/clientarea/elearning"}>
                <div>
                  <IoEaselOutline color="white" size={40} />
                </div>
                <label className="text-menu">Belajar</label>
              </Link>
            </div>
            <div
              className={`mt-4 text-center ${
                pathname === "/clientarea/mutabaah" ? "active" : ""
              }
              `}
            >
              <Link to={"/clientarea/mutabaah"}>
                <div>
                  <IoReaderOutline color="white" size={40} />
                </div>
                <label className="text-menu">Mutabaah</label>
              </Link>
            </div>
            <div
              className={`mt-4 text-center ${
                pathname === "/clientarea/qiroaty" ? "active" : ""
              }
              `}
            >
              <Link to={"/clientarea/qiroaty"}>
                <div>
                  <IoNewspaperOutline color="white" size={40} />
                </div>
                <label className="text-menu">Qiroaty</label>
              </Link>
            </div>
            <div
              className={`mt-4 text-center ${
                pathname === "/clientarea/calendar" ? "active" : ""
              }
              `}
            >
              <Link to={"/clientarea/calendar"}>
                <div>
                  <IoCalendarOutline color="white" size={40} />
                </div>
                <label className="text-menu">Kalender</label>
              </Link>
            </div>
            <div
              className={`mt-4 text-center ${
                pathname === "/clientarea/update-profile" ? "active" : ""
              }
              `}
            >
              <Link to={"/clientarea/update-profile"}>
                <div>
                  <IoSettingsOutline color="white" size={40} />
                </div>
                <label className="text-menu">Pengaturan</label>
              </Link>
            </div>
            <div
              className={`mt-4 text-center ${
                pathname === "/clientarea/logout" ? "active" : ""
              }
              `}
            >
              <span onClick={() => btnLogout()}>
                <div>
                  <IoLogOutOutline color="white" size={40} />
                </div>
                <label className="text-menu">Logout</label>
              </span>
            </div>
          </div>
        </div>
      </section>
      <main className="main-wrapper">
        <div className="container-fluid">
          <Suspense fallback={loading}>
            <Routes>
              {route_client.map(({ path, name, Component, exact }, key) => {
                return (
                  <Route
                    key={key}
                    exact={exact}
                    path={path}
                    name={name}
                    element={<Component name={name} />}
                  />
                );
              })}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
        </div>
        <div className="d-block d-sm-none py-5"></div>
      </main>

      {!isKeyboardVisible && (
        <>
          <div className="d-block d-md-none bottom-nav">
            <div className="d-flex justify-content-between align-items-center card-bottom-nav">
              <IoHomeOutline
                color="#11A887"
                size={30}
                onClick={() => navigate("/clientarea/")}
              />
              <IoEaselOutline
                color="#11A887"
                size={30}
                onClick={() => navigate("/clientarea/elearning")}
              />
              <IoReaderOutline
                color="#11A887"
                size={30}
                onClick={() => navigate("/clientarea/mutabaah")}
              />
              <button
                className="button-transparent"
                type="button"
                id="ListMenuMobile"
              >
                <IoListCircleOutline color="#11A887" size={30} />
              </button>
            </div>
            <UncontrolledPopover
              placement="top"
              target="ListMenuMobile"
              trigger="legacy"
            >
              <PopoverBody>
                <div
                  className="d-flex align-items-center link-menu-mobile"
                  onClick={() => navigate("/clientarea/qiroaty")}
                >
                  <IoPersonCircleOutline color="#11A887" size={20} />
                  <span className="ms-2">Qiroaty</span>
                </div>
                <div
                  className="d-flex align-items-center link-menu-mobile"
                  onClick={() => navigate("/clientarea/update-profile")}
                >
                  <IoSettingsOutline color="#11A887" size={20} />
                  <span className="ms-2">Pengaturan</span>
                </div>
                <div
                  className="d-flex align-items-center link-menu-mobile"
                  onClick={() => navigate("/clientarea/update-profile")}
                >
                  <IoLogOutOutline color="#11A887" size={20} />
                  <span className="ms-2">Keluar</span>
                </div>
              </PopoverBody>
            </UncontrolledPopover>
          </div>
        </>
      )}
    </>
  );
};

export default LayoutGuest;
