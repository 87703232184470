import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SectionBanner from "./parts/SectionBanner";
import SectionForm from "./parts/SectionForm";
import "./style.css";

const Login = () => {
  let navigate = useNavigate();
  const user = localStorage.getItem("TOKEN");
  useEffect(() => {
    if (user !== null) navigate("/clientarea");
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="container-fluid bg-white">
        <div className="row">
          <SectionForm />
          <SectionBanner />
        </div>
      </div>
    </>
  );
};

export default Login;
