import { swalError } from "./loadingSwal";

export const capitalizeFirstLetter = (
  [first, ...rest],
  locale = navigator.language
) => first.toLocaleUpperCase(locale) + rest.join("");

export const FUNCArraySelectId = (data) => {
  var array = [];
  data.map((v, i) => {
    array.push({
      value: v.Id,
      label: v.Nama,
    });
  });
  return array;
};

export const FUNCArraySelectKode = (data) => {
  var array = [];
  data.map((v, i) => {
    array.push({
      value: v.Kode,
      label: v.Nama,
    });
  });
  return array;
};

export const FUNCDateToString = (date) => {
  var Date = date.getDate();
  var Month = date.getMonth() + 1;
  var Year = date.getFullYear();
  if (Date < 10) Date = "0" + Date;
  if (Month < 10) Month = "0" + Month;
  return Date + "-" + Month + "-" + Year;
};

export const FUNCIndoDate = (date) => {
  var SplitTanggal = date.split("-");
  var Hari = SplitTanggal[0];
  var Bulan = SplitTanggal[1];
  var Tahun = SplitTanggal[2];

  var ArrayBulan = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];
  if (Bulan < 10) {
    Bulan = Bulan.replace("0", "");
  }

  return Hari + " " + ArrayBulan[Bulan - 1] + " " + Tahun;
};

export const FUNCValidateUploadFileSize = (
  fi,
  maxSize = 2048,
  strMaxSize = "2MB"
) => {
  if (fi.files.length > 0) {
    for (var i = 0; i <= fi.files.length - 1; i++) {
      const fsize = fi.files.item(i).size;
      const file = Math.round(fsize / 1024);
      if (file >= maxSize) {
        swalError(`Ukuran file terlalu besar, batas ukuran ${strMaxSize}`);
        fi.value = "";
        return null;
      }
    }
  }
};

export const FUNCValidateUploadFileExtension = (
  oInput,
  _validFileExtensions = [".jpg", ".jpeg", ".pdf", ".png"]
) => {
  var sFileName = oInput.value;
  if (sFileName.length > 0) {
    var blnValid = false;
    var msgExtension = "";
    for (var j = 0; j < _validFileExtensions.length; j++) {
      msgExtension += _validFileExtensions[j] + " ";
      var sCurExtension = _validFileExtensions[j];
      if (
        sFileName
          .substr(sFileName.length - sCurExtension.length, sCurExtension.length)
          .toLowerCase() == sCurExtension.toLowerCase()
      ) {
        blnValid = true;
        break;
      }
    }

    if (!blnValid) {
      swalError(
        `Ekstensi file tidak didukung! <br /> format harus ${msgExtension}`
      );
      oInput.value = "";
      return false;
    }
  }
};

export const FUNCDateDmytoYmd = (date) => {
  var b = date.split(/\D/);
  return b.reverse().join("-");
};

export const FUNCIsUrl = (string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
};
