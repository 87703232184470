import React from "react";

const ItemCardMapel = ({
  time,
  onClick,
  title,
  mapel,
  isTask,
  scoreCat,
  scoreRes,
  teach,
}) => {
  return (
    <div className="card-study" onClick={onClick}>
      <div className="d-flex">
        <div className="title-study">
          <span>{time}</span>
        </div>
        <div className="ms-3">
          <div className="mb-3">
            <h5>{mapel}</h5>
            <h3>{title}</h3>
            <div className="d-flex align-items-center">
              {isTask && (
                <span className="badge badge-warning me-1">Tugas</span>
              )}
              {scoreCat === "Harian" && (
                <span className="badge badge-custom-purple me-1">Harian</span>
              )}
              {scoreCat === "PTS" && (
                <span className="badge badge-custom-blue me-1">PTS</span>
              )}
            </div>
          </div>

          <h6>{teach}</h6>
        </div>
      </div>
      <div className="ms-3">
        {scoreRes != null && (
          <div className="card-score">
            <span>{scoreRes}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ItemCardMapel;
