const initialState = {
  dataInboxs: [],
  dataInbox: false,
  modalInbox: false,
};

const inboxReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DATA_INBOXS":
      return { ...state, dataInboxs: action.payload };
    case "DATA_INBOX":
      return { ...state, dataInbox: action.payload };
    case "MODAL_INBOX":
      return { ...state, modalInbox: action.payload };

    default:
      return state;
  }
};

export default inboxReducer;
