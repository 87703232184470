import axios from "axios";
import Swal from "sweetalert2";

const TOKEN = localStorage.getItem("TOKEN");
export const http = axios.create({
  timeout: 60000,
  withCredentials: false,
  headers: {},
});

http.interceptors.request.use(
  (request) => {
    const token = TOKEN;
    if (token && token.length > 0)
      request.headers.Authorization = `Bearer ${token}`;
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      const { data } = error.response;
      if (error.response.status === 401) {
        // Swal.fire('Session Expired', `Silahkan login kembali...`, 'success').then(() => window.location = '/')
        //
        console.log("401", error.response);

        if (
          data.name === "NotAuthenticated" &&
          data.data &&
          data.data.name === "TokenExpiredError"
        ) {
          Swal.fire(
            "Session Expired",
            `Token expired. Please try login again`,
            "error"
          ).then(() => (window.location = "/"));
          return Promise.reject({
            message: "Token expired. Please try login again.",
          });
        } else {
          Swal.fire({
            title: "Session Expired",
            text: "Token expired. Please try login again",
            confirmButtonColor: "#f54503",
            icon: "error",
          }).then(() => (window.location = "/"));
          localStorage.clear();
          return Promise.reject({
            message: "Login failed. Please check your email and password!",
          });
        }
      } else {
        Swal.fire({
          title: "Terjadi Kesalahan",
          text: JSON.stringify(error.message),
          confirmButtonColor: "#f54503",
          icon: "error",
        });
        let message = data.message || error.message;
        return Promise.reject({ message, raw: data });
      }
    } else if (error.request) {
      // Swal.fire('Error Connection', `There is problem connecting to server. Please check your connection!`, 'error').then(() => window.location = '/')
      // localStorage.clear();
      return Promise.reject({
        message:
          "There is problem connecting to server. Please check your connection!",
      });
    } else {
      return Promise.reject({ message: error.message });
    }
  }
);

export default http;
