import {
  http,
  URLSVC,
  swalError,
  swalLoading,
  FUNCDateToString,
} from "src/config";
import Swal from "sweetalert2";

export const setFormUser = (formType, formValue) => {
  return { type: "FORM_USER", formType, formValue };
};

export const setDataInfoProfil = () => {
  return (dispatch) => {
    http.get(`${URLSVC}/Siswas/GetProfil`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        dispatch({ type: "DATA_INFO_PROFIL", payload: data.Data });
      } else {
        swalError(data.ReturnMessage);
      }
    });
  };
};

export const setDataInfoOrtuWali = () => {
  return (dispatch) => {
    http.get(`${URLSVC}/Siswas/GetInfoOrtuWali`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        dispatch({ type: "DATA_INFO_ORTU_WALI", payload: data.Data });
      } else {
        swalError(data.ReturnMessage);
      }
    });
  };
};

export const setDataInfoKeluarga = () => {
  return (dispatch) => {
    http.get(`${URLSVC}/Siswas/GetInfoKeluarga`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        dispatch({ type: "DATA_INFO_KELUARGA", payload: data.Data });
      } else {
        swalError(data.ReturnMessage);
      }
    });
  };
};

export const setDataInfoKesehatan = () => {
  return (dispatch) => {
    http.get(`${URLSVC}/Siswas/GetInfoKesehatan`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        dispatch({ type: "DATA_INFO_KESEHATAN", payload: data.Data });
      } else {
        swalError(data.ReturnMessage);
      }
    });
  };
};

export const setDataInfoSekolahAsal = () => {
  return (dispatch) => {
    http.get(`${URLSVC}/Siswas/GetInfoSekolahAsal`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        dispatch({ type: "DATA_INFO_SEKOLAH_ASAL", payload: data.Data });
      } else {
        swalError(data.ReturnMessage);
      }
    });
  };
};

export const setDataInfoKip = () => {
  return (dispatch) => {
    http.get(`${URLSVC}/Siswas/GetInfoKip`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        dispatch({ type: "DATA_INFO_KIP", payload: data.Data });
      } else {
        swalError(data.ReturnMessage);
      }
    });
  };
};

export const setDataInfoIbu = () => {
  return (dispatch) => {
    http.get(`${URLSVC}/Siswas/GetSiswaIbu`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        console.log(data.Data.Nama);
        dispatch({ type: "DATA_INFO_IBU", payload: data.Data });
      } else {
        // swalError(data.ReturnMessage);
      }
    });
  };
};

export const setDataInfoAyah = () => {
  return (dispatch) => {
    http.get(`${URLSVC}/Siswas/GetSiswaAyah`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        dispatch({ type: "DATA_INFO_AYAH", payload: data.Data });
      } else {
        // swalError(data.ReturnMessage);
      }
    });
  };
};

export const setDataInfoBeasiswas = () => {
  return (dispatch) => {
    http.get(`${URLSVC}/Siswas/GetSiswaBeasiswas`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        dispatch({ type: "DATA_INFO_BEASISWAS", payload: data.Data });
      } else {
        dispatch({ type: "DATA_INFO_BEASISWAS", payload: [] });
        // swalError(data.ReturnMessage);
      }
    });
  };
};

export const setDataInfoBeasiswa = (id) => {
  return (dispatch) => {
    http
      .get(`${URLSVC}/Siswas/GetSiswaBeasiswa?IdSiswaBeasiswa=${id}`)
      .then((res) => {
        let data = res.data;
        if (data.IsSuccess) {
          dispatch({ type: "DATA_INFO_BEASISWA", payload: data.Data });
          dispatch({
            type: "MODAL_BEASISWA",
            payload: true,
          });
        } else {
          // swalError(data.ReturnMessage);
        }
      });
  };
};

export const setDataInfoPrestasis = () => {
  return (dispatch) => {
    http.get(`${URLSVC}/Siswas/GetSiswaPrestasis`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        dispatch({ type: "DATA_INFO_PRESTASIS", payload: data.Data });
      } else {
        dispatch({ type: "DATA_INFO_PRESTASIS", payload: [] });
        // swalError(data.ReturnMessage);
      }
    });
  };
};

export const setDataInfoPrestasi = (id) => {
  return (dispatch) => {
    http
      .get(`${URLSVC}/Siswas/GetSiswaPrestasi?IdSiswaPrestasi=${id}`)
      .then((res) => {
        let data = res.data;
        if (data.IsSuccess) {
          dispatch({ type: "DATA_INFO_PRESTASI", payload: data.Data });
          dispatch({
            type: "MODAL_PRESTASI",
            payload: true,
          });
        } else {
          // swalError(data.ReturnMessage);
        }
      });
  };
};

export const setDataAlamatOrtuWali = (id, setValue) => {
  return (dispatch) => {
    http
      .get(`${URLSVC}/Siswas/GetAlamatOrtuWali?IdStatusTempatTinggal=${id}`)
      .then((res) => {
        let data = res.data;
        setValue(
          "IdProvinsi",
          data.Data?.Provinsi?.Nama && {
            value: data.Data?.Provinsi?.Id,
            label: data.Data?.Provinsi?.Nama,
          }
        );
        setValue(
          "IdKabkot",
          data.Data?.Kabkot?.Nama && {
            value: data.Data?.Kabkot?.Id,
            label: data.Data?.Kabkot?.Nama,
          }
        );
        setValue(
          "IdKecamatan",
          data.Data?.Kecamatan?.Nama && {
            value: data.Data?.Kecamatan?.Id,
            label: data.Data?.Kecamatan?.Nama,
          }
        );
        setValue(
          "IdDesa",
          data.Data?.Desa?.Nama && {
            value: data.Data?.Desa?.Id,
            label: data.Data?.Desa?.Nama,
          }
        );
        setValue(
          "KdAlamatTunjuk",
          data.Data?.AlamatTunjuk?.Nama && {
            value: data.Data?.AlamatTunjuk?.Kode,
            label: data.Data?.AlamatTunjuk?.Nama,
          }
        );
        setValue("Alamat", data.Data?.Alamat);
        setValue("Longitude", data.Data?.Longitude);
        setValue("Latitude", data.Data?.Latitude);
        setValue("KdPos", data.Data?.KdPos);
        setValue("Rt", data.Data?.Rt);
        setValue("Rw", data.Data?.Rw);
        setValue("Dusun", data.Data?.Dusun);
      });
  };
};

export const setDataAlamatAyah = (setValue) => {
  return (dispatch) => {
    http.get(`${URLSVC}/Siswas/GetAlamatAyahSiswa`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        dispatch({ type: "DATA_ALAMAT_AYAH", payload: true });
      }
      setValue(
        "IdProvinsi",
        data.Data?.Provinsi?.Nama && {
          value: data.Data?.Provinsi?.Id,
          label: data.Data?.Provinsi?.Nama,
        }
      );
      setValue(
        "IdKabkot",
        data.Data?.Kabkot?.Nama && {
          value: data.Data?.Kabkot?.Id,
          label: data.Data?.Kabkot?.Nama,
        }
      );
      setValue(
        "IdKecamatan",
        data.Data?.Kecamatan?.Nama && {
          value: data.Data?.Kecamatan?.Id,
          label: data.Data?.Kecamatan?.Nama,
        }
      );
      setValue(
        "IdDesa",
        data.Data?.Desa?.Nama && {
          value: data.Data?.Desa?.Id,
          label: data.Data?.Desa?.Nama,
        }
      );
      setValue(
        "KdAlamatTunjuk",
        data.Data?.AlamatTunjuk?.Nama && {
          value: data.Data?.AlamatTunjuk?.Kode,
          label: data.Data?.AlamatTunjuk?.Nama,
        }
      );
      setValue("Alamat", data.Data?.Alamat);
      setValue("Longitude", data.Data?.Longitude);
      setValue("Latitude", data.Data?.Latitude);
      setValue("KdPos", data.Data?.KdPos);
      setValue("Rt", data.Data?.Rt);
      setValue("Rw", data.Data?.Rw);
      setValue("Dusun", data.Data?.Dusun);
    });
  };
};

export const setDataInfoWali = () => {
  return (dispatch) => {
    http.get(`${URLSVC}/Siswas/GetSiswaWali`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        dispatch({ type: "DATA_INFO_WALI", payload: data.Data });
      } else {
        // swalError(data.ReturnMessage);
      }
    });
  };
};

export const apiInfoUpdateProfil = (iData) => {
  return (dispatch) => {
    var fd = new FormData();

    fd.append("Nisn", iData.Nisn);
    fd.append("NamaPanggilan", iData.NamaPanggilan);
    fd.append("NoHandphone", iData.NoHandphone);
    fd.append("NoDarurat", iData.NoDarurat);
    fd.append("Email", iData.Email);
    fd.append("KdGolonganDarah", iData.IdGolonganDarah?.value);
    fd.append("IdKewarganegaraan", iData.IdKewarganegaraan);
    fd.append("AsalNegara", iData.AsalNegara);
    fd.append("NoKitas", iData.NoKitas);
    fd.append("IdBahasa", iData.IdBahasa?.value);
    fd.append("TinggiBadan", iData.TinggiBadan);
    fd.append("BeratBadan", iData.BeratBadan);
    fd.append("LingkarKepala", iData.LingkarKepala);
    fd.append("AnakKe", iData.AnakKe);
    fd.append("JumlahSodaraKandung", iData.JumlahSodaraKandung);
    fd.append("JumlahSodaraTiri", iData.JumlahSodaraTiri);
    fd.append("JumlahSodaraAngkat", iData.JumlahSodaraAngkat);
    fd.append("IdStatusTempatTinggal", iData.IdStatusTempatTinggal?.value);
    fd.append("KdAlamatTunjuk", iData.KdAlamatTunjuk?.value || ""); ///bingung
    fd.append("Alamat", iData.Alamat);
    fd.append("Rt", iData.Rt);
    fd.append("Rw", iData.Rw);
    fd.append("Dusun", iData.Dusun);
    fd.append("IdWilDesa", iData.IdDesa?.value);
    fd.append("KdPos", iData.KdPos);
    fd.append("Longitude", iData.Longitude);
    fd.append("Latitude", iData.Latitude);
    fd.append("IdJenisTransportasi", iData.IdJenisTransportasi?.value);
    fd.append("JarakSekolah", iData.JarakSekolah);
    fd.append("WaktuTempuh", iData.WaktuTempuh);
    fd.append("IdJenisBiayaSekolah", iData.IdJenisBiayaSekolah?.value);
    fd.append("IdKebutuhanKhusus", iData.IdKebutuhanKhusus?.value);
    fd.append("KebutuhanDisabilitas", iData.KebutuhanDisabilitas?.value); /// masih boolean
    fd.append("IdPenyakitDiderita", iData.IdPenyakitDiderita?.value);
    fd.append("IdKelainanJasmani", iData.IdKelainanJasmani?.value);
    fd.append("PernahTkFormal", iData.PernahTkFormal || false);
    fd.append("PernahTkInformal", iData.PernahTkInformal || false);
    fd.append("IdHobi", iData.IdHobi?.value);
    fd.append("IdCita2", iData.IdCita2?.value);
    fd.append("SekolahAsal", iData.SekolahAsal || "-");
    fd.append("AlamatSekolahAsal", iData.AlamatSekolahAsal || "-");
    fd.append("NspnSekolahAsal", iData.NspnSekolahAsal || "-");
    fd.append("PunyaWali", iData.PunyaWali);
    fd.append("ImunisasiHepatitis", iData.ImunisasiHepatitis);
    fd.append("ImunisasiBcg", iData.ImunisasiBcg);
    fd.append("ImunisasiDpt", iData.ImunisasiDpt);
    fd.append("ImunisasiPolio", iData.ImunisasiPolio);
    fd.append("ImunisasiCampak", iData.ImunisasiCampak);
    fd.append("ImunisasiCovid", iData.ImunisasiCovid);
    fd.append("NoKk", iData.NoKk);
    fd.append("NoKip", iData.TerimaKip === "true" ? iData.NoKip : "");
    fd.append("NoAkta", iData.NoAkta);
    fd.append("NoKtpAyah", iData.NoKtpAyah);
    fd.append("NoKtpIbu", iData.NoKtpIbu);
    fd.append("NoKks", iData.NoKks);
    fd.append("NoPkh", iData.NoPkh);
    fd.append("FotoProfile", iData.FotoProfile);
    fd.append("FileKk", iData.FileKk);
    fd.append("FileKip", iData.FileKip);
    fd.append("FileAkta", iData.FileAkta);
    fd.append("FileKtpAyah", iData.FileKtpAyah);
    fd.append("FileKtpIbu", iData.FileKtpIbu);
    fd.append("FileKks", iData.FileKks);
    fd.append("FilePkh", iData.FilePkh);

    swalLoading(true);
    http
      .post(`${URLSVC}/Siswas/UpdateProfileDetail`, fd)
      .then((res) => {
        let data = res.data;
        if (data.IsSuccess) {
          Swal.fire({
            title: "Berhasil",
            text: "Anda berhasil memperbarui data siswa",
            confirmButtonColor: "#11A887",
            icon: "success",
          }).then(() => window.location.reload());
        } else {
          swalError(data.ReturnMessage);
        }
      })
      .catch((e) => {
        swalError(e.message);
      });
  };
};

export const apiInfoUpdateDataIbu = (iData) => {
  return (dispatch) => {
    var fd = new FormData();

    fd.append("IdSiswaOrtu", iData.IdSiswaOrtu);
    fd.append("IdTipe", 2);
    fd.append("Nama", iData.Nama);
    fd.append("IdStatus", iData.IdStatus?.value);
    fd.append("IdKewarganegaraan", iData.IdKewarganegaraan);
    fd.append("AsalNegara", iData.AsalNegara);
    fd.append("NoKitas", iData.NoKitas);
    fd.append("Nik", iData.Nik);
    fd.append("TempatLahir", iData.TempatLahir);
    fd.append("TanggalLahir", FUNCDateToString(iData.TanggalLahir));
    fd.append(
      "IdJenisJenjangPendidikan",
      iData.IdJenisJenjangPendidikan?.value
    );
    fd.append("IdJenisPekerjaan", iData.IdJenisPekerjaan?.value);
    fd.append("NamaInstansi", iData.NamaInstansi);
    fd.append("Penghasilan", iData.Penghasilan);
    fd.append("NoHp", iData.NoHp);
    fd.append("Email", iData.Email);
    fd.append("IdDomisili", iData.IdDomisili);
    fd.append("IdJenisTempatTinggal", iData.IdJenisTempatTinggal?.value);
    fd.append("IsIbuTinggalDenganAyah", iData.IsIbuTinggalDenganAyah || false);
    fd.append("KdAlamatTunjuk", iData.KdAlamatTunjuk?.value || "Gg");
    fd.append("Alamat", iData.Alamat || "-");
    fd.append("Rt", iData.Rt || "-");
    fd.append("Rw", iData.Rw || "-");
    fd.append("Dusun", iData.Dusun || "-");
    fd.append("IdWilDesa", iData.IdDesa?.value);
    fd.append("KdPos", iData.KdPos || "00000");
    fd.append("Longitude", iData.Longitude || "-");
    fd.append("Latitude", iData.Latitude || "-");

    swalLoading(true);
    http
      .post(`${URLSVC}/Siswas/EditSiswaIbu`, fd)
      .then((res) => {
        let data = res.data;
        if (data.IsSuccess) {
          Swal.fire({
            title: "Berhasil",
            text: "Anda berhasil memperbarui data ibu",
            confirmButtonColor: "#11A887",
            icon: "success",
          }).then(() => window.location.reload());
        } else {
          swalError(data.ReturnMessage);
        }
      })
      .catch((e) => {
        swalError(e.message);
      });
  };
};

export const apiInfoUpdateDataAyah = (iData) => {
  return (dispatch) => {
    var fd = new FormData();

    fd.append("IdSiswaOrtu", iData.IdSiswaOrtu);
    fd.append("IdTipe", 2);
    fd.append("Nama", iData.Nama);
    fd.append("IdStatus", iData.IdStatus?.value);
    fd.append("IdKewarganegaraan", iData.IdKewarganegaraan);
    fd.append("AsalNegara", iData.AsalNegara);
    fd.append("NoKitas", iData.NoKitas);
    fd.append("Nik", iData.Nik);
    fd.append("TempatLahir", iData.TempatLahir);
    fd.append("TanggalLahir", FUNCDateToString(iData.TanggalLahir));
    fd.append(
      "IdJenisJenjangPendidikan",
      iData.IdJenisJenjangPendidikan?.value
    );
    fd.append("IdJenisPekerjaan", iData.IdJenisPekerjaan?.value);
    fd.append("NamaInstansi", iData.NamaInstansi);
    fd.append("Penghasilan", iData.Penghasilan);
    fd.append("NoHp", iData.NoHp);
    fd.append("Email", iData.Email);
    fd.append("IdDomisili", iData.IdDomisili);
    fd.append("IdJenisTempatTinggal", iData.IdJenisTempatTinggal?.value);
    fd.append("KdAlamatTunjuk", iData.KdAlamatTunjuk?.value || "Gg");
    fd.append("Alamat", iData.Alamat || "-");
    fd.append("Rt", iData.Rt || "-");
    fd.append("Rw", iData.Rw || "-");
    fd.append("Dusun", iData.Dusun || "-");
    fd.append("IdWilDesa", iData.IdDesa?.value);
    fd.append("KdPos", iData.KdPos || "00000");
    fd.append("Longitude", iData.Longitude || "-");
    fd.append("Latitude", iData.Latitude || "-");

    swalLoading(true);
    http
      .post(`${URLSVC}/Siswas/EditSiswaAyah`, fd)
      .then((res) => {
        let data = res.data;
        if (data.IsSuccess) {
          Swal.fire({
            title: "Berhasil",
            text: "Anda berhasil memperbarui data ayah",
            confirmButtonColor: "#11A887",
            icon: "success",
          }).then(() => window.location.reload());
        } else {
          swalError(data.ReturnMessage);
        }
      })
      .catch((e) => {
        swalError(e.message);
      });
  };
};

export const apiInfoUpdateDataWali = (iData) => {
  return (dispatch) => {
    var fd = new FormData();

    fd.append("IdSiswaOrtu", iData.IdSiswaOrtu);
    fd.append("IdTipe", 2);
    fd.append("Nama", iData.Nama);
    fd.append("IdStatus", iData.IdStatus?.value);
    fd.append("IdKewarganegaraan", iData.IdKewarganegaraan);
    fd.append("AsalNegara", iData.AsalNegara);
    fd.append("NoKitas", iData.NoKitas);
    fd.append("Nik", iData.Nik);
    fd.append("TempatLahir", iData.TempatLahir);
    fd.append("TanggalLahir", FUNCDateToString(iData.TanggalLahir));
    fd.append(
      "IdJenisJenjangPendidikan",
      iData.IdJenisJenjangPendidikan?.value
    );
    fd.append("IdJenisPekerjaan", iData.IdJenisPekerjaan?.value);
    fd.append("NamaInstansi", iData.NamaInstansi);
    fd.append("Penghasilan", iData.Penghasilan);
    fd.append("NoHp", iData.NoHp);
    fd.append("Email", iData.Email);
    fd.append("IdDomisili", iData.IdDomisili);
    fd.append("IdJenisTempatTinggal", iData.IdJenisTempatTinggal?.value);
    fd.append("KdAlamatTunjuk", iData.KdAlamatTunjuk?.value || "Gg");
    fd.append("Alamat", iData.Alamat || "-");
    fd.append("Rt", iData.Rt || "-");
    fd.append("Rw", iData.Rw || "-");
    fd.append("Dusun", iData.Dusun || "-");
    fd.append("IdWilDesa", iData.IdDesa?.value);
    fd.append("KdPos", iData.KdPos || "00000");
    fd.append("Longitude", iData.Longitude || "-");
    fd.append("Latitude", iData.Latitude || "-");

    swalLoading(true);
    http
      .post(`${URLSVC}/Siswas/EditSiswaWali`, fd)
      .then((res) => {
        let data = res.data;
        if (data.IsSuccess) {
          Swal.fire({
            title: "Berhasil",
            text: "Anda berhasil memperbarui data wali",
            confirmButtonColor: "#11A887",
            icon: "success",
          }).then(() => window.location.reload());
        } else {
          swalError(data.ReturnMessage);
        }
      })
      .catch((e) => {
        swalError(e.message);
      });
  };
};

export const apiInfoAddDataWali = (iData) => {
  return (dispatch) => {
    var fd = new FormData();

    fd.append("Nama", iData.Nama);
    fd.append("IdStatus", iData.IdStatus?.value);
    fd.append("IdKewarganegaraan", iData.IdKewarganegaraan);
    fd.append("AsalNegara", iData.AsalNegara);
    fd.append("NoKitas", iData.NoKitas);
    fd.append("Nik", iData.Nik);
    fd.append("TempatLahir", iData.TempatLahir);
    fd.append("TanggalLahir", FUNCDateToString(iData.TanggalLahir));
    fd.append(
      "IdJenisJenjangPendidikan",
      iData.IdJenisJenjangPendidikan?.value
    );
    fd.append("IdJenisPekerjaan", iData.IdJenisPekerjaan?.value);
    fd.append("NamaInstansi", iData.NamaInstansi);
    fd.append("Penghasilan", iData.Penghasilan);
    fd.append("NoHp", iData.NoHp);
    fd.append("Email", iData.Email);
    fd.append("IdDomisili", iData.IdDomisili);
    fd.append("IdJenisTempatTinggal", iData.IdJenisTempatTinggal?.value);
    fd.append("KdAlamatTunjuk", iData.KdAlamatTunjuk?.value || "Gg");
    fd.append("Alamat", iData.Alamat || "-");
    fd.append("Rt", iData.Rt || "-");
    fd.append("Rw", iData.Rw || "-");
    fd.append("Dusun", iData.Dusun || "-");
    fd.append("IdWilDesa", iData.IdDesa?.value);
    fd.append("KdPos", iData.KdPos || "00000");
    fd.append("Longitude", iData.Longitude || "-");
    fd.append("Latitude", iData.Latitude || "-");

    swalLoading(true);
    http
      .post(`${URLSVC}/Siswas/AddSiswaWali`, fd)
      .then((res) => {
        let data = res.data;
        if (data.IsSuccess) {
          Swal.fire({
            title: "Berhasil",
            text: "Anda berhasil menambah data wali",
            confirmButtonColor: "#11A887",
            icon: "success",
          }).then(() => window.location.reload());
        } else {
          swalError(data.ReturnMessage);
        }
      })
      .catch((e) => {
        swalError(e.message);
      });
  };
};

export const apiInfoAddPrestasi = (iData, closeModal, resetValue) => {
  return (dispatch) => {
    var fd = new FormData();

    fd.append("Tahun", iData.Tahun);
    fd.append("Nama", iData.Nama);
    fd.append("IdJenisPrestasi", iData.IdJenisPrestasi?.value);
    fd.append("IdJenisTingkatPrestasi", iData.IdJenisTingkatPrestasi?.value);
    fd.append("IdJenisPeringkat", iData.IdJenisPeringkat?.value);
    fd.append("IdKategori", iData.IdKategori?.value);
    fd.append("NamaPenyelenggara", iData.NamaPenyelenggara);
    fd.append("FileSertifikat", iData.FileSertifikat);

    swalLoading(true);
    http
      .post(`${URLSVC}/Siswas/AddSiswaPrestasi`, fd)
      .then((res) => {
        let data = res.data;
        if (data.IsSuccess) {
          Swal.fire({
            title: "Berhasil",
            text: "Anda berhasil menambah prestasi",
            confirmButtonColor: "#11A887",
            icon: "success",
          });
          dispatch(setDataInfoPrestasis());
          closeModal();
          resetValue();
        } else {
          swalError(data.ReturnMessage);
        }
      })
      .catch((e) => {
        swalError(e.message);
      });
  };
};

export const apiInfoEditPrestasi = (iData, closeModal, resetValue) => {
  return (dispatch) => {
    var fd = new FormData();

    fd.append("IdSiswaPrestasi", iData.IdSiswaPrestasi);
    fd.append("Tahun", iData.Tahun);
    fd.append("Nama", iData.Nama);
    fd.append("IdJenisPrestasi", iData.IdJenisPrestasi?.value);
    fd.append("IdJenisTingkatPrestasi", iData.IdJenisTingkatPrestasi?.value);
    fd.append("IdJenisPeringkat", iData.IdJenisPeringkat?.value);
    fd.append("IdKategori", iData.IdKategori?.value);
    fd.append("NamaPenyelenggara", iData.NamaPenyelenggara);
    fd.append("FileSertifikat", iData.FileSertifikat);

    swalLoading(true);
    http
      .post(`${URLSVC}/Siswas/EditSiswaPrestasi`, fd)
      .then((res) => {
        let data = res.data;
        if (data.IsSuccess) {
          Swal.fire({
            title: "Berhasil",
            text: "Anda berhasil memperbarui prestasi",
            confirmButtonColor: "#11A887",
            icon: "success",
          });
          dispatch(setDataInfoPrestasis());
          closeModal();
          resetValue();
        } else {
          swalError(data.ReturnMessage);
        }
      })
      .catch((e) => {
        swalError(e.message);
      });
  };
};

export const apiInfoDeletePrestasi = (id, nama) => {
  return (dispatch) => {
    Swal.fire({
      title: "Apakah anda yakin?",
      text: `Anda akan hapus prestasi (${nama})!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya, saya yakin!",
    }).then((result) => {
      if (result.isConfirmed) {
        swalLoading(true);
        http
          .get(`${URLSVC}/Siswas/DeleteSiswaPrestasi?IdSiswaPrestasi=${id}`)
          .then((res) => {
            swalLoading(false);
            let data = res.data;
            if (data.IsSuccess) {
              Swal.fire({
                title: "Berhasil",
                text: `Anda berhasil hapus prestasi (${nama})`,
                confirmButtonColor: "#11A887",
                icon: "success",
              });
              dispatch(setDataInfoPrestasis());
            } else {
              Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
            }
          });
      }
    });
  };
};

export const apiInfoAddBeasiswa = (iData, closeModal, resetValue) => {
  return (dispatch) => {
    var fd = new FormData();

    fd.append("Tahun", iData.Tahun);
    fd.append("IdKategori", iData.IdKategori?.value);
    fd.append("Nama", iData.Nama);
    fd.append("IdJenisPemberi", iData.IdJenisPemberi?.value);
    fd.append("NamaPemberi", iData.NamaPemberi);
    fd.append("TanggalMulai", FUNCDateToString(iData.TanggalMulai));
    fd.append("TanggalSelesai", FUNCDateToString(iData.TanggalSelesai));
    fd.append("NilaiNominal", iData.NilaiNominal);

    swalLoading(true);
    http
      .post(`${URLSVC}/Siswas/AddSiswaBeasiswa`, fd)
      .then((res) => {
        let data = res.data;
        if (data.IsSuccess) {
          Swal.fire({
            title: "Berhasil",
            text: "Anda berhasil menambah beasiswa",
            confirmButtonColor: "#11A887",
            icon: "success",
          });
          dispatch(setDataInfoBeasiswas());
          closeModal();
          resetValue();
        } else {
          swalError(data.ReturnMessage);
        }
      })
      .catch((e) => {
        swalError(e.message);
      });
  };
};

export const apiInfoEditBeasiswa = (iData, closeModal, resetValue) => {
  return (dispatch) => {
    var fd = new FormData();

    fd.append("IdSiswaBeasiswa", iData.IdSiswaBeasiswa);
    fd.append("Tahun", iData.Tahun);
    fd.append("IdKategori", iData.IdKategori?.value);
    fd.append("Nama", iData.Nama);
    fd.append("IdJenisPemberi", iData.IdJenisPemberi?.value);
    fd.append("NamaPemberi", iData.NamaPemberi);
    fd.append("TanggalMulai", FUNCDateToString(iData.TanggalMulai));
    fd.append("TanggalSelesai", FUNCDateToString(iData.TanggalSelesai));
    fd.append("NilaiNominal", iData.NilaiNominal);

    swalLoading(true);
    http
      .post(`${URLSVC}/Siswas/EditSiswaBeasiswa`, fd)
      .then((res) => {
        let data = res.data;
        if (data.IsSuccess) {
          Swal.fire({
            title: "Berhasil",
            text: "Anda berhasil memperbarui beasiswa",
            confirmButtonColor: "#11A887",
            icon: "success",
          });
          dispatch(setDataInfoBeasiswas());
          closeModal();
          resetValue();
        } else {
          swalError(data.ReturnMessage);
        }
      })
      .catch((e) => {
        swalError(e.message);
      });
  };
};

export const apiInfoDeleteBeasiswa = (id, nama) => {
  return (dispatch) => {
    Swal.fire({
      title: "Apakah anda yakin?",
      text: `Anda akan hapus beasiswa (${nama})!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya, saya yakin!",
    }).then((result) => {
      if (result.isConfirmed) {
        swalLoading(true);
        http
          .get(`${URLSVC}/Siswas/DeleteSiswaBeasiswa?IdSiswaBeasiswa=${id}`)
          .then((res) => {
            swalLoading(false);
            let data = res.data;
            if (data.IsSuccess) {
              Swal.fire({
                title: "Berhasil",
                text: `Anda berhasil hapus beasiswa (${nama})`,
                confirmButtonColor: "#11A887",
                icon: "success",
              });
              dispatch(setDataInfoBeasiswas());
            } else {
              Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
            }
          });
      }
    });
  };
};
