import React, { useEffect } from "react";
import { IoCalendarOutline, IoFilterOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { DataTable } from "src/components";
import { FUNCDateToString } from "src/config";
import {
  getDateQiroatyFilterFirstLast,
  setDataQiroatyProgress,
  setFormQiroaty,
  setQiroatyModalFilter,
} from "src/config/redux/action";

const SectionProgress = () => {
  const { modalFilterQiroaty, formQiroaty, dataQiroatyProgress } = useSelector(
    (state) => state.qiroatyReducer
  );
  const dispatch = useDispatch();
  const onModal = () => {
    dispatch(setFormQiroaty("SelectType", "progres"));
    dispatch(setQiroatyModalFilter(modalFilterQiroaty));
  };

  useEffect(() => {
    dispatch(
      getDateQiroatyFilterFirstLast(formQiroaty.TanggalFilterProgres, "progres")
    );
    dispatch(
      setDataQiroatyProgress(FUNCDateToString(formQiroaty.TanggalFilterProgres))
    );
  }, [dispatch]);

  const columns = [
    {
      title: "TANGGAL",
      field: "Tanggal",
    },
    {
      title: "GURU",
      field: "Guru",
    },
    {
      title: "JILID",
      field: "Jilid",

      render: (rowData) => (
        <>
          <span>{rowData.JenisJilidQuran.Nama}</span>
        </>
      ),
    },
    {
      title: "HALAMAN",
      field: "Halaman",
    },
    {
      title: "CATATAN",
      field: "Catatan",
      cellStyle: {
        width: 300,
        minWidth: 300,
      },
      headerStyle: {
        width: 300,
        minWidth: 300,
      },
    },
  ];

  return (
    <>
      <div className="row">
        <div className="col-md-12 mb-3">
          <div className="d-flex justify-content-between align-items-center">
            <div className="qiroaty-jadwal-filter" onClick={() => onModal()}>
              <IoFilterOutline color="#3F3D56" />
              <span className="ms-2">Filter</span>
            </div>
            <div className="qiroaty-jadwal-filter-tanggal">
              <IoCalendarOutline color="#fff" />
              <span>
                Tanggal : {formQiroaty.TanggalAwalProgres} /{" "}
                {formQiroaty.TanggalAkhirProgres}
              </span>
            </div>
          </div>
        </div>
      </div>
      <DataTable
        title="Data Tabel"
        columns={columns}
        data={dataQiroatyProgress}
      />
    </>
  );
};

export default SectionProgress;
