import React from "react";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const SectionForm = () => {
  let navigate = useNavigate();
  return (
    <div className="col-md-5 d-flex align-items-center vh-100">
      <div className="card-global">
        <div className="d-flex align-items-center">
          <h5 className="title-global">Lupa Kata Sandi ?</h5>
        </div>
        <h4>
          Kata Sandi baru akan dikirimkan ke email yang tertaut di akun anda,
          apabila anda salah email silahkan hubungi admin.
        </h4>
        <div className="my-4">
          <form>
            <label className="label-input mb-2">NIS / NISN</label>
            <div className="mb-3">
              <div className="input-group">
                <input
                  type="text"
                  className="input-costum form-control"
                  name="Nisn"
                  placeholder="Masukkan NIS / NISN"
                />
              </div>
            </div>
            <button
              type="submit"
              className="btn btn-primary w-100 d-flex justify-content-center"
            >
              Kirim Kata Sandi Baru
            </button>
          </form>
        </div>
        <div className="d-flex align-items-center justify-content-center pt-3">
          <IoArrowBack color="#FFC50F" className="me-2" />
          <p onClick={() => navigate("/login")}>Kembali ke halaman login</p>
        </div>
      </div>
    </div>
  );
};

export default SectionForm;
