import React from "react";
import SectionForm from "./parts/SectionForm";
import SectionImage from "./parts/SectionImage";
import "./style.css";

const ResetPassword = () => {
  return (
    <>
      <div className="bg-primary section-reset-pass">
        <div className="container">
          <div className="row">
            <SectionForm />
            <SectionImage />
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
