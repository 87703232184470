import React from "react";
import { useNavigate } from "react-router-dom";
import {
  IoEaselOutline,
  IoLockClosedOutline,
  IoNotificationsOutline,
  IoPersonCircleOutline,
} from "react-icons/io5";

const SectionLink = () => {
  let navigate = useNavigate();
  return (
    <>
      <div className="col-6 mb-4">
        <div className="card-link">
          <img
            src="./assets/img/shadow-link.png"
            className="img-fluid"
            width={"100%"}
          />
          <div
            className="layer-link"
            onClick={() => navigate(`/clientarea/inbox`)}
          >
            <div className="link-icon">
              <IoNotificationsOutline color="white" size={30} />
            </div>
            <label>Inbox</label>
          </div>
        </div>
      </div>
      <div className="col-6 mb-4">
        <div className="card-link">
          <img
            src="./assets/img/shadow-link.png"
            className="img-fluid"
            width={"100%"}
          />
          <div
            className="layer-link"
            onClick={() => navigate(`/clientarea/update-profile`)}
          >
            <div className="link-icon">
              <IoPersonCircleOutline color="white" size={30} />
            </div>
            <label>Ubah Profil</label>
          </div>
        </div>
      </div>
      <div className="col-6 mb-4">
        <div className="card-link">
          <img
            src="./assets/img/shadow-link.png"
            className="img-fluid"
            width={"100%"}
          />
          <div
            className="layer-link"
            onClick={() => navigate(`/clientarea/security`)}
          >
            <div className="link-icon">
              <IoLockClosedOutline color="white" size={30} />
            </div>
            <label>Keamanan</label>
          </div>
        </div>
      </div>
      <div className="col-6 mb-4">
        <div className="card-link">
          <img
            src="./assets/img/shadow-link.png"
            className="img-fluid"
            width={"100%"}
          />
          <div
            className="layer-link"
            onClick={() => navigate(`/clientarea/elearning`)}
          >
            <div className="link-icon">
              <IoEaselOutline color="white" size={30} />
            </div>
            <label>Belajar</label>
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionLink;
