import React from "react";
import { useForm } from "react-hook-form";
import ReactLoading from "react-loading";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { apiChangePassword } from "src/config/redux/action";

const SectionForm = () => {
  let navigate = useNavigate();
  const { formChangePassword } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const btnChangePassword = (data) => dispatch(apiChangePassword(data));
  return (
    <div className="col-md-6">
      <div className="card-global">
        <div className="d-flex align-items-center">
          <div className="icon-global"></div>
          <h5 className="title-global">Form</h5>
        </div>
        <div className="mt-3">
          <form onSubmit={handleSubmit(btnChangePassword)}>
            <div className="mb-3">
              <label className="label-input mb-2">Password Lama</label>
              <div className="input-group">
                <input
                  type="text"
                  className="input-costum form-control"
                  placeholder="Masukkan Password Lama"
                  {...register("OldPassword", { required: true })}
                />
              </div>
              {errors.OldPassword && (
                <span className="alert-input">
                  Password lama tidak boleh kosong
                </span>
              )}
            </div>
            <div className="mb-3">
              <label className="label-input mb-2">Password Baru</label>
              <div className="input-group">
                <input
                  type="text"
                  className="input-costum form-control"
                  placeholder="Masukkan Password Baru"
                  {...register("NewPassword1", { required: true })}
                />
              </div>
              {errors.NewPassword1 && (
                <span className="alert-input">
                  Password baru tidak boleh kosong
                </span>
              )}
            </div>
            <div className="mb-3">
              <label className="label-input mb-2">
                Konfirmasi Password Baru
              </label>
              <div className="input-group">
                <input
                  type="text"
                  className="input-costum form-control"
                  placeholder="Masukkan Konfirmasi Password Baru"
                  {...register("NewPassword2", { required: true })}
                />
              </div>
              {errors.NewPassword2 && (
                <span className="alert-input">
                  Konfirmasi password baru tidak boleh kosong
                </span>
              )}
            </div>
            <div className="mt-4">
              {formChangePassword.isLoading ? (
                <button
                  type="submit"
                  className="btn btn-primary w-100 d-flex justify-content-center"
                  disabled
                >
                  <ReactLoading
                    type={"spin"}
                    color={"#fff"}
                    width={15}
                    height={15}
                    className="me-3"
                  />
                  Memuat
                </button>
              ) : (
                <button type="submit" className="btn btn-primary w-100">
                  Simpan Pembaruan
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SectionForm;
