const initialState = {
  dataHome: null,
};

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DATA_HOME":
      return { ...state, dataHome: action.payload };
    default:
      return state;
  }
};

export default homeReducer;
