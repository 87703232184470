const initialState = {
  dataComboAgama: [],
  dataComboBahasa: [],
  dataComboKewarganegaraan: [],
  dataComboHobi: [],
  dataComboCita: [],
  dataComboBantuanOrtu: [],
  dataComboGolonganDarah: [],
  dataComboJenisKelamin: [],
  dataComboStatusNikah: [],
  dataComboTransportasi: [],
  dataComboJenisPekerjaan: [],
  dataComboJenjangPendidikan: [],
  dataComboKelasParalel: [],
  dataComboProvinsi: [],
  dataComboKabkot: [],
  dataComboKecamatan: [],
  dataComboDesa: [],
  dataComboJenisBiayaSiswa: [],
  dataComboKebutuhanKhusus: [],
  dataComboStatusTempatTinggal: [],
  dataComboAlamatTunjuk: [],
  dataComboStatusOrtu: [],
  dataComboOrtuJenisTempatTinggal: [],
  dataComboBeasiswaKategori: [],
  dataComboBeasiswaJenisPemberi: [],
  dataComboPrestasiJenis: [],
  dataComboPrestasiTingkat: [],
  dataComboPrestasiPeringkat: [],
  dataComboPrestasiKategori: [],
  dataComboPenyakit: [],
  dataComboKelainan: [],
};

const comboReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DATA_COMBO_AGAMA":
      return { ...state, dataComboAgama: action.payload };
    case "DATA_COMBO_BAHASA":
      return { ...state, dataComboBahasa: action.payload };
    case "DATA_COMBO_KEWARGANEGARAAN":
      return { ...state, dataComboKewarganegaraan: action.payload };
    case "DATA_COMBO_HOBI":
      return { ...state, dataComboHobi: action.payload };
    case "DATA_COMBO_CITA":
      return { ...state, dataComboCita: action.payload };
    case "DATA_COMBO_BANTUAN_ORTU":
      return { ...state, dataComboBantuanOrtu: action.payload };
    case "DATA_COMBO_GOLONGAN_DARAH":
      return { ...state, dataComboGolonganDarah: action.payload };
    case "DATA_COMBO_JENIS_KELAMIN":
      return { ...state, dataComboJenisKelamin: action.payload };
    case "DATA_COMBO_STATUS_NIKAH":
      return { ...state, dataComboStatusNikah: action.payload };
    case "DATA_COMBO_TRANSPORTASI":
      return { ...state, dataComboTransportasi: action.payload };
    case "DATA_COMBO_JENIS_PEKERJAAN":
      return { ...state, dataComboJenisPekerjaan: action.payload };
    case "DATA_COMBO_JENJANG_PENDIDIKAN":
      return { ...state, dataComboJenjangPendidikan: action.payload };
    case "DATA_COMBO_KELAS_PARALEL":
      return { ...state, dataComboKelasParalel: action.payload };
    case "DATA_COMBO_PROVINSI":
      return { ...state, dataComboProvinsi: action.payload };
    case "DATA_COMBO_KABKOT":
      return { ...state, dataComboKabkot: action.payload };
    case "DATA_COMBO_KECAMATAN":
      return { ...state, dataComboKecamatan: action.payload };
    case "DATA_COMBO_DESA":
      return { ...state, dataComboDesa: action.payload };
    case "DATA_COMBO_JENIS_BIAYA_SISWA":
      return { ...state, dataComboJenisBiayaSiswa: action.payload };
    case "DATA_COMBO_KEBUTUHAN_KHUSUS":
      return { ...state, dataComboKebutuhanKhusus: action.payload };
    case "DATA_COMBO_STATUS_TEMPAT_TINGGAL":
      return { ...state, dataComboStatusTempatTinggal: action.payload };
    case "DATA_COMBO_ALAMAT_TUNJUK":
      return { ...state, dataComboAlamatTunjuk: action.payload };
    case "DATA_COMBO_STATUS_ORTU":
      return { ...state, dataComboStatusOrtu: action.payload };
    case "DATA_COMBO_ORTU_JENIS_TEMPAT_TINGGAL":
      return { ...state, dataComboOrtuJenisTempatTinggal: action.payload };
    case "DATA_COMBO_BEASISWA_KATEGORI":
      return { ...state, dataComboBeasiswaKategori: action.payload };
    case "DATA_COMBO_BEASISWA_JENIS_PEMBERI":
      return { ...state, dataComboBeasiswaJenisPemberi: action.payload };
    case "DATA_COMBO_PRESTASI_JENIS":
      return { ...state, dataComboPrestasiJenis: action.payload };
    case "DATA_COMBO_PRESTASI_TINGKAT":
      return { ...state, dataComboPrestasiTingkat: action.payload };
    case "DATA_COMBO_PRESTASI_PERINGKAT":
      return { ...state, dataComboPrestasiPeringkat: action.payload };
    case "DATA_COMBO_PRESTASI_KATEGORI":
      return { ...state, dataComboPrestasiKategori: action.payload };
    case "DATA_COMBO_PENYAKIT":
      return { ...state, dataComboPenyakit: action.payload };
    case "DATA_COMBO_KELAINAN":
      return { ...state, dataComboKelainan: action.payload };
    default:
      return state;
  }
};

export default comboReducer;
