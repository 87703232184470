import React, { useState } from "react";
import {
  IoArrowForward,
  IoAlbums,
  IoAccessibility,
  IoDocumentText,
  IoAnalyticsSharp,
  IoPerson,
  IoFolderSharp,
} from "react-icons/io5";
import { Header } from "src/components";
import "./style.css";
// import SectionProfile from "./parts-backup/SectionProfile";
// import SectionParent from "./parts-backup/SectionParent";
// import SectionHealth from "./parts-backup/SectionHealth";
// import SectionFamily from "./parts-backup/SectionFamily";
// import SectionSchool from "./parts-backup/SectionSchool";
// import SectionKip from "./parts-backup/SectionKip";
import SectionStudent from "./parts/SectionStudent";
import SectionParent from "./parts/SectionParent";
import SectionBeasiswa from "./parts/SectionBeasiswa";
import SectionPrestasi from "./parts/SectionPrestasi";

const jsonTabs = [
  {
    tab: "student",
    title: "Data Siswa",
    icon: <IoPerson color="#11A887" />,
  },
  {
    tab: "parent",
    title: "Orang Tua & Wali",
    icon: <IoAccessibility color="#11A887" />,
  },
  // {
  //   tab: "activity",
  //   title: "Aktivitas Belajar",
  //   icon: <IoAnalyticsSharp color="#11A887" />,
  // },
  {
    tab: "beasiswa",
    title: "Beasiswa & Bantuan",
    icon: <IoAlbums color="#11A887" />,
  },
  {
    tab: "achievement",
    title: "Prestasi Siswa",
    icon: <IoDocumentText color="#11A887" />,
  },
  // {
  //   tab: "academy",
  //   title: "Pendidikan Lain",
  //   icon: <IoFolderSharp color="#11A887" />,
  // },
];

const UpdateProfile = (props) => {
  const [tab, setTab] = useState("student");
  return (
    <>
      <Header name={props.name} />
      <div className="row">
        <div className="col-md-4">
          <div className="list-profile">
            {jsonTabs.map((v, i) => {
              return (
                <div
                  key={i}
                  className={`tab-profile ${tab === v.tab ? "active" : ""}`}
                  onClick={() => setTab(v.tab)}
                >
                  <div className="d-flex align-items-center">
                    {v.icon}
                    <span className="ms-3"> {v.title} </span>
                  </div>
                  <div>
                    <IoArrowForward color="#3F3D56" />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-md-8">
          {/* {tab === "profile" && <SectionProfile />}
          {tab === "parent" && <SectionParent />}
          {tab === "family" && <SectionFamily />}
          {tab === "health" && <SectionHealth />}
          {tab === "school" && <SectionSchool />}
          {tab === "kip" && <SectionKip />} */}
          {tab === "student" && <SectionStudent />}
          {tab === "parent" && <SectionParent />}
          {tab === "beasiswa" && <SectionBeasiswa />}
          {tab === "achievement" && <SectionPrestasi />}
        </div>
      </div>
    </>
  );
};

export default UpdateProfile;
