import Swal from "sweetalert2";

export const swalError = (msg) => {
  Swal.fire({
    title: "Gagal",
    html: `${msg}`,
    confirmButtonColor: "#f54503",
    icon: "error",
  });
};

export const swalLoading = (bool = true) => {
  if (bool) {
    Swal.fire({
      title: "Sedang Memuat",
      html: "silahkan tunggu sebentar...",
      showConfirmButton: false,
      onBeforeOpen() {
        Swal.showLoading();
      },
      onAfterClose() {
        Swal.hideLoading();
      },
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    });
  } else {
    Swal.close();
  }
};
