import React from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";

const DatePicker = ({ ...props }) => {
  return <ReactDatePicker className="input-costum form-control" {...props} />;
};

export default DatePicker;
