import {
  Home,
  Elearning,
  Study,
  Mutabaah,
  Qiroaty,
  Inbox,
  UpdateProfile,
  Security,
  Calendar,
} from "src/pages/client";

const route_client = [
  {
    path: "",
    exact: true,
    name: "Halaman Utama",
    Component: Home,
  },
  {
    path: "elearning",
    exact: true,
    name: "Ruang Belajar",
    Component: Elearning,
  },
  {
    path: "elearning/play/:id",
    exact: true,
    name: "Mulai Belajar",
    Component: Study,
  },
  {
    path: "mutabaah",
    exact: true,
    name: "Mutabaah",
    Component: Mutabaah,
  },
  {
    path: "qiroaty",
    exact: true,
    name: "Qiroaty",
    Component: Qiroaty,
  },
  {
    path: "inbox",
    exact: true,
    name: "Notifikasi",
    Component: Inbox,
  },
  {
    path: "update-profile",
    exact: true,
    name: "Ubah Profil",
    Component: UpdateProfile,
  },
  {
    path: "security",
    exact: true,
    name: "Ubah Password",
    Component: Security,
  },
  {
    path: "calendar",
    exact: true,
    name: "Kalender Pendidikan",
    Component: Calendar,
  },
];

export default route_client;
