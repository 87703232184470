import React, { Suspense, useEffect, useState } from "react";
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import route_guest from "src/routes/route_guest";
import { NotFound } from "src/pages/guest";
import "./style.css";
import {
  IoEaselOutline,
  IoHomeOutline,
  IoListCircleOutline,
  IoLockClosed,
  IoLogOutOutline,
  IoNewspaperOutline,
  IoPersonCircleOutline,
  IoReaderOutline,
  IoSettingsOutline,
} from "react-icons/io5";
import { Logo } from "src/assets";
import { PopoverBody, UncontrolledPopover } from "reactstrap";

const loading = (
  <div className="mt-4 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const LayoutGuest = () => {
  let navigate = useNavigate();

  const isInitiallyVisible = false;
  const [isKeyboardVisible, setKeyboardVisible] = useState(isInitiallyVisible);

  let fullWindowHeight = window.innerHeight;

  window.addEventListener("resize", function () {
    if (window.innerHeight == fullWindowHeight) {
      setKeyboardVisible(false);
    } else if (window.innerHeight < fullWindowHeight * 0.9) {
      setKeyboardVisible(true);
    }
  });
  // useEffect(() => {
  //   handle.enter;
  // }, []);

  const { pathname } = useLocation();

  return (
    <>
      <div>
        <Suspense fallback={loading}>
          <Routes>
            {route_guest.map(({ path, name, Component, exact }, key) => {
              return (
                <Route
                  key={key}
                  exact={exact}
                  path={path}
                  name={name}
                  element={<Component name={name} />}
                />
              );
            })}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </div>
    </>
  );
};

export default LayoutGuest;
