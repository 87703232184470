import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ReactInputMask from "react-input-mask";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker, ReactSelect } from "src/components";
import {
  FUNCDateDmytoYmd,
  FUNCValidateUploadFileExtension,
  FUNCValidateUploadFileSize,
} from "src/config";
import {
  apiInfoUpdateProfil,
  setDataComboAgama,
  setDataInfoProfil,
  setDataComboBahasa,
  setDataComboCita,
  setDataComboHobi,
  setDataComboKewarganegaraan,
  setDataComboTransportasi,
  setDataComboProvinsi,
  setDataComboKabkot,
  setDataComboKecamatan,
  setDataComboDesa,
  setDataComboJenisBiayaSiswa,
  setDataComboKebutuhanKhusus,
  setDataComboGolonganDarah,
  setDataComboStatusTempatTinggal,
  setDataComboAlamatTunjuk,
  setDataAlamatOrtuWali,
  setDataComboJenisPenyakit,
  setDataComboJenisKelainan,
} from "src/config/redux/action";

const SectionStudent = () => {
  const {
    dataComboAgama,
    dataComboBahasa,
    dataComboHobi,
    dataComboCita,
    dataComboTransportasi,
    dataComboProvinsi,
    dataComboKabkot,
    dataComboKecamatan,
    dataComboDesa,
    dataComboJenisBiayaSiswa,
    dataComboKebutuhanKhusus,
    dataComboGolonganDarah,
    dataComboStatusTempatTinggal,
    dataComboAlamatTunjuk,
    dataComboPenyakit,
    dataComboKelainan,
  } = useSelector((state) => state.comboReducer);
  const { dataInfoProfil, dataAlamatOrtuWali } = useSelector(
    (state) => state.userReducer
  );
  const dispatch = useDispatch();
  const {
    control,
    getValues,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const [isKip, setIsKip] = useState(false);
  const [isWna, setIsWna] = useState(false);

  useEffect(() => {
    dispatch(setDataComboAgama("DATA_COMBO_AGAMA"));
    dispatch(setDataComboHobi("DATA_COMBO_HOBI"));
    dispatch(setDataComboCita("DATA_COMBO_CITA"));
    dispatch(setDataComboBahasa("DATA_COMBO_BAHASA"));
    dispatch(setDataComboKewarganegaraan("DATA_COMBO_KEWARGANEGARAAN"));
    dispatch(setDataComboTransportasi("DATA_COMBO_TRANSPORTASI"));
    dispatch(setDataComboProvinsi("DATA_COMBO_PROVINSI"));
    dispatch(setDataComboJenisBiayaSiswa("DATA_COMBO_JENIS_BIAYA_SISWA"));
    dispatch(setDataComboKebutuhanKhusus("DATA_COMBO_KEBUTUHAN_KHUSUS"));
    dispatch(setDataComboGolonganDarah("DATA_COMBO_GOLONGAN_DARAH"));
    dispatch(
      setDataComboStatusTempatTinggal("DATA_COMBO_STATUS_TEMPAT_TINGGAL")
    );
    dispatch(setDataComboAlamatTunjuk("DATA_COMBO_ALAMAT_TUNJUK"));
    dispatch(setDataComboJenisPenyakit("DATA_COMBO_PENYAKIT"));
    dispatch(setDataComboJenisKelainan("DATA_COMBO_KELAINAN"));
    dispatch(setDataInfoProfil());
  }, [dispatch]);

  useEffect(() => {
    setValue("Nama", dataInfoProfil?.Nama);
    setValue("NamaPanggilan", dataInfoProfil?.NamaPanggilan);
    setValue("Nisn", dataInfoProfil?.Nisn);
    setValue("Nik", dataInfoProfil?.Nik);
    setValue("Nis", dataInfoProfil?.Nis);
    setValue(
      "IdKewarganegaraan",
      `${dataInfoProfil?.Detail?.Kewarganegaraan?.Id || 1}`
    );
    if (dataInfoProfil?.Detail?.Kewarganegaraan?.Id === 2) setIsWna(true);
    setValue("AsalNegara", dataInfoProfil?.Detail?.AsalNegara);
    setValue("NoKitas", dataInfoProfil?.Detail?.NoKitas);
    setValue("TempatLahir", dataInfoProfil?.TempatLahir);
    var TanggalLahir = new Date("1900-01-01");
    if (dataInfoProfil?.TanggalLahir)
      TanggalLahir = new Date(FUNCDateDmytoYmd(dataInfoProfil?.TanggalLahir));
    setValue("TanggalLahir", TanggalLahir);
    console.log(dataInfoProfil?.JenisKelamin);
    setValue("JenisKelamin", dataInfoProfil?.JenisKelamin?.Kode || "L");
    setValue("AnakKe", dataInfoProfil?.Detail?.AnakKe);
    setValue(
      "JumlahSodaraKandung",
      dataInfoProfil?.Detail?.JumlahSodaraKandung
    );
    setValue("JumlahSodaraTiri", dataInfoProfil?.Detail?.JumlahSodaraTiri);
    setValue("JumlahSodaraAngkat", dataInfoProfil?.Detail?.JumlahSodaraAngkat);
    setValue(
      "IdGolonganDarah",
      dataInfoProfil?.Detail?.GolonganDarah && {
        value: dataInfoProfil?.Detail?.GolonganDarah?.Kode,
        label: dataInfoProfil?.Detail?.GolonganDarah?.Nama,
      }
    );
    setValue(
      "IdAgama",
      dataInfoProfil?.Agama && {
        value: dataInfoProfil?.Agama?.Id,
        label: dataInfoProfil?.Agama?.Nama,
      }
    );
    setValue(
      "IdHobi",
      dataInfoProfil?.Detail?.Hobi?.Nama && {
        value: dataInfoProfil?.Detail?.Hobi?.Id,
        label: dataInfoProfil?.Detail?.Hobi?.Nama,
      }
    );
    setValue(
      "IdCita2",
      dataInfoProfil?.Detail?.Cita2?.Nama && {
        value: dataInfoProfil?.Detail?.Cita2?.Id,
        label: dataInfoProfil?.Detail?.Cita2?.Nama,
      }
    );
    setValue("NoHandphone", dataInfoProfil?.NoHandphone);
    setValue("NoDarurat", dataInfoProfil?.NoDarurat);
    setValue("Email", dataInfoProfil?.Email);
    //foto profile
    setValue(
      "IdBahasa",
      dataInfoProfil?.Detail?.Bahasa?.Nama && {
        value: dataInfoProfil?.Detail?.Bahasa?.Id,
        label: dataInfoProfil?.Detail?.Bahasa?.Nama,
      }
    );
    setValue("BeratBadan", dataInfoProfil?.Detail?.BeratBadan);
    setValue("TinggiBadan", dataInfoProfil?.Detail?.TinggiBadan);
    setValue("LingkarKepala", dataInfoProfil?.Detail?.LingkarKepala);
    setValue("PunyaWali", `${dataInfoProfil?.Detail?.PunyaWali}`);

    setValue(
      "IdStatusTempatTinggal",
      dataInfoProfil?.Detail?.StatusTempatTinggal?.Nama && {
        value: dataInfoProfil?.Detail?.StatusTempatTinggal?.Id,
        label: dataInfoProfil?.Detail?.StatusTempatTinggal?.Nama,
      }
    );
    if (
      dataInfoProfil?.Detail?.StatusTempatTinggal?.Id === 1 ||
      dataInfoProfil?.Detail?.StatusTempatTinggal?.Id === 2
    ) {
      dispatch({ type: "DATA_ALAMAT_ORTU_WALI", payload: true });
    }
    setValue(
      "IdProvinsi",
      dataInfoProfil?.Detail?.AlamatTinggal?.Provinsi?.Nama && {
        value: dataInfoProfil?.Detail?.AlamatTinggal?.Provinsi?.Id,
        label: dataInfoProfil?.Detail?.AlamatTinggal?.Provinsi?.Nama,
      }
    );
    setValue(
      "IdKabkot",
      dataInfoProfil?.Detail?.AlamatTinggal?.Kabkot?.Nama && {
        value: dataInfoProfil?.Detail?.AlamatTinggal?.Kabkot?.Id,
        label: dataInfoProfil?.Detail?.AlamatTinggal?.Kabkot?.Nama,
      }
    );
    setValue(
      "IdKecamatan",
      dataInfoProfil?.Detail?.AlamatTinggal?.Kecamatan?.Nama && {
        value: dataInfoProfil?.Detail?.AlamatTinggal?.Kecamatan?.Id,
        label: dataInfoProfil?.Detail?.AlamatTinggal?.Kecamatan?.Nama,
      }
    );
    setValue(
      "IdDesa",
      dataInfoProfil?.Detail?.AlamatTinggal?.Desa?.Nama && {
        value: dataInfoProfil?.Detail?.AlamatTinggal?.Desa?.Id,
        label: dataInfoProfil?.Detail?.AlamatTinggal?.Desa?.Nama,
      }
    );
    setValue(
      "KdAlamatTunjuk",
      dataInfoProfil?.Detail?.AlamatTinggal?.AlamatTunjuk?.Nama && {
        value: dataInfoProfil?.Detail?.AlamatTinggal?.AlamatTunjuk?.Kode,
        label: dataInfoProfil?.Detail?.AlamatTinggal?.AlamatTunjuk?.Nama,
      }
    );
    setValue("Alamat", dataInfoProfil?.Detail?.AlamatTinggal?.Alamat);
    setValue("Longitude", dataInfoProfil?.Detail?.AlamatTinggal?.Longitude);
    setValue("Latitude", dataInfoProfil?.Detail?.AlamatTinggal?.Latitude);
    setValue("KdPos", dataInfoProfil?.Detail?.AlamatTinggal?.KdPos);
    setValue("Rt", dataInfoProfil?.Detail?.AlamatTinggal?.Rt);
    setValue("Rw", dataInfoProfil?.Detail?.AlamatTinggal?.Rw);
    setValue("Dusun", dataInfoProfil?.Detail?.AlamatTinggal?.Dusun);
    setValue(
      "IdJenisTransportasi",
      dataInfoProfil?.Detail?.JenisTransportasi?.Nama && {
        value: dataInfoProfil?.Detail?.JenisTransportasi?.Id,
        label: dataInfoProfil?.Detail?.JenisTransportasi?.Nama,
      }
    );
    setValue("JarakSekolah", dataInfoProfil?.Detail?.JarakSekolah);
    setValue("WaktuTempuh", dataInfoProfil?.Detail?.WaktuTempuh);
    setValue(
      "IdJenisBiayaSekolah",
      dataInfoProfil?.Detail?.JenisBiayaSekolah?.Nama && {
        value: dataInfoProfil?.Detail?.JenisBiayaSekolah?.Id,
        label: dataInfoProfil?.Detail?.JenisBiayaSekolah?.Nama,
      }
    );
    setValue(
      "IdKebutuhanKhusus",
      dataInfoProfil?.Detail?.KebutuhanKhusus?.Nama && {
        value: dataInfoProfil?.Detail?.KebutuhanKhusus?.Id,
        label: dataInfoProfil?.Detail?.KebutuhanKhusus?.Nama,
      }
    );
    setValue(
      "IdPenyakitDiderita",
      dataInfoProfil?.Detail?.PenyakitDiderita?.Nama && {
        value: dataInfoProfil?.Detail?.PenyakitDiderita?.Id,
        label: dataInfoProfil?.Detail?.PenyakitDiderita?.Nama,
      }
    );
    setValue(
      "IdKelainanJasmani",
      dataInfoProfil?.Detail?.KelainanJasmani?.Nama && {
        value: dataInfoProfil?.Detail?.KelainanJasmani?.Id,
        label: dataInfoProfil?.Detail?.KelainanJasmani?.Nama,
      }
    );
    setValue(
      "KebutuhanDisabilitas",
      `${dataInfoProfil?.Detail?.KebutuhanDisabilitas}`
    );
    setValue("PernahTkFormal", dataInfoProfil?.Detail?.PernahTkFormal);
    setValue("PernahTkInformal", dataInfoProfil?.Detail?.PernahTkInformal);
    setValue("ImunisasiHepatitis", dataInfoProfil?.Detail?.ImunisasiHepatitis);
    setValue("ImunisasiBcg", dataInfoProfil?.Detail?.ImunisasiBcg);
    setValue("ImunisasiDpt", dataInfoProfil?.Detail?.ImunisasiDpt);
    setValue("ImunisasiPolio", dataInfoProfil?.Detail?.ImunisasiPolio);
    setValue("ImunisasiCampak", dataInfoProfil?.Detail?.ImunisasiCampak);
    setValue("ImunisasiCovid", dataInfoProfil?.Detail?.ImunisasiCovid);
    // setValue("PenyakitDiderita", dataInfoProfil?.Detail?.PenyakitDiderita);
    // setValue("KelainanJasmani", dataInfoProfil?.Detail?.KelainanJasmani);
    setValue("SekolahAsal", dataInfoProfil?.Detail?.SekolahAsal);
    setValue("AlamatSekolahAsal", dataInfoProfil?.Detail?.AlamatSekolahAsal);
    setValue("NspnSekolahAsal", dataInfoProfil?.Detail?.NspnSekolahAsal);
    setValue("NoKip", dataInfoProfil?.Detail?.NoKip);
    if (dataInfoProfil?.Detail?.NoKip) {
      setValue("TerimaKip", "true");
      setIsKip(true);
    } else {
      setValue("TerimaKip", "false");
    }
    setValue("NoKk", dataInfoProfil?.Detail?.NoKk);
    setValue("NoAkta", dataInfoProfil?.Detail?.NoAkta);
    setValue("NoKtpAyah", dataInfoProfil?.Detail?.NoKtpAyah);
    setValue("NoKtpIbu", dataInfoProfil?.Detail?.NoKtpIbu);
    setValue("NoKks", dataInfoProfil?.Detail?.NoKks);
    setValue("NoPkh", dataInfoProfil?.Detail?.NoPkh);
  }, [dataInfoProfil]);

  const onFile = (e, type) => {
    const file = e.target.files[0];
    FUNCValidateUploadFileSize(e.target, 2048, "2MB");
    FUNCValidateUploadFileExtension(e.target, [".jpg", ".jpeg", ".png"]);
    setValue(type, file);
  };

  const onWilayah = (id, type) => {
    if (type === "Provinsi") {
      setValue("IdKabkot", "");
      setValue("IdKecamatan", "");
      setValue("IdDesa", "");
      dispatch(setDataComboKabkot("DATA_COMBO_KABKOT", id));
      return;
    }
    if (type === "Kabkot") {
      setValue("IdKecamatan", "");
      setValue("IdDesa", "");
      dispatch(setDataComboKecamatan("DATA_COMBO_KECAMATAN", id));
      return;
    }
    if (type === "Kecamatan") {
      setValue("IdDesa", "");
      dispatch(setDataComboDesa("DATA_COMBO_DESA", id));
      return;
    }
  };

  const onAlamat = (val) => {
    if (val === 1 || val === 2) {
      dispatch(setDataAlamatOrtuWali(val, setValue));
      dispatch({ type: "DATA_ALAMAT_ORTU_WALI", payload: true });
    } else {
      dispatch({ type: "DATA_ALAMAT_ORTU_WALI", payload: false });
    }
  };

  const btnSave = (data) => dispatch(apiInfoUpdateProfil(data));

  return (
    <div className="card-global">
      <div className="d-flex align-items-center">
        <div className="icon-global"></div>
        <h5 className="title-global">Data Siswa</h5>
      </div>
      <div className="card-data-siswa mt-3">
        <div className="content-data-siswa">
          <form onSubmit={handleSubmit(btnSave)}>
            <div className="row">
              <div className="col-md-7">
                <div className="mb-3">
                  <label className="label-input mb-2">Nama Lengkap</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="input-costum form-control"
                      placeholder="Masukkan Nama Lengkap"
                      {...register("Nama", { required: false })}
                      disabled
                    />
                  </div>
                  {errors.Nama && (
                    <span className="alert-input">
                      Nama Lengkap tidak boleh kosong
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-5">
                <div className="mb-3">
                  <label className="label-input mb-2">Nama Panggilan</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="input-costum form-control"
                      placeholder="Masukkan Nama Panggilan"
                      {...register("NamaPanggilan", { required: false })}
                    />
                  </div>
                  {errors.NamaPanggilan && (
                    <span className="alert-input">
                      Nama Panggilan tidak boleh kosong
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="label-input mb-2">NIS Lokal</label>
                  <div className="input-group">
                    <input
                      type="number"
                      className="input-costum form-control"
                      placeholder="Masukkan NIS"
                      {...register("Nis", { required: false })}
                      disabled
                    />
                  </div>
                  {errors.Nis && (
                    <span className="alert-input">NIS tidak boleh kosong</span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="label-input mb-2">
                    NISN{" "}
                    <small>
                      *Cek NISN Klik{" "}
                      <a href="https://s.id/checknisn" targer="_blank">
                        disini
                      </a>
                    </small>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="input-costum form-control"
                      placeholder="Masukkan NISN"
                      {...register("Nisn", { required: false })}
                    />
                  </div>
                  {errors.Nisn && (
                    <span className="alert-input">NISN tidak boleh kosong</span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="label-input mb-2">NIK Siswa</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="input-costum form-control"
                      placeholder="Masukkan NIK Siswa"
                      {...register("Nik", { required: false })}
                    />
                  </div>
                  {errors.Nik && (
                    <span className="alert-input">
                      NIK Siswa tidak boleh kosong
                    </span>
                  )}
                </div>
              </div>

              <div className="col-md-12">
                <div className="mb-3">
                  <label className="label-input mb-2">Warga Negara</label>
                  <div className="wrapper-radiobtn">
                    <div className="form-check me-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="IdKewarganegaraan"
                        id="wnI"
                        value={1}
                        defaultChecked={getValues("IdKewarganegaraan") === "1"}
                        {...register("IdKewarganegaraan")}
                        onChange={() => setIsWna(false)}
                      />
                      <label className="form-check-label" htmlFor="wnI">
                        WNI
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="IdKewarganegaraan"
                        id="wnA"
                        value={2}
                        defaultChecked={getValues("IdKewarganegaraan") === "2"}
                        {...register("IdKewarganegaraan")}
                        onChange={() => setIsWna(true)}
                      />
                      <label className="form-check-label" htmlFor="wnA">
                        WNA
                      </label>
                    </div>
                  </div>
                  {errors.IdKewarganegaraan && (
                    <span className="alert-input">
                      Warga Negara tidak boleh kosong
                    </span>
                  )}
                </div>
              </div>
              {isWna && (
                <>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="label-input mb-2">Asal Negara</label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="input-costum form-control"
                          placeholder="Masukkan Asal Negara"
                          {...register("AsalNegara", { required: isWna })}
                        />
                      </div>
                      {errors.AsalNegara && (
                        <span className="alert-input">
                          Asal Negara tidak boleh kosong
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="label-input mb-2">
                        No. Izin Tinggal (KITAS)
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="input-costum form-control"
                          placeholder="Masukkan No. Izin Tinggal (KITAS)"
                          {...register("NoKitas", { required: isWna })}
                        />
                      </div>
                      {errors.NoKitas && (
                        <span className="alert-input">
                          No. Izin Tinggal (KITAS) tidak boleh kosong
                        </span>
                      )}
                    </div>
                  </div>
                </>
              )}

              <div className="col-md-6">
                <div className="mb-3">
                  <label className="label-input mb-2">Tempat Lahir</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="input-costum form-control"
                      placeholder="Masukkan Tempat Lahir"
                      {...register("TempatLahir", { required: false })}
                    />
                  </div>
                  {errors.TempatLahir && (
                    <span className="alert-input">
                      Tempat Lahir tidak boleh kosong
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="label-input mb-2">Tanggal Lahir </label>
                  <div className="input-group">
                    <Controller
                      control={control}
                      name="TanggalLahir"
                      render={({ field, value }) => (
                        <DatePicker
                          placeholderText="Masukkan Tanggal Lahir"
                          selected={field.value}
                          value={value}
                          onChange={(val) => field.onChange(val)}
                          dateFormat="dd-MM-yyyy"
                          maxDate={new Date()}
                          customInput={
                            <ReactInputMask
                              mask="99-99-9999"
                              onChange={field.onChange}
                              value={field.value}
                              maskChar=""
                              disabled
                            />
                          }
                          disabled
                        />
                      )}
                      rules={{
                        required: false,
                      }}
                    />
                    {errors.TanggalLahir && (
                      <span className="alert-input">Kolom wajib diisi</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="label-input mb-2">Jenis Kelamin</label>
                  <div className="wrapper-radiobtn">
                    <div className="form-check me-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="JenisKelamin"
                        id="jkLaki"
                        value="L"
                        defaultChecked={getValues("JenisKelamin") === "L"}
                        {...register("JenisKelamin")}
                        disabled
                      />
                      <label className="form-check-label" htmlFor="jkLaki">
                        Laki - laki
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="JenisKelamin"
                        id="jkPerempuan"
                        value="P"
                        defaultChecked={getValues("JenisKelamin") === "P"}
                        {...register("JenisKelamin")}
                        disabled
                      />
                      <label className="form-check-label" htmlFor="jkPerempuan">
                        Perempuan
                      </label>
                    </div>
                  </div>
                  {errors.KdJenisKelamin && (
                    <span className="alert-input">
                      Tempat Lahir tidak boleh kosong
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="label-input mb-2">Anak Ke</label>
                  <div className="input-group">
                    <input
                      type="number"
                      className="input-costum form-control"
                      placeholder="Masukkan Anak Ke"
                      {...register("AnakKe", { required: false })}
                    />
                  </div>
                  {errors.AnakKe && (
                    <span className="alert-input">
                      Anak Ke tidak boleh kosong
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="label-input mb-2">
                    Jumlah Sodara Kandung
                  </label>
                  <div className="input-group">
                    <input
                      type="number"
                      className="input-costum form-control"
                      placeholder="Masukkan Jumlah Sodara Kandung"
                      {...register("JumlahSodaraKandung", { required: false })}
                    />
                  </div>
                  {errors.JumlahSodaraKandung && (
                    <span className="alert-input">
                      Jumlah Sodara Kandung tidak boleh kosong
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="label-input mb-2">Jumlah Sodara Tiri</label>
                  <div className="input-group">
                    <input
                      type="number"
                      className="input-costum form-control"
                      placeholder="Masukkan Jumlah Sodara Tiri"
                      {...register("JumlahSodaraTiri", { required: false })}
                    />
                  </div>
                  {errors.JumlahSodaraTiri && (
                    <span className="alert-input">
                      Jumlah Sodara Tiri tidak boleh kosong
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="label-input mb-2">
                    Jumlah Sodara Angkat
                  </label>
                  <div className="input-group">
                    <input
                      type="number"
                      className="input-costum form-control"
                      placeholder="Masukkan Jumlah Sodara Angkat"
                      {...register("JumlahSodaraAngkat", { required: false })}
                    />
                  </div>
                  {errors.JumlahSodaraAngkat && (
                    <span className="alert-input">
                      Jumlah Sodara Angkat tidak boleh kosong
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="label-input mb-2">Golongan Darah</label>
                  <Controller
                    control={control}
                    defaultValue={getValues("IdGolonganDarah") || ""}
                    name="IdGolonganDarah"
                    render={({ field, ref }) => (
                      <ReactSelect
                        inputRef={ref}
                        classNamePrefix="addl-class"
                        options={dataComboGolonganDarah}
                        value={getValues("IdGolonganDarah")}
                        onChange={(val) => {
                          field.onChange(val);
                        }}
                        isClearable={true}
                      />
                    )}
                    rules={{
                      required: false,
                    }}
                  />
                  {errors.IdGolonganDarah && (
                    <span className="text-danger">Kolom wajib diisi</span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="label-input mb-2">Agama</label>
                  <Controller
                    control={control}
                    defaultValue={getValues("IdAgama") || ""}
                    name="IdAgama"
                    render={({ field, ref }) => (
                      <ReactSelect
                        inputRef={ref}
                        classNamePrefix="addl-class"
                        options={dataComboAgama}
                        value={getValues("IdAgama")}
                        onChange={(val) => {
                          field.onChange(val);
                        }}
                        isClearable={true}
                      />
                    )}
                    rules={{
                      required: false,
                    }}
                  />
                  {errors.IdAgama && (
                    <span className="text-danger">Kolom wajib diisi</span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="label-input mb-2">Hobi</label>
                  <Controller
                    control={control}
                    defaultValue={getValues("IdHobi") || null}
                    name="IdHobi"
                    render={({ field, ref }) => (
                      <ReactSelect
                        inputRef={ref}
                        classNamePrefix="addl-class"
                        options={dataComboHobi}
                        value={getValues("IdHobi")}
                        onChange={(val) => {
                          field.onChange(val);
                        }}
                        isClearable={true}
                      />
                    )}
                    rules={{
                      required: false,
                    }}
                  />
                  {errors.IdHobi && (
                    <span className="text-danger">Kolom wajib diisi</span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="label-input mb-2">Cita-Cita</label>
                  <Controller
                    control={control}
                    defaultValue={getValues("IdCita2") || null}
                    name="IdCita2"
                    render={({ field, ref }) => (
                      <ReactSelect
                        inputRef={ref}
                        classNamePrefix="addl-class"
                        options={dataComboCita}
                        value={getValues("IdCita2")}
                        onChange={(val) => {
                          field.onChange(val);
                        }}
                        isClearable={true}
                      />
                    )}
                    rules={{
                      required: false,
                    }}
                  />
                  {errors.IdCita2 && (
                    <span className="text-danger">Kolom wajib diisi</span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="label-input mb-2">Nomor HP</label>
                  <div className="input-group">
                    <input
                      type="number"
                      className="input-costum form-control"
                      placeholder="Masukkan Nomor HP"
                      {...register("NoHandphone", { required: false })}
                    />
                  </div>
                  {errors.NoHandphone && (
                    <span className="alert-input">
                      Nomor HP tidak boleh kosong
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="label-input mb-2">Nomor Darurat</label>
                  <div className="input-group">
                    <input
                      type="number"
                      className="input-costum form-control"
                      placeholder="Masukkan Nomor Darurat"
                      {...register("NoDarurat", { required: false })}
                    />
                  </div>
                  {errors.NoDarurat && (
                    <span className="alert-input">
                      Nomor Darurat tidak boleh kosong
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="label-input mb-2">Email</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="input-costum form-control"
                      placeholder="Masukkan Email"
                      {...register("Email", { required: false })}
                    />
                  </div>
                  {errors.Email && (
                    <span className="alert-input">
                      Email tidak boleh kosong
                    </span>
                  )}
                </div>
              </div>
              <div className="mb-3">
                <div className="alert alert-warning">
                  <b>Info Upload File </b> <br />
                  1. Ukuran file maksimal 2MB <br />
                  2. Format file yang digunakan wajib <i>.png .jpg .jpeg</i>
                </div>
                <label className="label-input mb-2">
                  Foto Profil (opsional)
                </label>
                <div className="input-group">
                  <input
                    type="file"
                    className="input-costum form-control"
                    onChange={(e) => onFile(e, "FotoProfile")}
                    accept="image/png, image/jpg, image/jpeg"
                  />
                </div>
                <span className="alert-input text-primary">
                  {dataInfoProfil?.FotoProfile && (
                    <a
                      href={dataInfoProfil?.FotoProfile}
                      className="inherit-anchor"
                      target="_blank"
                    >
                      lihat file
                    </a>
                  )}
                </span>
              </div>
              {/* hr */}
              <div className="col-md-12">
                <hr style={{ color: "#afafaf" }} />
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="label-input mb-2">Bahasa</label>
                  <Controller
                    control={control}
                    defaultValue={getValues("IdBahasa") || null}
                    name="IdBahasa"
                    render={({ field, ref }) => (
                      <ReactSelect
                        inputRef={ref}
                        classNamePrefix="addl-class"
                        options={dataComboBahasa}
                        value={getValues("IdBahasa")}
                        onChange={(val) => {
                          field.onChange(val);
                        }}
                        isClearable={true}
                      />
                    )}
                    rules={{
                      required: false,
                    }}
                  />
                  {errors.IdBahasa && (
                    <span className="text-danger">Kolom wajib diisi</span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="label-input mb-2">
                    Berat Badan <small>*dalam satuan Kg</small>
                  </label>
                  <div className="input-group">
                    <input
                      type="number"
                      className="input-costum form-control"
                      placeholder="Masukkan Berat Badan"
                      {...register("BeratBadan", { required: false })}
                    />
                  </div>
                  {errors.BeratBadan && (
                    <span className="alert-input">
                      Berat Badan tidak boleh kosong
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="label-input mb-2">
                    Tinggi Badan <small>*dalam satuan Cm</small>
                  </label>
                  <div className="input-group">
                    <input
                      type="number"
                      className="input-costum form-control"
                      placeholder="Masukkan Tinggi Badan"
                      {...register("TinggiBadan", { required: false })}
                    />
                  </div>
                  {errors.TinggiBadan && (
                    <span className="alert-input">
                      Tinggi Badan tidak boleh kosong
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="label-input mb-2">
                    Lingkar Kepala <small>*dalam satuan Cm</small>
                  </label>
                  <div className="input-group">
                    <input
                      type="number"
                      className="input-costum form-control"
                      placeholder="Masukkan Lingkar Kepala"
                      {...register("LingkarKepala", { required: false })}
                    />
                  </div>
                  {errors.LingkarKepala && (
                    <span className="alert-input">
                      Lingkar Kepala tidak boleh kosong
                    </span>
                  )}
                </div>
              </div>

              {/* hr */}
              <div className="col-md-12">
                <hr style={{ color: "#afafaf" }} />
              </div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="label-input mb-2">Punya Wali</label>
                  <div className="wrapper-radiobtn">
                    <div className="form-check me-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="PunyaWali"
                        id="pwY"
                        value={true}
                        defaultChecked={getValues("PunyaWali") === "true"}
                        {...register("PunyaWali")}
                      />
                      <label className="form-check-label" htmlFor="pwY">
                        Ya
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="PunyaWali"
                        id="pwT"
                        value={false}
                        defaultChecked={getValues("PunyaWali") === "false"}
                        {...register("PunyaWali")}
                      />
                      <label className="form-check-label" htmlFor="pwT">
                        Tidak
                      </label>
                    </div>
                  </div>
                  {errors.PunyaWali && (
                    <span className="alert-input">
                      Punya Wali harus dipilih
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="label-input mb-2">
                    Status Tempat Tinggal
                  </label>
                  <Controller
                    control={control}
                    defaultValue={getValues("IdStatusTempatTinggal") || null}
                    name="IdStatusTempatTinggal"
                    render={({ field, ref }) => (
                      <ReactSelect
                        inputRef={ref}
                        classNamePrefix="addl-class"
                        options={dataComboStatusTempatTinggal}
                        value={getValues("IdStatusTempatTinggal")}
                        onChange={(val) => {
                          field.onChange(val);
                          onAlamat(val.value);
                        }}
                        isClearable={true}
                      />
                    )}
                    rules={{
                      required: false,
                    }}
                  />
                  {errors.IdStatusTempatTinggal && (
                    <span className="text-danger">Kolom wajib diisi</span>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="mb-3">
                  <label className="label-input mb-2">Provinsi</label>
                  <Controller
                    control={control}
                    defaultValue={getValues("IdProvinsi") || null}
                    name="IdProvinsi"
                    render={({ field, ref }) => (
                      <ReactSelect
                        inputRef={ref}
                        classNamePrefix="addl-class"
                        options={dataComboProvinsi}
                        value={getValues("IdProvinsi")}
                        onChange={(val) => {
                          field.onChange(val);
                          onWilayah(val.value, "Provinsi");
                        }}
                        isClearable={true}
                        isDisabled={dataAlamatOrtuWali}
                      />
                    )}
                    rules={{
                      required: false,
                    }}
                  />
                  {errors.IdProvinsi && (
                    <span className="text-danger">Kolom wajib diisi</span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="label-input mb-2">Kabupaten/Kota</label>
                  <Controller
                    control={control}
                    defaultValue={getValues("IdKabkot") || null}
                    name="IdKabkot"
                    render={({ field, ref }) => (
                      <ReactSelect
                        inputRef={ref}
                        classNamePrefix="addl-class"
                        options={dataComboKabkot}
                        value={getValues("IdKabkot")}
                        onChange={(val) => {
                          field.onChange(val);
                          onWilayah(val.value, "Kabkot");
                        }}
                        isClearable={true}
                        isDisabled={dataAlamatOrtuWali}
                      />
                    )}
                    rules={{
                      required: false,
                    }}
                  />
                  {errors.IdKabkot && (
                    <span className="text-danger">Kolom wajib diisi</span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="label-input mb-2">Kecamatan</label>
                  <Controller
                    control={control}
                    defaultValue={getValues("IdKecamatan") || null}
                    name="IdKecamatan"
                    render={({ field, ref }) => (
                      <ReactSelect
                        inputRef={ref}
                        classNamePrefix="addl-class"
                        options={dataComboKecamatan}
                        value={getValues("IdKecamatan")}
                        onChange={(val) => {
                          field.onChange(val);
                          onWilayah(val.value, "Kecamatan");
                        }}
                        isClearable={true}
                        isDisabled={dataAlamatOrtuWali}
                      />
                    )}
                    rules={{
                      required: false,
                    }}
                  />
                  {errors.IdKecamatan && (
                    <span className="text-danger">Kolom wajib diisi</span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="label-input mb-2">Kelurahan/Desa</label>
                  <Controller
                    control={control}
                    defaultValue={getValues("IdDesa") || null}
                    name="IdDesa"
                    render={({ field, ref }) => (
                      <ReactSelect
                        inputRef={ref}
                        classNamePrefix="addl-class"
                        options={dataComboDesa}
                        value={getValues("IdDesa")}
                        onChange={(val) => {
                          field.onChange(val);
                          onWilayah(val.value, "Desa");
                        }}
                        isClearable={true}
                        isDisabled={dataAlamatOrtuWali}
                      />
                    )}
                    rules={{
                      required: false,
                    }}
                  />
                  {errors.IdDesa && (
                    <span className="text-danger">Kolom wajib diisi</span>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="label-input mb-2">Alamat Tunjuk</label>
                  <Controller
                    control={control}
                    defaultValue={getValues("KdAlamatTunjuk") || null}
                    name="KdAlamatTunjuk"
                    render={({ field, ref }) => (
                      <ReactSelect
                        inputRef={ref}
                        classNamePrefix="addl-class"
                        options={dataComboAlamatTunjuk}
                        value={getValues("KdAlamatTunjuk")}
                        onChange={(val) => {
                          field.onChange(val);
                        }}
                        isClearable={true}
                        isDisabled={dataAlamatOrtuWali}
                      />
                    )}
                    rules={{
                      required: false,
                    }}
                  />
                  {errors.KdAlamatTunjuk && (
                    <span className="text-danger">Kolom wajib diisi</span>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="label-input mb-2">Alamat Tinggal</label>
                  <div className="input-group">
                    <textarea
                      name="Alamat"
                      rows={5}
                      className="input-costum form-control"
                      placeholder="Masukkan Alamat Tinggal"
                      {...register("Alamat", {
                        required: false,
                        // maxLength: 25,
                      })}
                      disabled={dataAlamatOrtuWali}
                    />
                  </div>
                  {errors.Alamat && (
                    <span className="alert-input">
                      Alamat Tinggal tidak boleh kosong
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="label-input mb-2">Dusun</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="input-costum form-control"
                      placeholder="Masukkan Dusun"
                      {...register("Dusun", { required: false })}
                      disabled={dataAlamatOrtuWali}
                    />
                  </div>
                  {errors.Dusun && (
                    <span className="alert-input">
                      Dusun tidak boleh kosong
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="label-input mb-2">RT</label>
                  <div className="input-group">
                    <input
                      type="number"
                      className="input-costum form-control"
                      placeholder="Masukkan RT"
                      {...register("Rt", { required: false })}
                      disabled={dataAlamatOrtuWali}
                    />
                  </div>
                  {errors.Rt && (
                    <span className="alert-input">RT tidak boleh kosong</span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="label-input mb-2">RW</label>
                  <div className="input-group">
                    <input
                      type="number"
                      className="input-costum form-control"
                      placeholder="Masukkan RW"
                      {...register("Rw", { required: false })}
                      disabled={dataAlamatOrtuWali}
                    />
                  </div>
                  {errors.Rw && (
                    <span className="alert-input">RW tidak boleh kosong</span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="label-input mb-2">Latitude</label>
                  <div className="input-group">
                    <input
                      type="number"
                      className="input-costum form-control"
                      placeholder="Masukkan Latitude"
                      {...register("Latitude", { required: false })}
                      disabled={dataAlamatOrtuWali}
                    />
                  </div>
                  {errors.Latitude && (
                    <span className="alert-input">
                      Latitude tidak boleh kosong
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="label-input mb-2">Longitude</label>
                  <div className="input-group">
                    <input
                      type="number"
                      className="input-costum form-control"
                      placeholder="Masukkan Longitude"
                      {...register("Longitude", { required: false })}
                      disabled={dataAlamatOrtuWali}
                    />
                  </div>
                  {errors.Longitude && (
                    <span className="alert-input">
                      Longitude tidak boleh kosong
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="label-input mb-2">Kode Pos</label>
                  <div className="input-group">
                    <input
                      type="number"
                      className="input-costum form-control"
                      placeholder="Masukkan Kode Pos"
                      {...register("KdPos", { required: false })}
                      disabled={dataAlamatOrtuWali}
                    />
                  </div>
                  {errors.KdPos && (
                    <span className="alert-input">
                      Kode Pos tidak boleh kosong
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="label-input mb-2">Jenis Transportasi</label>
                  <Controller
                    control={control}
                    defaultValue={getValues("IdJenisTransportasi") || null}
                    name="IdJenisTransportasi"
                    render={({ field, ref }) => (
                      <ReactSelect
                        inputRef={ref}
                        classNamePrefix="addl-class"
                        options={dataComboTransportasi}
                        value={getValues("IdJenisTransportasi")}
                        onChange={(val) => {
                          field.onChange(val);
                        }}
                        isClearable={true}
                      />
                    )}
                    rules={{
                      required: false,
                    }}
                  />
                  {errors.IdTransportasi && (
                    <span className="text-danger">Kolom wajib diisi</span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="label-input mb-2">
                    Jarak Sekolah <small>*dalam satuan Km</small>
                  </label>
                  <div className="input-group">
                    <input
                      type="number"
                      className="input-costum form-control"
                      placeholder="Masukkan Jarak Sekolah"
                      {...register("JarakSekolah", { required: false })}
                    />
                  </div>
                  {errors.JarakSekolah && (
                    <span className="alert-input">
                      Jarak Sekolah tidak boleh kosong
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="label-input mb-2">
                    Waktu Tempuh <small>*dalam satuan Menit</small>
                  </label>
                  <div className="input-group">
                    <input
                      type="number"
                      className="input-costum form-control"
                      placeholder="Masukkan Waktu Tempuh"
                      {...register("WaktuTempuh", { required: false })}
                    />
                  </div>
                  {errors.WaktuTempuh && (
                    <span className="alert-input">
                      Waktu Tempuh tidak boleh kosong
                    </span>
                  )}
                </div>
              </div>

              {/* hr */}
              <div className="col-md-12">
                <hr style={{ color: "#afafaf" }} />
              </div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="label-input mb-2">
                    Yang Membiayai Sekolah
                  </label>
                  <Controller
                    control={control}
                    defaultValue={getValues("IdJenisBiayaSekolah") || null}
                    name="IdJenisBiayaSekolah"
                    render={({ field, ref }) => (
                      <ReactSelect
                        inputRef={ref}
                        classNamePrefix="addl-class"
                        options={dataComboJenisBiayaSiswa}
                        value={getValues("IdJenisBiayaSekolah")}
                        onChange={(val) => {
                          field.onChange(val);
                        }}
                        isClearable={true}
                      />
                    )}
                    rules={{
                      required: false,
                    }}
                  />
                  {errors.IdJenisBiayaSekolah && (
                    <span className="text-danger">Kolom wajib diisi</span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="label-input mb-2">
                    Berkebutuhan Khusus
                  </label>
                  <Controller
                    control={control}
                    defaultValue={getValues("IdKebutuhanKhusus") || null}
                    name="IdKebutuhanKhusus"
                    render={({ field, ref }) => (
                      <ReactSelect
                        inputRef={ref}
                        classNamePrefix="addl-class"
                        options={dataComboKebutuhanKhusus}
                        value={getValues("IdKebutuhanKhusus")}
                        onChange={(val) => {
                          field.onChange(val);
                        }}
                        isClearable={true}
                      />
                    )}
                    rules={{
                      required: false,
                    }}
                  />
                  {errors.IdKebutuhanKhusus && (
                    <span className="text-danger">Kolom wajib diisi</span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="label-input mb-2">
                    Kebutuhan Disabilitas
                  </label>
                  <div className="wrapper-radiobtn">
                    <div className="form-check me-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="KebutuhanDisabilitas"
                        id="kdY"
                        value={true}
                        defaultChecked={
                          getValues("KebutuhanDisabilitas") === "true"
                        }
                        {...register("KebutuhanDisabilitas")}
                      />
                      <label className="form-check-label" htmlFor="kdY">
                        Ya
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="KebutuhanDisabilitas"
                        id="kdT"
                        value={false}
                        defaultChecked={
                          getValues("KebutuhanDisabilitas") === "false"
                        }
                        {...register("KebutuhanDisabilitas")}
                      />
                      <label className="form-check-label" htmlFor="kdT">
                        Tidak Ada
                      </label>
                    </div>
                  </div>
                  {errors.KebutuhanDisabilitas && (
                    <span className="alert-input">
                      Punya Wali harus dipilih
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="label-input mb-2">Pra Sekolah</label>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="PernahTkFormal"
                          id="PernahTkFormal"
                          value={true}
                          defaultChecked={
                            getValues("PernahTkFormal") === "true"
                          }
                          {...register("PernahTkFormal")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="PernahTkFormal"
                        >
                          TK Formal
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="PernahTkInformal"
                          id="PernahTkInformal"
                          value={true}
                          defaultChecked={
                            getValues("PernahTkInformal") === "true"
                          }
                          {...register("PernahTkInformal")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="PernahTkInformal"
                        >
                          TK Informal
                        </label>
                      </div>
                    </div>
                  </div>
                  {errors.TerimaKip && (
                    <span className="alert-input">
                      Terima KIP tidak boleh kosong
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="label-input mb-2">Imunisasi</label>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="ImunisasiHepatitis"
                          id="ImunisasiHepatitis"
                          value={true}
                          defaultChecked={
                            getValues("ImunisasiHepatitis") === "true"
                          }
                          {...register("ImunisasiHepatitis")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="ImunisasiHepatitis"
                        >
                          Hepatitis G
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="ImunisasiBcg"
                          id="ImunisasiBcg"
                          value={true}
                          defaultChecked={getValues("ImunisasiBcg") === "true"}
                          {...register("ImunisasiBcg")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="ImunisasiBcg"
                        >
                          BCG
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="ImunisasiDpt"
                          id="ImunisasiDpt"
                          value={true}
                          defaultChecked={getValues("ImunisasiDpt") === "true"}
                          {...register("ImunisasiDpt")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="ImunisasiDpt"
                        >
                          DPT
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="ImunisasiPolio"
                          id="ImunisasiPolio"
                          value={true}
                          defaultChecked={
                            getValues("ImunisasiPolio") === "true"
                          }
                          {...register("ImunisasiPolio")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="ImunisasiPolio"
                        >
                          Polio
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="ImunisasiCampak"
                          id="ImunisasiCampak"
                          value={true}
                          defaultChecked={
                            getValues("ImunisasiCampak") === "true"
                          }
                          {...register("ImunisasiCampak")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="ImunisasiCampak"
                        >
                          Campak
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="ImunisasiCovid"
                          id="ImunisasiCovid"
                          value={true}
                          defaultChecked={
                            getValues("ImunisasiCovid") === "true"
                          }
                          {...register("ImunisasiCovid")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="ImunisasiCovid"
                        >
                          Covid
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="label-input mb-2">Penyakit Diderita</label>
                  <Controller
                    control={control}
                    defaultValue={getValues("IdPenyakitDiderita") || null}
                    name="IdPenyakitDiderita"
                    render={({ field, ref }) => (
                      <ReactSelect
                        inputRef={ref}
                        classNamePrefix="addl-class"
                        options={dataComboPenyakit}
                        value={getValues("IdPenyakitDiderita")}
                        onChange={(val) => {
                          field.onChange(val);
                        }}
                        isClearable={true}
                      />
                    )}
                    rules={{
                      required: false,
                    }}
                  />
                  {errors.IdPenyakitDiderita && (
                    <span className="text-danger">
                      Penyakit Diderita tidak boleh kosong
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="label-input mb-2">Kelainan Jasmani</label>
                  <Controller
                    control={control}
                    defaultValue={getValues("IdKelainanJasmani") || null}
                    name="IdKelainanJasmani"
                    render={({ field, ref }) => (
                      <ReactSelect
                        inputRef={ref}
                        classNamePrefix="addl-class"
                        options={dataComboKelainan}
                        value={getValues("IdKelainanJasmani")}
                        onChange={(val) => {
                          field.onChange(val);
                        }}
                        isClearable={true}
                      />
                    )}
                    rules={{
                      required: false,
                    }}
                  />
                  {errors.IdKelainanJasmani && (
                    <span className="text-danger">
                      Kelainan Jasmani tidak boleh kosong
                    </span>
                  )}
                </div>
              </div>

              {/* hr */}
              <div className="col-md-12">
                <hr style={{ color: "#afafaf" }} />
              </div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="label-input mb-2">Sekolah Asal</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="input-costum form-control"
                      placeholder="Masukkan Sekolah Asal"
                      {...register("SekolahAsal", { required: false })}
                    />
                  </div>
                  {errors.SekolahAsal && (
                    <span className="alert-input">
                      Sekolah Asal tidak boleh kosong
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="label-input mb-2">
                    Alamat Sekolah Asal
                  </label>
                  <div className="input-group">
                    <textarea
                      name="AlamatSekolahAsal"
                      rows={5}
                      className="input-costum form-control"
                      placeholder="Masukkan Alamat Sekolah Asal"
                      {...register("AlamatSekolahAsal", {
                        required: false,
                      })}
                    />
                  </div>
                  {errors.AlamatSekolahAsal && (
                    <span className="alert-input">
                      Alamat Sekolah Asal tidak boleh kosong
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="label-input mb-2">NPSN Sekolah Asal</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="input-costum form-control"
                      placeholder="Masukkan NPSN Sekolah Asal"
                      {...register("NspnSekolahAsal", { required: false })}
                    />
                  </div>
                  {errors.NspnSekolahAsal && (
                    <span className="alert-input">
                      NPSN Sekolah Asal tidak boleh kosong
                    </span>
                  )}
                </div>
              </div>

              {/* hr */}
              <div className="col-md-12">
                <hr style={{ color: "#afafaf" }} />
              </div>

              <div className="col-md-12">
                <div className="alert alert-warning">
                  <b>Info Upload File </b> <br />
                  1. Ukuran file maksimal 2MB <br />
                  2. Format file yang digunakan wajib{" "}
                  <i>.png .jpg .jpeg .pdf</i>
                </div>
              </div>

              <div className="col-md-12">
                <div className="mb-3">
                  <label className="label-input mb-2">
                    Memiliki KIP (Kartu Indonesia Pintar)
                  </label>
                  <div className="wrapper-radiobtn">
                    <div className="form-check me-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="TerimaKip"
                        id="kipY"
                        value={true}
                        defaultChecked={getValues("TerimaKip") === "true"}
                        {...register("TerimaKip")}
                        onChange={() => setIsKip(true)}
                      />
                      <label className="form-check-label" htmlFor="kipY">
                        Ya
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="TerimaKip"
                        id="kipT"
                        value={false}
                        defaultChecked={getValues("TerimaKip") === "false"}
                        {...register("TerimaKip")}
                        onChange={() => setIsKip(false)}
                      />
                      <label className="form-check-label" htmlFor="kipT">
                        Tidak
                      </label>
                    </div>
                  </div>
                  {errors.TerimaKip && (
                    <span className="alert-input">
                      Terima KIP tidak boleh kosong
                    </span>
                  )}
                </div>
              </div>
              {isKip ? (
                <>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="label-input mb-2">No. KIP</label>
                      <div className="input-group">
                        <input
                          type="number"
                          className="input-costum form-control"
                          placeholder="Masukkan Nomor KIP"
                          {...register("NoKip", { required: false })}
                        />
                      </div>
                      {errors.NoKip && (
                        <span className="alert-input">
                          Nomor KIP tidak boleh kosong
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="label-input mb-2">File KIP</label>
                      <div className="input-group">
                        <input
                          type="file"
                          className="input-costum form-control"
                          onChange={(e) => onFile(e, "FileKip")}
                          accept="image/png, image/jpg, image/jpeg"
                        />
                      </div>
                      <span className="alert-input text-primary">
                        {dataInfoProfil?.Detail?.FileKip && (
                          <a
                            href={dataInfoProfil?.Detail?.FileKip}
                            className="inherit-anchor"
                            target="_blank"
                          >
                            lihat file
                          </a>
                        )}
                      </span>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {/* <div className="col-md-12">
                    <div className="mb-3">
                      <label className="label-input mb-2">
                        Alasan menolak KIP
                      </label>
                      <div className="input-group">
                        <textarea
                          name="AlasanKip"
                          rows={5}
                          className="input-costum form-control"
                          placeholder="Masukkan Alasan menolak KIP"
                          {...register("AlasanKip", {
                            required: false,
                          })}
                        />
                      </div>
                      {errors.AlasanKip && (
                        <span className="alert-input">
                          Alasan menolak KIP tidak boleh kosong
                        </span>
                      )}
                    </div>
                  </div> */}
                </>
              )}

              <div className="col-md-6">
                <div className="mb-3">
                  <label className="label-input mb-2">No. Kartu Keluarga</label>
                  <div className="input-group">
                    <input
                      type="number"
                      className="input-costum form-control"
                      placeholder="Masukkan No. Kartu Keluarga"
                      {...register("NoKk", { required: false })}
                    />
                  </div>
                  {errors.NoKk && (
                    <span className="alert-input">
                      No. Kartu Keluarga tidak boleh kosong
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="label-input mb-2">
                    File Kartu Keluarga
                  </label>
                  <div className="input-group">
                    <input
                      type="file"
                      className="input-costum form-control"
                      onChange={(e) => onFile(e, "FileKk")}
                      accept="image/png, image/jpg, image/jpeg"
                    />
                  </div>
                  <span className="alert-input text-primary">
                    {dataInfoProfil?.Detail?.FileKk && (
                      <a
                        href={dataInfoProfil?.Detail?.FileKk}
                        className="inherit-anchor"
                        target="_blank"
                      >
                        lihat file
                      </a>
                    )}
                  </span>
                </div>
              </div>

              <div className="col-md-6">
                <div className="mb-3">
                  <label className="label-input mb-2">No. Akta</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="input-costum form-control"
                      placeholder="Masukkan No. Akta"
                      {...register("NoAkta", { required: false })}
                    />
                  </div>
                  {errors.NoAkta && (
                    <span className="alert-input">
                      No. Akta tidak boleh kosong
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="label-input mb-2">File Akta</label>
                  <div className="input-group">
                    <input
                      type="file"
                      className="input-costum form-control"
                      onChange={(e) => onFile(e, "FileAkta")}
                      accept="image/png, image/jpg, image/jpeg"
                    />
                  </div>
                  <span className="alert-input text-primary">
                    {dataInfoProfil?.Detail?.FileAkta && (
                      <a
                        href={dataInfoProfil?.Detail?.FileAkta}
                        className="inherit-anchor"
                        target="_blank"
                      >
                        lihat file
                      </a>
                    )}
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="label-input mb-2">No. KTP Ayah</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="input-costum form-control"
                      placeholder="Masukkan No. KtpAyah"
                      {...register("NoKtpAyah", { required: false })}
                    />
                  </div>
                  {errors.NoKtpAyah && (
                    <span className="alert-input">
                      No. KTP Ayah tidak boleh kosong
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="label-input mb-2">File KTP Ayah</label>
                  <div className="input-group">
                    <input
                      type="file"
                      className="input-costum form-control"
                      onChange={(e) => onFile(e, "FileKtpAyah")}
                      accept="image/png, image/jpg, image/jpeg"
                    />
                  </div>
                  <span className="alert-input text-primary">
                    {dataInfoProfil?.Detail?.FileKtpAyah && (
                      <a
                        href={dataInfoProfil?.Detail?.FileKtpAyah}
                        className="inherit-anchor"
                        target="_blank"
                      >
                        lihat file
                      </a>
                    )}
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="label-input mb-2">No. KTP Ibu</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="input-costum form-control"
                      placeholder="Masukkan No. KtpIbu"
                      {...register("NoKtpIbu", { required: false })}
                    />
                  </div>
                  {errors.NoKtpIbu && (
                    <span className="alert-input">
                      No. KTP Ibu tidak boleh kosong
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="label-input mb-2">File KTP Ibu</label>
                  <div className="input-group">
                    <input
                      type="file"
                      className="input-costum form-control"
                      onChange={(e) => onFile(e, "FileKtpIbu")}
                      accept="image/png, image/jpg, image/jpeg"
                    />
                  </div>
                  <span className="alert-input text-primary">
                    {dataInfoProfil?.Detail?.FileKtpIbu && (
                      <a
                        href={dataInfoProfil?.Detail?.FileKtpIbu}
                        className="inherit-anchor"
                        target="_blank"
                      >
                        lihat file
                      </a>
                    )}
                  </span>
                </div>
              </div>

              <div className="col-md-6">
                <div className="mb-3">
                  <label className="label-input mb-2">No. KKS</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="input-costum form-control"
                      placeholder="Masukkan No. Kks"
                      {...register("NoKks", { required: false })}
                    />
                  </div>
                  {errors.NoKks && (
                    <span className="alert-input">
                      No. Kks tidak boleh kosong
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="label-input mb-2">File KKS</label>
                  <div className="input-group">
                    <input
                      type="file"
                      className="input-costum form-control"
                      onChange={(e) => onFile(e, "FileKks")}
                      accept="image/png, image/jpg, image/jpeg"
                    />
                  </div>
                  <span className="alert-input text-primary">
                    {dataInfoProfil?.Detail?.FileKks && (
                      <a
                        href={dataInfoProfil?.Detail?.FileKks}
                        className="inherit-anchor"
                        target="_blank"
                      >
                        lihat file
                      </a>
                    )}
                  </span>
                </div>
              </div>

              <div className="col-md-6">
                <div className="mb-3">
                  <label className="label-input mb-2">No. PKH</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="input-costum form-control"
                      placeholder="Masukkan No. Pkh"
                      {...register("NoPkh", { required: false })}
                    />
                  </div>
                  {errors.NoPkh && (
                    <span className="alert-input">
                      No. Pkh tidak boleh kosong
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="label-input mb-2">File PKH</label>
                  <div className="input-group">
                    <input
                      type="file"
                      className="input-costum form-control"
                      onChange={(e) => onFile(e, "FilePkh")}
                      accept="image/png, image/jpg, image/jpeg"
                    />
                  </div>
                  <span className="alert-input text-primary">
                    {dataInfoProfil?.Detail?.FilePkh && (
                      <a
                        href={dataInfoProfil?.Detail?.FilePkh}
                        className="inherit-anchor"
                        target="_blank"
                      >
                        lihat file
                      </a>
                    )}
                  </span>
                </div>
              </div>
            </div>

            <div>
              <button type="submit" className="btn btn-primary w-100 mt-3">
                Simpan Pembaruan
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SectionStudent;
