import { http } from "src/config";
import { URLSVC } from "src/config/helper/urlService";
import Swal from "sweetalert2";

export const setDataUserInfo = () => {
  return (dispatch) => {
    http.get(`${URLSVC}/Siswas/GetProfil`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        // setTimeout(() => {
        localStorage.setItem("DATA_USER", JSON.stringify(data.Data));
        dispatch({ type: "GB_DATA_USER", payload: data.Data });
        // }, 1000);
      } else {
        Swal.fire({
          title: "Gagal",
          text: `${data.ReturnMessage}`,
          confirmButtonColor: "#f54503",
          icon: "error",
        });
      }
    });
  };
};
