import { Login, ResetPassword } from "src/pages";

const route_guest = [
  {
    path: "",
    exact: true,
    name: "Login",
    Component: Login,
  },
  {
    path: "/login",
    exact: true,
    name: "Login",
    Component: Login,
  },
  {
    path: "/reset-password",
    exact: true,
    name: "Reset Password",
    Component: ResetPassword,
  },
];

export default route_guest;
