import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LogoBig } from "src/assets";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { apiLogin } from "src/config/redux/action";
import ReactLoading from "react-loading";

const SectionForm = () => {
  let navigate = useNavigate();
  const { formLogin } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const btnLogin = (data) => dispatch(apiLogin(data));

  return (
    <div className="col-md-5 vh-100 d-flex align-items-center justify-content-center">
      <div className="logo-login d-none d-md-block">
        <img src={LogoBig} width={80} className={"img-fluid"} />
      </div>
      <div className="card-form-login">
        <center>
          <img
            src={LogoBig}
            width={150}
            className={"img-fluid d-block d-md-none"}
          />
        </center>
        <h1>Halaman Login</h1>
        <h4>
          Ini adalah halaman login siswa, silahkan login dan gunakan fitur
          dengan cara memilih menu yang sudah disediakan
        </h4>
        <div className="mt-4">
          <form onSubmit={handleSubmit(btnLogin)}>
            <div className="mb-3">
              <label className="label-input mb-2">NIS / NISN</label>
              <div className="input-group">
                <input
                  type="text"
                  className="input-costum form-control"
                  name="Nisn"
                  placeholder="Masukkan NIS / NISN"
                  {...register("Nisn", { required: true })}
                />
              </div>
              {errors.Nisn && (
                <span className="text-danger mt-3">
                  NIS / Nisn tidak boleh kosong
                </span>
              )}
            </div>
            <div className="mb-3">
              <label className="label-input mb-2">Kata Sandi</label>
              <div className="input-group">
                <input
                  type="password"
                  className="input-costum form-control"
                  name="Password"
                  placeholder="Masukkan Kata Sandi"
                  {...register("Password", { required: true })}
                />
              </div>
              {errors.Password && (
                <span className="text-danger mt-3">
                  Password tidak boleh kosong
                </span>
              )}
            </div>
            <div
              className="d-flex justify-content-end"
              onClick={() => navigate("/reset-password")}
            >
              <span>Lupa Kata Sandi ?</span>
            </div>
            <hr />
            <div className="d-flex justify-content-between mt-4">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Remember Me
                </label>
              </div>

              {formLogin.isLoading ? (
                <>
                  <button
                    type="button"
                    className="btn btn-primary w-50 d-flex justify-content-center"
                    disabled
                  >
                    <ReactLoading
                      type={"spin"}
                      color={"#fff"}
                      width={15}
                      height={15}
                      className="me-3"
                    />
                    Memuat
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="submit"
                    className="btn btn-primary w-50 d-flex justify-content-center"
                  >
                    Masuk
                  </button>
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SectionForm;
