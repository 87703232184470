import { useNavigate } from "react-router-dom";
import { http } from "src/config";
import {
  FUNCDateToString,
  FUNCIndoDate,
  swalError,
  swalLoading,
} from "src/config/function";
import { URLSVC } from "src/config/helper/urlService";
import Swal from "sweetalert2";

export const setFormElearning = (formType, formValue) => {
  return { type: "FORM_ELEARNING", formType, formValue };
};

export const setStudyModalLink = (bool) => {
  return { type: "MODAL_STUDY_LINK", payload: !bool };
};

export const setStudyModalImage = (bool) => {
  return { type: "MODAL_STUDY_IMAGE", payload: !bool };
};

export const setStudyModalVideo = (bool) => {
  return { type: "MODAL_STUDY_VIDEO", payload: !bool };
};

export const setStudyModalFile = (bool) => {
  return { type: "MODAL_STUDY_FILE", payload: !bool };
};

export const getDateElearningFilterFirstLast = (date) => {
  return (dispatch) => {
    dispatch(setFormElearning("TanggalFilter", date));
    date = FUNCDateToString(date);
    date = date.split("-").reverse().join("-");
    date = new Date(date);
    var firstDate = new Date(date.setDate(date.getDate() + 1 - date.getDay()));
    var lastDate = new Date(date.setDate(date.getDate() - date.getDay() + 5));
    dispatch(setFormElearning("TanggalAwal", FUNCDateToString(firstDate)));
    dispatch(setFormElearning("TanggalAkhir", FUNCDateToString(lastDate)));
  };
};

export const setDataFilterMapel = (date) => {
  return (dispatch) => {
    dispatch(setDataRuangBelajar(FUNCDateToString(date)));
    dispatch(getDateElearningFilterFirstLast(date));
  };
};

export const setDataRuangBelajar = (date) => {
  return (dispatch) => {
    dispatch({ type: "DATA_ELEARNINGS", payload: [] });
    http
      .get(`${URLSVC}/Siswas/GetRuangBelajar?Currdate=${date}`)
      .then((res) => {
        let data = res.data;
        if (data.IsSuccess) {
          if (data.Data?.Materis?.length !== 0) {
            dispatch({
              type: "DATA_ELEARNINGS",
              payload: data.Data?.Materis,
            });
          } else {
            dispatch({ type: "DATA_ELEARNINGS", payload: false });
          }

          dispatch({
            type: "DATA_ELEARNING_DATES",
            payload: data.Data?.Tanggals,
          });
          data.Data?.Tanggals?.map((v, i) => {
            if (v.IsSelect) dispatch(setFormElearning("SelectTabDay", v.Hari));
          });
        } else {
          swalError(res.ReturnMessage);
        }
      });
  };
};

export const setDataRuangBelajarDetail = (id) => {
  return (dispatch) => {
    dispatch({ type: "DATA_ELEARNING", payload: [] });
    swalLoading(true);
    http
      .get(`${URLSVC}/Siswas/GetRuangBelajarDet?IdKbmMateri=${id}`)
      .then((res) => {
        let data = res.data;
        if (data.IsSuccess) {
          swalLoading(false);
          dispatch({
            type: "DATA_ELEARNING",
            payload: data.Data,
          });
        } else {
          swalError(res.ReturnMessage);
        }
      });
  };
};

export const apiUploadJawaban = (iData) => {
  return (dispatch) => {
    var fd = new FormData();

    fd.append("IdMapelKelasMateriTugassoal", iData?.IdKbmSoal);
    fd.append("idJenisBantuanOrtu", iData?.IdJenisBantuanOrtu);
    fd.append("namaUrl", iData?.NamaUrl);
    fd.append("fileJawaban", iData?.FileJawaban);

    http.post(`${URLSVC}/Siswas/UploadJawaban`, fd).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        Swal.fire({
          title: "Berhasil",
          text: "Anda berhasil mengirim tugas",
          confirmButtonColor: "#11A887",
          icon: "success",
        }).then(() => window.location.reload());
      } else {
        swalError(data.ReturnMessage);
      }
    });
  };
};
