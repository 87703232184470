import React from "react";
import {
  IoChevronDown,
  IoLockClosed,
  IoLogOutOutline,
  IoPersonCircleOutline,
} from "react-icons/io5";
import { PopoverBody, UncontrolledPopover } from "reactstrap";
import { DummyUser, IMGNotFoundSquare, Logo } from "src/assets";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { capitalizeFirstLetter } from "src/config";
import { Skeleton } from "@mui/material";

const Header = ({ name }) => {
  let navigate = useNavigate();
  const { gbDataUser } = useSelector((state) => state.globalReducer);
  const btnLogout = () => {
    localStorage.clear();
    navigate("/login");
  };
  return (
    <>
      <div className="d-block d-md-none mobile">
        <div className="menu-mobile">
          <img src={Logo} width={40} className={"img-fluid"} />
          <span className="title-mobile-menu ms-3">
            TAUFIQI EDUCATION CENTERED
          </span>
        </div>
        <div className="list"></div>
      </div>
      <div className="d-block d-md-none py-4 mb-3" />
      <div className="card-web d-none d-md-block">
        <div className="header-web">
          <div className="d-flex justify-content-between align-items-center mb-3 pt-4">
            <span className="breadcrumb-title">{name}</span>
            <button
              type="button"
              className="button-transparent d-none d-sm-block btn-profile"
              id="PopoverFocus"
            >
              <div className="d-flex justify-content-center align-items-center profile">
                {gbDataUser.NamaPanggilan === null ? (
                  <>
                    <Skeleton variant="circular" width={40} height={40} />
                    <Skeleton
                      variant="rectangular"
                      width={130}
                      height={40}
                      style={{ borderRadius: 20, marginLeft: 15 }}
                    />
                  </>
                ) : (
                  <>
                    <div className="card-user-profile">
                      <img
                        src={
                          gbDataUser?.FotoProfile || "http://sasacxcaf.com/sadf"
                        }
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = IMGNotFoundSquare;
                        }}
                        className={"user-profile"}
                      />
                    </div>
                    <span className="mx-3">
                      Hi,{" "}
                      {capitalizeFirstLetter(
                        gbDataUser.NamaPanggilan?.toLowerCase()
                      )}
                    </span>
                    <IoChevronDown color="#3F3D56" size={15} />
                  </>
                )}
              </div>
            </button>
          </div>
        </div>
      </div>
      <UncontrolledPopover
        placement="bottom"
        target="PopoverFocus"
        trigger="legacy"
      >
        <PopoverBody>
          <div
            className="d-flex align-items-center link-profile"
            onClick={() => navigate("/clientarea/update-profile")}
          >
            <IoPersonCircleOutline color="#3F3D56" />
            <span className="ms-2">Ubah Profil</span>
          </div>
          <div
            className="d-flex align-items-center link-profile"
            onClick={() => navigate("/clientarea/security")}
          >
            <IoLockClosed color="#3F3D56" />
            <span className="ms-2">Ganti Password</span>
          </div>
          <hr />
          <div
            className="d-flex align-items-center link-profile"
            onClick={() => btnLogout()}
          >
            <IoLogOutOutline color="red" />
            <span className="ms-2 text-danger">Logout</span>
          </div>
        </PopoverBody>
      </UncontrolledPopover>
    </>
  );
};

export default Header;
