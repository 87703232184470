import { FUNCDateToString } from "src/config/function";

const initialState = {
  dataMutabaahs: [],
  dataMutabaahDates: [],
  dataMutabaah: false,
  modalFilterMutabaah: false,
  formMutabaah: {
    TanggalFilter: new Date(),
    TanggalAwal: "loading",
    TanggalAkhir: "loading",
    SelectTabDay: "Senin",
    TanggalSelect: FUNCDateToString(new Date()),
  },
};

const mutabaahReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DATA_MUTABAAHS":
      return { ...state, dataMutabaahs: action.payload };
    case "DATA_MUTABAAH":
      return { ...state, dataMutabaah: action.payload };
    case "DATA_MUTABAAH_DATES":
      return { ...state, dataMutabaahDates: action.payload };
    case "MODAL_FILTER_MUTABAAH":
      return { ...state, modalFilterMutabaah: action.payload };
    case "FORM_MUTABAAH":
      return {
        ...state,
        formMutabaah: {
          ...state.formMutabaah,
          [action.formType]: action.formValue,
        },
      };
    case "RESET_FORM_MUTABAAH":
      return { ...state, formMutabaah: initialState.formMutabaah };
    default:
      return state;
  }
};

export default mutabaahReducer;
