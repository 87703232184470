import { Skeleton } from "@mui/material";
import React from "react";
import { capitalizeFirstLetter } from "src/config";

const SectionHello = ({ data }) => {
  return (
    <div className="card-hello mb-3">
      <h3>
        {data ? (
          <>
            {/* Hello,
            <span className="ms-2">
              {capitalizeFirstLetter(nameUser?.toLowerCase())} 👋
            </span> */}
            {data?.Konten1} 👋
          </>
        ) : (
          <Skeleton
            variant="rectangular"
            width={250}
            style={{ borderRadius: 20 }}
          />
        )}
      </h3>
      <p>
        {data ? (
          <>{data?.Konten2}</>
        ) : (
          <>
            <Skeleton
              variant="rectangular"
              style={{ borderRadius: 20, marginBottom: 5 }}
            />
            <Skeleton
              variant="rectangular"
              style={{ borderRadius: 20, marginRight: 30 }}
            />
          </>
        )}
      </p>
    </div>
  );
};

export default SectionHello;
