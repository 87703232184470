import {
  http,
  URLSVC,
  FUNCArraySelectId,
  FUNCArraySelectKode,
  swalError,
} from "src/config";

export const setDataComboAgama = (type) => {
  return (dispatch) => {
    http.get(`${URLSVC}/Siswas/GetAgamas`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        let array = FUNCArraySelectId(data.Data);
        dispatch({ type, payload: array });
      } else {
        swalError(data.ReturnMessage);
      }
    });
  };
};

export const setDataComboBahasa = (type) => {
  return (dispatch) => {
    http.get(`${URLSVC}/Siswas/GetBahasas`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        let array = FUNCArraySelectId(data.Data);
        dispatch({ type, payload: array });
      } else {
        swalError(data.ReturnMessage);
      }
    });
  };
};

export const setDataComboKewarganegaraan = (type) => {
  return (dispatch) => {
    http.get(`${URLSVC}/Siswas/GetKewarganegaraans`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        let array = FUNCArraySelectId(data.Data);
        dispatch({ type, payload: array });
      } else {
        swalError(data.ReturnMessage);
      }
    });
  };
};

export const setDataComboHobi = (type) => {
  return (dispatch) => {
    http.get(`${URLSVC}/Siswas/GetJenisHobis`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        let array = FUNCArraySelectId(data.Data);
        dispatch({ type, payload: array });
      } else {
        swalError(data.ReturnMessage);
      }
    });
  };
};

export const setDataComboCita = (type) => {
  return (dispatch) => {
    http.get(`${URLSVC}/Siswas/GetJenisCita2s`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        let array = FUNCArraySelectId(data.Data);
        dispatch({ type, payload: array });
      } else {
        swalError(data.ReturnMessage);
      }
    });
  };
};

export const setDataComboBantuanOrtu = (type) => {
  return (dispatch) => {
    http.get(`${URLSVC}/Siswas/GetBantuanOrtus`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        let array = FUNCArraySelectId(data.Data);
        dispatch({ type, payload: array });
      } else {
        swalError(data.ReturnMessage);
      }
    });
  };
};

export const setDataComboGolonganDarah = (type) => {
  return (dispatch) => {
    http.get(`${URLSVC}/Siswas/GetGolonganDarahs`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        let array = FUNCArraySelectKode(data.Data);
        dispatch({ type, payload: array });
      } else {
        swalError(data.ReturnMessage);
      }
    });
  };
};

export const setDataComboJenisKelamin = (type) => {
  return (dispatch) => {
    http.get(`${URLSVC}/Siswas/GetJenisKelamins`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        let array = FUNCArraySelectKode(data.Data);
        dispatch({ type, payload: array });
      } else {
        swalError(data.ReturnMessage);
      }
    });
  };
};

export const setDataComboStatusNikah = (type) => {
  return (dispatch) => {
    http.get(`${URLSVC}/Siswas/GetStatusNikahs`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        let array = FUNCArraySelectKode(data.Data);
        dispatch({ type, payload: array });
      } else {
        swalError(data.ReturnMessage);
      }
    });
  };
};

export const setDataComboTransportasi = (type) => {
  return (dispatch) => {
    http.get(`${URLSVC}/Siswas/GetTransportasis`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        let array = FUNCArraySelectId(data.Data);
        dispatch({ type, payload: array });
      } else {
        swalError(data.ReturnMessage);
      }
    });
  };
};

export const setDataComboJenisPekerjaan = (type) => {
  return (dispatch) => {
    http.get(`${URLSVC}/Siswas/GetJenisPekerjaans`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        let array = FUNCArraySelectId(data.Data);
        dispatch({ type, payload: array });
      } else {
        swalError(data.ReturnMessage);
      }
    });
  };
};

export const setDataComboJenisPendidikan = (type) => {
  return (dispatch) => {
    http.get(`${URLSVC}/Siswas/GetJenisPendidikans`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        let array = FUNCArraySelectId(data.Data);
        dispatch({ type, payload: array });
      } else {
        swalError(data.ReturnMessage);
      }
    });
  };
};

export const setDataComboKelasParalel = (type, id) => {
  return (dispatch) => {
    http.get(`${URLSVC}/Siswas/GetKelasParalels?IdKelas=${id}`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        let array = FUNCArraySelectId(data.Data);
        dispatch({ type, payload: array });
      } else {
        swalError(data.ReturnMessage);
      }
    });
  };
};

export const setDataComboProvinsi = (type) => {
  return (dispatch) => {
    http.get(`${URLSVC}/Siswas/GetProvinsis`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        let array = FUNCArraySelectId(data.Data);
        dispatch({ type, payload: array });
      } else {
        swalError(data.ReturnMessage);
      }
    });
  };
};

export const setDataComboKabkot = (type, idProvinsi) => {
  return (dispatch) => {
    http
      .get(`${URLSVC}/Siswas/GetKabkots?IdProvinsi=${idProvinsi}`)
      .then((res) => {
        let data = res.data;
        if (data.IsSuccess) {
          let array = FUNCArraySelectId(data.Data);
          dispatch({ type, payload: array });
        } else {
          swalError(data.ReturnMessage);
        }
      });
  };
};

export const setDataComboKecamatan = (type, idKabkot) => {
  return (dispatch) => {
    http
      .get(`${URLSVC}/Siswas/GetKecamatans?IdKabkot=${idKabkot}`)
      .then((res) => {
        let data = res.data;
        if (data.IsSuccess) {
          let array = FUNCArraySelectId(data.Data);
          dispatch({ type, payload: array });
        } else {
          swalError(data.ReturnMessage);
        }
      });
  };
};

export const setDataComboDesa = (type, idKecamatan) => {
  return (dispatch) => {
    http
      .get(`${URLSVC}/Siswas/GetDesas?IdKecamatan=${idKecamatan}`)
      .then((res) => {
        let data = res.data;
        if (data.IsSuccess) {
          let array = FUNCArraySelectId(data.Data);
          dispatch({ type, payload: array });
        } else {
          swalError(data.ReturnMessage);
        }
      });
  };
};

export const setDataComboJenisBiayaSiswa = (type) => {
  return (dispatch) => {
    http.get(`${URLSVC}/Siswas/GetJenisBiayaSiswa`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        let array = FUNCArraySelectId(data.Data);
        dispatch({ type, payload: array });
      } else {
        swalError(data.ReturnMessage);
      }
    });
  };
};

export const setDataComboKebutuhanKhusus = (type) => {
  return (dispatch) => {
    http.get(`${URLSVC}/Siswas/GetSiswaProfilKebutuhanKhusus`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        let array = FUNCArraySelectId(data.Data);
        dispatch({ type, payload: array });
      } else {
        swalError(data.ReturnMessage);
      }
    });
  };
};

export const setDataComboStatusTempatTinggal = (type) => {
  return (dispatch) => {
    http.get(`${URLSVC}/Siswas/GetStatusTempatTinggalSiswa`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        let array = FUNCArraySelectId(data.Data);
        dispatch({ type, payload: array });
      } else {
        swalError(data.ReturnMessage);
      }
    });
  };
};

export const setDataComboAlamatTunjuk = (type) => {
  return (dispatch) => {
    http.get(`${URLSVC}/Siswas/GetAlamatTunjuks`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        let array = FUNCArraySelectKode(data.Data);
        dispatch({ type, payload: array });
      } else {
        swalError(data.ReturnMessage);
      }
    });
  };
};

export const setDataComboStatusOrtu = (type) => {
  return (dispatch) => {
    http.get(`${URLSVC}/Siswas/GetStatusOrtus`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        let array = FUNCArraySelectId(data.Data);
        dispatch({ type, payload: array });
      } else {
        swalError(data.ReturnMessage);
      }
    });
  };
};

export const setDataComboOrtuJenisTempatTinggal = (type) => {
  return (dispatch) => {
    http.get(`${URLSVC}/Siswas/GetSiswaOrtuJenisTempatTinggal`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        let array = FUNCArraySelectId(data.Data);
        dispatch({ type, payload: array });
      } else {
        swalError(data.ReturnMessage);
      }
    });
  };
};

export const setDataComboBeasiswaKategori = (type) => {
  return (dispatch) => {
    http.get(`${URLSVC}/Siswas/GetSiswaBeasiswaKategori`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        let array = FUNCArraySelectId(data.Data);
        dispatch({ type, payload: array });
      } else {
        swalError(data.ReturnMessage);
      }
    });
  };
};

export const setDataComboBeasiswaJenisPemberi = (type) => {
  return (dispatch) => {
    http.get(`${URLSVC}/Siswas/GetSiswaBeasiswaJenisPemberi`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        let array = FUNCArraySelectId(data.Data);
        dispatch({ type, payload: array });
      } else {
        swalError(data.ReturnMessage);
      }
    });
  };
};

export const setDataComboPrestasiJenis = (type) => {
  return (dispatch) => {
    http.get(`${URLSVC}/Siswas/GetSiswaPrestasiJenis`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        let array = FUNCArraySelectId(data.Data);
        dispatch({ type, payload: array });
      } else {
        swalError(data.ReturnMessage);
      }
    });
  };
};

export const setDataComboPrestasiTingkat = (type) => {
  return (dispatch) => {
    http.get(`${URLSVC}/Siswas/GetSiswaPrestasiTingkat`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        let array = FUNCArraySelectId(data.Data);
        dispatch({ type, payload: array });
      } else {
        swalError(data.ReturnMessage);
      }
    });
  };
};

export const setDataComboPrestasiPeringkat = (type) => {
  return (dispatch) => {
    http.get(`${URLSVC}/Siswas/GetSiswaPrestasiPeringkat`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        let array = FUNCArraySelectId(data.Data);
        dispatch({ type, payload: array });
      } else {
        swalError(data.ReturnMessage);
      }
    });
  };
};

export const setDataComboPrestasiKategori = (type) => {
  return (dispatch) => {
    http.get(`${URLSVC}/Siswas/GetSiswaPrestasiKategori`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        let array = FUNCArraySelectId(data.Data);
        dispatch({ type, payload: array });
      } else {
        swalError(data.ReturnMessage);
      }
    });
  };
};

export const setDataComboJenisPenyakit = (type) => {
  return (dispatch) => {
    http.get(`${URLSVC}/Siswas/GetJenisPenyakits`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        let array = FUNCArraySelectId(data.Data);
        dispatch({ type, payload: array });
      } else {
        swalError(data.ReturnMessage);
      }
    });
  };
};

export const setDataComboJenisKelainan = (type) => {
  return (dispatch) => {
    http.get(`${URLSVC}/Siswas/GetJenisKelainans`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        let array = FUNCArraySelectId(data.Data);
        dispatch({ type, payload: array });
      } else {
        swalError(data.ReturnMessage);
      }
    });
  };
};
