import { http } from "src/config";
import { URLSVC } from "src/config/helper/urlService";
import Swal from "sweetalert2";

export const setDataHome = () => {
  return (dispatch) => {
    http.get(`${URLSVC}/Siswas/GetHome`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        dispatch({ type: "DATA_HOME", payload: data.Data });
      } else {
        Swal.fire({
          title: "Gagal",
          text: `${data.ReturnMessage}`,
          confirmButtonColor: "#f54503",
          icon: "error",
        });
      }
    });
  };
};
