import React from "react";
import { Controller, useForm } from "react-hook-form";
import { IoIosClose } from "react-icons/io";
import { IoCalendarOutline } from "react-icons/io5";
import ReactInputMask from "react-input-mask";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import { DatePicker } from "src/components";
import { setDataFilterQiroaty } from "src/config/redux/action";

const SectionModalFilter = ({ isOpen, onModal }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    reset,
  } = useForm();
  const { formQiroaty } = useSelector((state) => state.qiroatyReducer);
  const dispatch = useDispatch();
  const btnFilter = (data) => {
    dispatch(setDataFilterQiroaty(data.Tanggal, formQiroaty.SelectType));
    onModal();
  };
  return (
    <Modal centered isOpen={isOpen} toggle={onModal}>
      <ModalBody>
        <div className="mb-2">
          <div className="close-modal">
            <span onClick={() => onModal()}>
              <IoIosClose size={30} color="#5F5D7E" />
            </span>
          </div>
        </div>
        <div className="p-3">
          <div className="modal-filter-mapel-date">
            <div className="d-flex align-items-center">
              <IoCalendarOutline color="#3F3D56" size={20} />
              <h4>Filter Jadwal</h4>
            </div>
            <form className="mt-3" onSubmit={handleSubmit(btnFilter)}>
              <label className="label-input mb-2">Tanggal</label>
              <div className="input-group mb-3">
                <Controller
                  control={control}
                  name="Tanggal"
                  render={({ field, onChange, value }) => (
                    <DatePicker
                      placeholderText="Masukkan Tanggal..."
                      selected={field.value}
                      value={getValues("Tanggal")}
                      onChange={(val) => field.onChange(val)}
                      dateFormat="dd-MM-yyyy"
                      maxDate={new Date()}
                      customInput={
                        <ReactInputMask
                          mask="99-99-9999"
                          onChange={field.onChange}
                          value={field.value}
                          maskChar=""
                        />
                      }
                    />
                  )}
                  rules={{
                    required: true,
                  }}
                />
                {errors.Tanggal && (
                  <span className="alert-input">Kolom wajib diisi</span>
                )}
              </div>
              <button type="submit" className="btn btn-primary w-100 mt-3">
                Mulai Pencarian
              </button>
            </form>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default SectionModalFilter;
