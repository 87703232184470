import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Header } from "src/components";
import { setDataHome } from "src/config/redux/action";
import SectionContent from "./parts/SectionContent";

const Calendar = (props) => {
  const { dataHome } = useSelector((state) => state.homeReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setDataHome());
  }, []);
  return (
    <>
      <Header name={props.name} />
      <div className="row">
        <div className="card-global" style={{ height: "85vh" }}>
          <div className="d-flex align-items-center">
            <div className="icon-global"></div>
            <h5 className="title-global">Kalender</h5>
          </div>
          <SectionContent data={dataHome?.Kalenders} />
        </div>
      </div>
    </>
  );
};

export default Calendar;
