import React from "react";
import { ILStudy } from "src/assets";
import {
  IoNewspaperOutline,
  IoPersonOutline,
  IoTimeOutline,
} from "react-icons/io5";

const SectionHero = ({ data }) => {
  return (
    <div className="hero">
      <div className="mapel row">
        <div className="col-md-4 d-none d-md-block">
          <center>
            <img src={ILStudy} className="img-fluid" />
          </center>
        </div>
        <div className="col-md-7 align-self-center">
          <div className="d-flex align-items-center mb-2">
            <div className="me-2">
              <IoNewspaperOutline color="#fff" size={20} />
            </div>
            <div>
              <h5>
                <b>Mapel</b> : {data?.Mapel}
              </h5>
            </div>
          </div>
          <div className="d-flex align-items-center mb-2">
            <div className="me-2">
              <IoTimeOutline color="#fff" size={20} />
            </div>
            <div>
              <h5>
                <b>Jam</b> : {data?.JamMulai} s/d {data?.JamSelesai}
              </h5>
            </div>
          </div>
          <div className="d-flex align-items-center mb-2">
            <div className="me-2">
              <IoPersonOutline color="#fff" size={20} />
            </div>
            <div>
              <h5>
                <b>Guru</b> : {data?.Guru}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionHero;
