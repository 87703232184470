import {
  http,
  FUNCDateToString,
  URLSVC,
  swalError,
  swalLoading,
} from "src/config";
import Swal from "sweetalert2";

export const setFormMutabaah = (formType, formValue) => {
  return { type: "FORM_MUTABAAH", formType, formValue };
};

export const getDateMutabaahFilterFirstLast = (date = new Date()) => {
  return (dispatch) => {
    dispatch(setFormMutabaah("TanggalFilter", date));
    date = FUNCDateToString(date);
    date = date.split("-").reverse().join("-");
    date = new Date(date);
    var firstDate = new Date(date.setDate(date.getDate() + 1 - date.getDay()));
    var lastDate = new Date(date.setDate(date.getDate() - date.getDay() + 5));
    dispatch(setFormMutabaah("TanggalAwal", FUNCDateToString(firstDate)));
    dispatch(setFormMutabaah("TanggalAkhir", FUNCDateToString(lastDate)));
  };
};

export const setDataFilterMutabaah = (date) => {
  return (dispatch) => {
    dispatch(setDataMutabaah(FUNCDateToString(date)));
    dispatch(getDateMutabaahFilterFirstLast(date));
  };
};

export const setDataMutabaah = (date) => {
  return (dispatch) => {
    dispatch({ type: "DATA_MUTABAAHS", payload: [] });
    http.get(`${URLSVC}/Siswas/GetMutabaah?Currdate=${date}`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        dispatch({ type: "DATA_MUTABAAHS", payload: data.Data?.Mutabaahs });
        dispatch({
          type: "DATA_MUTABAAH_DATES",
          payload: data.Data?.Tanggals,
        });
        data.Data?.Tanggals?.map((v, i) => {
          if (v.IsSelect) dispatch(setFormMutabaah("SelectTabDay", v.Hari));
        });
      } else {
        swalError(data.ReturnMessage);
      }
    });
  };
};

export const apiSaveMutabaah = (iData, date) => {
  return (dispatch) => {
    var fd = new FormData();
    fd.append("Tanggal", date);
    iData.map((v, i) => {
      fd.append(
        `Jawabans[${i}].IdJenisEvaluasiHarian`,
        v.IdJenisEvaluasiHarian
      );
      fd.append(`Jawabans[${i}].IsDone`, v.IsDone);
    });

    http.post(`${URLSVC}/Siswas/SaveMutabaah`, fd).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        Swal.fire({
          title: "Berhasil",
          text: "Anda berhasil menyimpan mutabaah harian",
          confirmButtonColor: "#11A887",
          icon: "success",
        });
      } else {
        swalError(data.ReturnMessage);
      }
    });
  };
};

// function selectWeek(date) {
//   return Array(6)
//     .fill(new Date(date))
//     .map((el, idx) => new Date(el.setDate(el.getDate() - el.getDay() + idx)));
// }

// const date = new Date();
// // console.log(selectWeek(date));

// selectWeek(date).map((v, i) => {
//   console.log(FUNCDateToString(v));
// });
