import React from "react";
import { IoEaselOutline, IoReaderOutline } from "react-icons/io5";
import { BGProfile, DummyUser, IMGNotFoundSquare } from "src/assets";
import { Skeleton } from "@mui/material";

const SectionProfile = ({ data }) => {
  return (
    <div className="card-profile mb-3">
      <div>
        <img src={BGProfile} className="img-fluid profile-bg" />
      </div>
      <div className="card-profile-user">
        {data === null ? (
          <Skeleton
            variant="rectangular"
            width={80}
            height={80}
            style={{ borderRadius: 20 }}
          />
        ) : (
          <img
            src={data?.Profil?.ImageFoto}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = IMGNotFoundSquare;
            }}
            className="img-fluid profile-user"
          />
        )}
      </div>
      <p className="text-name mt-5 mb-2">
        {data === null ? (
          <Skeleton
            variant="rectangular"
            width={250}
            style={{ borderRadius: 20 }}
          />
        ) : (
          <>
            {data?.Profil?.Nama}
            <br />
            {data?.Profil?.Kelas}
          </>
        )}
      </p>
      <div className="row">
        <div className="col-6">
          <div className="card-static">
            <IoEaselOutline color="#11A887" size={30} />
            <h1>{data?.JumlahMapel?.Mapel || 0}</h1>
            <span>Mapel</span>
          </div>
        </div>
        <div className="col-6">
          <div className="card-static">
            <IoReaderOutline color="#11A887" size={30} />
            <h1>{data?.JumlahMapel?.Mutabaah || 0}</h1>
            <span>Mutabaah</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionProfile;
