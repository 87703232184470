import React, { useEffect, useState } from "react";
import {
  IoIosClose,
  IoIosMail,
  IoIosMailOpen,
  IoIosNotifications,
} from "react-icons/io";
import { FaRegBell, FaRegClock } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import { Header, DataTable } from "src/components";
import { setDataInbox, setDataInboxs } from "src/config/redux/action";
import "./style.css";

const Inbox = (props) => {
  const { dataInboxs, dataInbox, modalInbox } = useSelector(
    (state) => state.inboxReducer
  );
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const showModalInbox = () => {
    dispatch({ type: "MODAL_INBOX", payload: !modalInbox });
  };

  useEffect(() => {
    dispatch(setDataInboxs());
  }, []);

  const btnDetail = (id) => {
    dispatch(setDataInbox(id));
  };

  const columns = [
    {
      title: "AKSI",
      field: "internal_action",
      cellStyle: {
        width: 160,
        minWidth: 160,
      },
      headerStyle: {
        width: 160,
        minWidth: 160,
      },
      render: (rowData) => (
        <>
          <button
            className="btn btn-primary btn-sm"
            onClick={() => btnDetail(rowData.IdSiswaInbox)}
          >
            {rowData.IsRead && (
              <>
                <IoIosMailOpen color="white" /> Dibaca
              </>
            )}
            {!rowData.IsRead && (
              <>
                <IoIosMail color="white" /> Belum Dibaca
              </>
            )}
          </button>
        </>
      ),
    },
    {
      title: "JUDUL",
      field: "Judul",
      cellStyle: {
        width: 200,
        minWidth: 200,
      },
      headerStyle: {
        width: 200,
        minWidth: 200,
      },
    },
    {
      title: "DESKRIPSI",
      field: "Deskripsi",
      cellStyle: {
        width: 300,
        minWidth: 300,
      },
      headerStyle: {
        width: 300,
        minWidth: 300,
      },
      render: (rowData) => (
        <>
          <span
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              width: "300px",
              display: "block",
              overflow: "hidden",
            }}
          >
            {rowData.Deskripsi}
          </span>
        </>
      ),
    },
    {
      title: "TANGGAL",
      field: "CreatedDate",
    },
    {
      title: "OLEH",
      field: "CreatedBy",
    },
  ];
  return (
    <>
      <Header name={props.name} />
      <DataTable title="Data Tabel" columns={columns} data={dataInboxs} />

      <Modal centered isOpen={modalInbox} toggle={showModalInbox}>
        <ModalBody>
          <div className="mb-2">
            <div className="close-modal">
              <span onClick={() => showModalInbox()}>
                <IoIosClose size={30} color="#5F5D7E" />
              </span>
            </div>
          </div>
          <div className="p-3">
            <div className="modal-inbox-date">
              <div className="icon">
                <FaRegBell color="white" size={17} />
              </div>
              <div className="date">
                <FaRegClock color="#727272" size={13} />
                <span>{dataInbox?.CreatedDate}</span>
              </div>
            </div>
            <div className="modal-inbox-content">
              <h3>{dataInbox?.Judul}</h3>
              <p>{dataInbox?.Deskripsi}</p>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Inbox;
