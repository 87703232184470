import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DataTable } from "src/components";
import {
  apiInfoDeletePrestasi,
  setDataComboPrestasiJenis,
  setDataComboPrestasiKategori,
  setDataComboPrestasiPeringkat,
  setDataComboPrestasiTingkat,
  setDataInfoPrestasi,
  setDataInfoPrestasis,
} from "src/config/redux/action";
import SectionModalPrestasi from "./SectionModalPrestasi";

const SectionPrestasi = () => {
  const { dataInfoPrestasis, dataInfoPrestasi, modalPrestasi } = useSelector(
    (state) => state.userReducer
  );
  const [isAdd, setIsAdd] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setDataInfoPrestasis());
    dispatch(setDataComboPrestasiJenis("DATA_COMBO_PRESTASI_JENIS"));
    dispatch(setDataComboPrestasiTingkat("DATA_COMBO_PRESTASI_TINGKAT"));
    dispatch(setDataComboPrestasiPeringkat("DATA_COMBO_PRESTASI_PERINGKAT"));
    dispatch(setDataComboPrestasiKategori("DATA_COMBO_PRESTASI_KATEGORI"));
  }, [dispatch]);

  const columns = [
    {
      title: "AKSI",
      field: "Tahun",
      cellStyle: {
        width: 160,
        minWidth: 160,
      },
      headerStyle: {
        width: 160,
        minWidth: 160,
      },
      render: (rowData) => (
        <>
          <button
            type="button"
            onClick={() => addEditPrestasi(false, rowData.IdSiswaPrestasi)}
            className="btn btn-warning me-1"
          >
            Edit
          </button>
          <button
            type="button"
            onClick={() =>
              dispatch(
                apiInfoDeletePrestasi(
                  rowData.IdSiswaPrestasi,
                  `${rowData.Nama}`
                )
              )
            }
            className="btn btn-danger"
          >
            Hapus
          </button>
        </>
      ),
    },
    {
      title: "TAHUN",
      field: "Tahun",
    },
    {
      title: "NAMA",
      field: "Nama",
    },
    {
      title: "JENIS",
      field: "Jenis",
      render: (rowData) => (
        <>
          <span>{rowData.JenisPrestasi.Nama}</span>
        </>
      ),
    },
    {
      title: "TINGKAT",
      field: "Jenis",
      render: (rowData) => (
        <>
          <span>{rowData.JenisTingkatPrestasi.Nama}</span>
        </>
      ),
    },
    {
      title: "PERINGKAT",
      field: "Jenis",
      render: (rowData) => (
        <>
          <span>{rowData.JenisPeringkat.Nama}</span>
        </>
      ),
    },
    {
      title: "KATEGORI",
      field: "Jenis",
      render: (rowData) => (
        <>
          <span>{rowData.Kategori.Nama}</span>
        </>
      ),
    },
    {
      title: "PENYELENGGARA",
      field: "NamaPenyelenggara",
    },
    {
      title: "SERTIFIKAT",
      field: "FileSertifikat",
      render: (rowData) => (
        <>
          <a href={rowData.FileSertifikat} target="_blank">
            Download
          </a>
        </>
      ),
    },
  ];

  const showModal = () =>
    dispatch({
      type: "MODAL_PRESTASI",
      payload: !modalPrestasi,
    });

  const addEditPrestasi = (isAdd, id) => {
    setIsAdd(isAdd);
    if (!isAdd) {
      dispatch(setDataInfoPrestasi(id));
    } else {
      showModal();
    }
  };
  return (
    <>
      <div className="mb-3">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => addEditPrestasi(true)}
        >
          Tambah Prestasi
        </button>
      </div>
      <DataTable
        title="Data Tabel"
        columns={columns}
        data={dataInfoPrestasis}
      />
      <SectionModalPrestasi
        isOpen={modalPrestasi}
        onModal={showModal}
        isAdd={isAdd}
        dataPrestasi={dataInfoPrestasi}
      />
    </>
  );
};

export default SectionPrestasi;
