import React, { useEffect } from "react";
import {
  IoCalendarClearOutline,
  IoDownloadOutline,
  IoFilterOutline,
} from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { DataTable } from "src/components";
import { FUNCDateToString } from "src/config";
import {
  getDateQiroatyFilterFirstLast,
  setDataQiroatyResults,
} from "src/config/redux/action";

const SectionResult = () => {
  const { modalFilterQiroaty, formQiroaty, dataQiroatyResults } = useSelector(
    (state) => state.qiroatyReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDateQiroatyFilterFirstLast(formQiroaty.TanggalFilter));
    dispatch(
      setDataQiroatyResults(FUNCDateToString(formQiroaty.TanggalFilter))
    );
  }, [dispatch]);

  const columns = [
    {
      title: "TANGGAL",
      field: "Tanggal",
    },
    {
      title: "GURU",
      field: "Guru",
    },
    {
      title: "JILID",
      field: "Guru",
      render: (rowData) => (
        <>
          <span>{rowData.JenisJilidQuran.Nama}</span>
        </>
      ),
    },
    {
      title: "SERTIFIKAT",
      field: "Sertifikat",
      render: (rowData) => (
        <>
          <a href={rowData.Sertifikat} target="_blank">
            Download
          </a>
        </>
      ),
    },
    {
      title: "HASIL",
      field: "Hasil",
      render: (rowData) => (
        <>
          {rowData.Hasil === "Lulus" && (
            <span className="badge badge-success">{rowData.Hasil}</span>
          )}
          {rowData.Hasil !== "Lulus" && (
            <span className="badge badge-warning">{rowData.Hasil}</span>
          )}
        </>
      ),
    },
    {
      title: "NILAI",
      field: "NilaiHuruf",
    },
    {
      title: "CATATAN",
      field: "Catatan",
    },
  ];

  return (
    <>
      <DataTable
        title="Data Tabel"
        columns={columns}
        data={dataQiroatyResults}
      />
    </>
  );
};

export default SectionResult;
