import axios from "axios";
import { http, URLSVC } from "src/config";
import Swal from "sweetalert2";
import { setDataUserInfo } from "./globalAction";

export const setFormLogin = (formType, formValue) => {
  return { type: "FORM_LOGIN", formType, formValue };
};
export const setFormChangePassword = (formType, formValue) => {
  return { type: "FORM_CHANGE_PASSWORD", formType, formValue };
};

const direct = (dispatch) => {
  // dispatch(setDataUserInfo());
  dispatch(setFormLogin("isLoading", false));
  window.location = "/";
};

export const apiLogin = (iData) => {
  return (dispatch) => {
    var fd = new FormData();

    fd.append("nisOrNisn", iData.Nisn);
    fd.append("password", iData.Password);

    dispatch(setFormLogin("isLoading", true));
    axios
      .post(`${URLSVC}/Siswas/Login`, fd)
      .then((res) => {
        let data = res.data;
        if (data.IsSuccess) {
          localStorage.setItem("TOKEN", data.Data);
          // setTimeout(() => {
          //   dispatch(setDataUserInfo());
          //   dispatch(setFormLogin("isLoading", false));
          // }, 2000);
          Swal.fire({
            title: "Berhasil",
            text: "Selamat datang!!!",
            confirmButtonColor: "#11A887",
            icon: "success",
          }).then(() => direct(dispatch));
          // }).then(() => (window.location = "/"));
        } else {
          dispatch(setFormLogin("isLoading", false));
          Swal.fire({
            title: "Gagal",
            text: `${data.ReturnMessage}`,
            confirmButtonColor: "#f54503",
            icon: "error",
          });
        }
      })
      .catch((e) => {
        setTimeout(() => {
          dispatch(setFormLogin("isLoading", false));
        }, 2000);
      });
  };
};

export const apiChangePassword = (iData) => {
  return (dispatch) => {
    var fd = new FormData();

    fd.append("oldPassword", iData.OldPassword);
    fd.append("newPassword1", iData.NewPassword1);
    fd.append("newPassword2", iData.NewPassword2);

    dispatch(setFormChangePassword("isLoading", true));

    http.post(`${URLSVC}/Siswas/ChangePasswordSiswa`, fd).then((res) => {
      let data = res.data;
      dispatch(setFormChangePassword("isLoading", false));
      if (data.IsSuccess) {
        Swal.fire({
          title: "Berhasil",
          text: "Anda berhasil mengganti password baru",
          confirmButtonColor: "#11A887",
          icon: "success",
        });
      } else {
        Swal.fire({
          title: "Gagal",
          text: `${data.ReturnMessage}`,
          confirmButtonColor: "#f54503",
          icon: "error",
        });
      }
    });
  };
};
