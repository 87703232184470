import Kalend, { CalendarView } from "kalend";
import React, { useState, useEffect } from "react";
import { FUNCDateDmytoYmd } from "src/config";

const SectionContent = ({ data }) => {
  const [events, setEvents] = useState([]);
  useEffect(() => {
    let x = [];
    data?.map((v, i) => {
      const tglMulai = v.TanggalMulai.split(" ");
      const tglSelesai = v.TanggalSelesai.split(" ");

      x.push({
        id: i + 1,
        startAt: new Date(FUNCDateDmytoYmd(tglMulai[0])).toISOString(),
        endAt: new Date(FUNCDateDmytoYmd(tglSelesai[0])).toISOString(),
        timezoneStartAt: "Asia/Jakarta", // optional
        summary: v.Kegiatan,
        color: v.Warna,
      });
    });

    setEvents(x);
  }, [data]);
  return (
    <div className="mt-4" style={{ height: "70vh" }}>
      <Kalend
        events={events}
        initialDate={new Date().toISOString()}
        hourHeight={60}
        initialView={CalendarView.MONTH}
        disabledViews={[
          CalendarView.DAY,
          CalendarView.WEEK,
          CalendarView.THREE_DAYS,
        ]}
        timeFormat={"24"}
        weekDayStart={"Monday"}
        language={"en"}
      />
    </div>
  );
};

export default SectionContent;
