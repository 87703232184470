import React, { useState, useEffect } from "react";
import { Header } from "src/components";
import { IoCalendarOutline } from "react-icons/io5";
import { useSelector, useDispatch } from "react-redux";
import {
  getDateMutabaahFilterFirstLast,
  setDataMutabaah,
} from "src/config/redux/action";
import SectionModalFilter from "./parts/SectionModalFilter";
import "./style.css";
import SectionMutabaah from "./parts/SectionMutabaah";
import { FUNCDateToString } from "src/config";

const Mutabaah = (props) => {
  const [isFilterModal, setIsFilterModal] = useState(false);

  const {
    modalFilterMutabaah,
    formMutabaah,
    dataMutabaahs,
    dataMutabaahDates,
  } = useSelector((state) => state.mutabaahReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDateMutabaahFilterFirstLast(formMutabaah.TanggalFilter));
    dispatch(setDataMutabaah(FUNCDateToString(formMutabaah.TanggalFilter)));
  }, [dispatch]);

  const showModalFilterMutabaah = () =>
    dispatch({
      type: "MODAL_FILTER_MUTABAAH",
      payload: !modalFilterMutabaah,
    });

  const showFilterModal = () => setIsFilterModal(!isFilterModal);

  return (
    <>
      <Header name={props.name} />
      <div className="filter-mutabaah-mobile d-block d-md-none">
        <div
          className="d-flex align-items-center"
          onClick={() => showModalFilterMutabaah()}
        >
          <IoCalendarOutline color="#3F3D56" size={18} />
          <h4>Filter</h4>
        </div>
      </div>
      <SectionMutabaah
        dataMutabaahs={dataMutabaahs}
        dataMutabaahDates={dataMutabaahDates}
        onFilterModal={() => showModalFilterMutabaah()}
      />
      <SectionModalFilter
        isOpen={modalFilterMutabaah}
        onModal={showModalFilterMutabaah}
      />
    </>
  );
};

export default Mutabaah;
