import { Skeleton } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import { ILHomeTheoryNotfound } from "src/assets";

const SectionMateri = ({ data }) => {
  return (
    <div className="card-global mb-3">
      <div className="d-flex align-items-center">
        <div className="icon-global"></div>
        <h5 className="title-global">Materi</h5>
      </div>
      <div className="mt-4">
        {data && data.length > 0 && (
          <Slider
            slidesPerRow={1}
            autoplay={true}
            infinite={true}
            speed={500}
            arrows={false}
            responsive={[
              {
                breakpoint: 801,
                settings: {
                  slidesPerRow: 1,
                },
              },
            ]}
          >
            {data.map((v, i) => {
              return (
                <div key={i} className="card-mapel">
                  <div className="title-mapel">
                    <span>{v.JamMulai}</span>
                  </div>
                  <div className="mt-3">
                    <h5>{v.Mapel}</h5>
                    <h3>{v.Judul || "Tidak ada judul"}</h3>
                    <h6>{v.Guru}</h6>
                  </div>
                </div>
              );
            })}
          </Slider>
        )}

        {data && data.length === 0 && (
          <div>
            <center>
              <img
                src={ILHomeTheoryNotfound}
                width={250}
                className="img-fluid mb-3"
              />
              <p>Tidak ada materi hari ini</p>
            </center>
          </div>
        )}

        {!data && (
          <Skeleton
            variant="rectangular"
            height={200}
            style={{ borderRadius: 20 }}
          />
        )}
      </div>
    </div>
  );
};

export default SectionMateri;
