import { combineReducers } from "redux";

import authReducer from "./authReducer";
import globalReducer from "./globalReducer";
import inboxReducer from "./inboxReducer";
import mutabaahReducer from "./mutabaahReducer";
import elearningReducer from "./elearningReducer";
import userReducer from "./userReducer";
import comboReducer from "./comboReducer";
import homeReducer from "./homeReducer";
import qiroatyReducer from "./qiroatyReducer";

const reducer = combineReducers({
  authReducer,
  globalReducer,
  inboxReducer,
  mutabaahReducer,
  elearningReducer,
  userReducer,
  comboReducer,
  homeReducer,
  qiroatyReducer,
});

export default reducer;
