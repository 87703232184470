import React from "react";

const ItemBanner = ({ img, title }) => {
  return (
    <div>
      <div className="banner-login mx-3">
        <center>
          <img src={img} className="img-fluid" />
        </center>
        <h6>Keunggulan :</h6>
        <h4>{title}</h4>
      </div>
    </div>
  );
};

export default ItemBanner;
