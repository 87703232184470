import { getValue } from "@testing-library/user-event/dist/utils";
import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  IoCheckmarkSharp,
  IoCloudDownloadOutline,
  IoFolderOpenOutline,
  IoImageOutline,
  IoLinkOutline,
  IoVideocamOutline,
} from "react-icons/io5";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import {
  ILStudyUpload,
  ILStudyUploadNotfound,
  ILStudyUploadRubrik,
} from "src/assets";
import { Alert, ReactSelect } from "src/components";
import {
  FUNCIsUrl,
  FUNCValidateUploadFileExtension,
  FUNCValidateUploadFileSize,
} from "src/config";
import {
  apiUploadJawaban,
  setFormElearning,
  setStudyModalFile,
  setStudyModalImage,
  setStudyModalLink,
  setStudyModalVideo,
} from "src/config/redux/action";
import ItemTabTask from "./components/ItemTabTask";

const SectionContent = ({
  data,
  modalLink,
  modalImage,
  modalVideo,
  modalFile,
}) => {
  const [isTab, setIsTab] = useState(1);
  return (
    <div className="content">
      <Nav className="card-tab-study-play-header" tabs>
        <NavItem>
          <NavLink
            className={isTab === 1 ? "active" : ""}
            onClick={() => setIsTab(1)}
          >
            Materi
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={isTab === 2 ? "active" : ""}
            onClick={() => setIsTab(2)}
          >
            Soal
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={isTab === 3 ? "active" : ""}
            onClick={() => setIsTab(3)}
          >
            Jawaban
          </NavLink>
        </NavItem>
        {data.Hasil !== null && (
          <NavItem>
            <NavLink
              className={isTab === 4 ? "active" : ""}
              onClick={() => setIsTab(4)}
            >
              Hasil
            </NavLink>
          </NavItem>
        )}
      </Nav>
      <TabContent
        activeTab={`${isTab}`}
        className="card-tab-study-play-content mt-4 ms-4"
      >
        <TabPane tabId="1">
          <ItemTabTheory
            data={data}
            modalLink={modalLink}
            modalImage={modalImage}
            modalVideo={modalVideo}
            modalFile={modalFile}
          />
        </TabPane>
        <TabPane tabId="2">
          <ItemTabTask data={data} />
        </TabPane>
        <TabPane tabId="3">
          <ItemTabAnswer data={data} />
        </TabPane>
        <TabPane tabId="4">
          <ItemTabScore data={data} />
        </TabPane>
      </TabContent>
    </div>
  );
};

const ItemTabTheory = ({
  data,
  modalLink,
  modalImage,
  modalVideo,
  modalFile,
}) => {
  const dispatch = useDispatch();
  const [attachment, setAttachment] = useState({
    file: [],
    image: [],
    video: [],
    link: [],
  });

  useEffect(() => {
    dispatch(setFormElearning("AttachmentLink", null));
    dispatch(setFormElearning("AttachmentImage", null));
    dispatch(setFormElearning("AttachmentVideo", null));
    dispatch(setFormElearning("AttachmentFile", null));
    setAttachment({
      file: [],
      image: [],
      video: [],
      link: [],
    });

    data?.Medias?.map((v, i) => {
      const data = v.NamaFileUrl;
      if (v.TipeMedia === "Dokumen")
        attachment.file.push({
          data,
        });
      if (v.TipeMedia === "Image")
        attachment.image.push({
          data,
        });
      if (v.TipeMedia === "Video")
        attachment.video.push({
          data,
        });
      if (v.TipeMedia === "Url")
        attachment.link.push({
          data,
        });
    });
    setAttachment({
      file: attachment.file,
      image: attachment.image,
      video: attachment.video,
      link: attachment.link,
    });
  }, [data]);

  const onModalLink = () => {
    dispatch(setStudyModalLink(modalLink));
    dispatch(setFormElearning("AttachmentLink", attachment.link));
  };
  const onModalImage = () => {
    dispatch(setStudyModalImage(modalImage));
    dispatch(setFormElearning("AttachmentImage", attachment.image));
  };
  const onModalVideo = () => {
    dispatch(setStudyModalVideo(modalVideo));
    dispatch(setFormElearning("AttachmentVideo", attachment.video));
  };
  const onModalFile = () => {
    dispatch(setStudyModalFile(modalFile));
    dispatch(setFormElearning("AttachmentFile", attachment.file));
  };

  return (
    <div className="tab-theory">
      <div className="mb-3">
        <h6>Judul Materi : </h6>
        <p>{data?.Materi?.Judul || "Tidak ada judul"}</p>
      </div>
      <div className="mb-3">
        <h6>Deskripsi : </h6>
        <p
          dangerouslySetInnerHTML={{
            __html: data?.Materi?.Deskripsi || "Tidak ada deskripsi",
          }}
        ></p>
      </div>
      <div className="mb-3">
        <h6>Lampiran : </h6>
        {data?.Medias !== null ? (
          <div className="lampiran">
            {attachment.file.length > 0 && (
              <div className="doc" onClick={() => onModalFile()}>
                <div className="icon">
                  <IoFolderOpenOutline color="#fff" size={30} />
                </div>
                <div className="title">File</div>
              </div>
            )}
            {attachment.image.length > 0 && (
              <div className="image" onClick={() => onModalImage()}>
                <div className="icon">
                  <IoImageOutline color="#fff" size={30} />
                </div>
                <div className="title">Gambar</div>
              </div>
            )}
            {attachment.video.length > 0 && (
              <div className="video" onClick={() => onModalVideo()}>
                <div className="icon">
                  <IoVideocamOutline color="#fff" size={30} />
                </div>
                <div className="title">Vidio </div>
              </div>
            )}
            {attachment.link.length > 0 && (
              <div className="link" onClick={() => onModalLink()}>
                <div className="icon">
                  <IoLinkOutline color="#fff" size={30} />
                </div>
                <div className="title">Link</div>
              </div>
            )}
          </div>
        ) : (
          <>
            <p>Tidak ada lampiran</p>
          </>
        )}
      </div>
    </div>
  );
};

const ItemTabAnswer = ({ data }) => {
  const { gbDataUser } = useSelector((state) => state.globalReducer);
  const dispatch = useDispatch();

  const [isDisabled, setIsDisabled] = useState(true);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();

  useEffect(() => {
    setValue("IdKbmSoal", data?.Soal?.IdKbmSoal);
  }, [data]);

  const onFile = (e) => {
    const file = e.target.files[0];
    FUNCValidateUploadFileSize(e.target, 5048, "5MB");
    FUNCValidateUploadFileExtension(e.target, [
      ".jpg",
      ".jpeg",
      ".png",
      ".docx",
      ".pdf",
      ".zip",
      ".rar",
      ".pdf",
    ]);
    setValue("FileJawaban", file);
  };

  const onRubrikCheck = (isCheck) => {
    setIsDisabled(!isCheck);
    if (isCheck) setValue("NamaUrl", "Ya");
    if (!isCheck) setValue("NamaUrl", "");
  };

  const btnUploadJawaban = () => {
    const data = getValues();
    dispatch(apiUploadJawaban(data));
  };
  return (
    <div className="tab-answer">
      {data?.Soal?.TipeJawaban === null && (
        <div className="row">
          <div className="col-md-12 text-center">
            <img
              src={ILStudyUploadNotfound}
              width={250}
              className="img-fluid"
            />
            <div className="text-answer-notfound">
              Yey, Tidak ada upload tugas untuk materi ini!
            </div>
          </div>
        </div>
      )}

      {data?.Soal?.TipeJawaban === "Upload" && (
        <div className="row">
          <div className="col-md-7">
            <form onSubmit={handleSubmit(btnUploadJawaban)}>
              <div className="mb-3">
                <div className="alert alert-warning">
                  <b>Info Upload File </b> <br />
                  1. Ukuran file maksimal 5MB <br />
                  2. Format file yang digunakan wajib{" "}
                  <i>
                    <code>.png .jpg .jpeg .docx .pdf .zip .rar .pdf</code>
                  </i>
                </div>
                {data?.Jawaban && (
                  <div className="mb-3">
                    <Alert
                      icon={<IoCheckmarkSharp color="#fff" size={25} />}
                      type="success"
                      desc={`
                  ${
                    FUNCIsUrl(data?.Jawaban?.NamaFileUrl)
                      ? `Anda sudah mengirim tugas (<a href="${data?.Jawaban?.NamaFileUrl}" target="_blank" class="text-primary font-weight-bold">Lihat URL</a>) !`
                      : data?.Jawaban?.NamaFileUrl === "Ya" &&
                        "Anda sudah mengirim tugas (Rubrik)"
                  }`}
                    />
                  </div>
                )}
                <label className="label-input mb-2">File Tugas</label>
                <div className="input-group">
                  <input
                    type="file"
                    className="input-costum form-control"
                    onChange={(e) => onFile(e)}
                    disabled={data?.Hasil === "Tuntas"}
                  />
                </div>
              </div>
              {gbDataUser?.Unit?.IdUnit === 1 && (
                <div className="mb-3">
                  <label className="label-input mb-2">Bantuan Orang Tua</label>
                  <Controller
                    control={control}
                    defaultValue={getValues("IdJenisBantuanOrtu") || ""}
                    name="IdJenisBantuanOrtu"
                    render={({ field, ref }) => (
                      <ReactSelect
                        inputRef={ref}
                        classNamePrefix="addl-class"
                        // options={dataComboAgama}
                        value={getValues("IdJenisBantuanOrtu")}
                        onChange={(val) => {
                          field.onChange(val);
                        }}
                        isClearable={true}
                      />
                    )}
                    rules={{
                      required: gbDataUser?.Unit?.IdUnit === 1,
                    }}
                  />
                  {errors.IdJenisBantuanOrtu && (
                    <span className="text-danger">Kolom wajib diisi</span>
                  )}
                </div>
              )}
              <div>
                <button
                  type="submit"
                  className="btn btn-primary w-100 mt-3"
                  disabled={data?.Hasil === "Tuntas"}
                >
                  Kirim Sekarang
                </button>
              </div>
            </form>
          </div>
          <div className="col-md-5 text-center">
            <img src={ILStudyUpload} width={300} className="img-fluid" />
          </div>
        </div>
      )}

      {data?.Soal?.TipeJawaban === "Url" && (
        <div className="row">
          <div className="col-md-7">
            <form onSubmit={handleSubmit(btnUploadJawaban)}>
              {data?.Jawaban && (
                <div className="mb-3">
                  <Alert
                    icon={<IoCheckmarkSharp color="#fff" size={25} />}
                    type="success"
                    desc={`
                  ${
                    FUNCIsUrl(data?.Jawaban?.NamaFileUrl)
                      ? `Anda sudah mengirim tugas (<a href="${data?.Jawaban?.NamaFileUrl}" target="_blank" class="text-primary font-weight-bold">Lihat URL</a>) !`
                      : data?.Jawaban?.NamaFileUrl === "Ya" &&
                        "Anda sudah mengirim tugas (Rubrik)"
                  }`}
                  />
                </div>
              )}
              <div className="mb-3">
                <label className="label-input mb-2">File Tugas</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="input-costum form-control"
                    placeholder="Masukkan URL/Link..."
                    {...register("NamaUrl", {
                      required: "Kolom wajib diisi",
                    })}
                    disabled={data?.Hasil === "Tuntas"}
                  />
                </div>
                {errors.NamaUrl && (
                  <span className="alert-input">{errors.NamaUrl?.message}</span>
                )}
              </div>
              {gbDataUser?.Unit?.IdUnit === 1 && (
                <div className="mb-3">
                  <label className="label-input mb-2">Bantuan Orang Tua</label>
                  <Controller
                    control={control}
                    defaultValue={getValues("IdJenisBantuanOrtu") || ""}
                    name="IdJenisBantuanOrtu"
                    render={({ field, ref }) => (
                      <ReactSelect
                        inputRef={ref}
                        classNamePrefix="addl-class"
                        // options={dataComboAgama}
                        value={getValues("IdJenisBantuanOrtu")}
                        onChange={(val) => {
                          field.onChange(val);
                        }}
                        isClearable={true}
                      />
                    )}
                    rules={{
                      required: gbDataUser?.Unit?.IdUnit === 1,
                    }}
                  />
                  {errors.IdJenisBantuanOrtu && (
                    <span className="alert-input">Kolom wajib diisi</span>
                  )}
                </div>
              )}
              <div>
                <button type="submit" className="btn btn-primary w-100 mt-3">
                  Kirim Sekarang
                </button>
              </div>
            </form>
          </div>
          <div className="col-md-5 text-center">
            <img src={ILStudyUpload} width={300} className="img-fluid" />
          </div>
        </div>
      )}

      {data?.Soal?.TipeJawaban === "Rubrik" && (
        <div className="row answer-rubrik">
          <div className="col-md-12 text-center">
            {data?.Jawaban && (
              <div className="mb-3">
                <Alert
                  icon={<IoCheckmarkSharp color="#fff" size={25} />}
                  type="success"
                  desc={`
                  ${
                    FUNCIsUrl(data?.Jawaban?.NamaFileUrl)
                      ? `Anda sudah mengirim tugas (<a href="${data?.Jawaban?.NamaFileUrl}" target="_blank" class="text-primary font-weight-bold">Lihat URL</a>) !`
                      : data?.Jawaban?.NamaFileUrl === "Ya" &&
                        "Anda sudah mengirim tugas (Rubrik)"
                  }`}
                />
              </div>
            )}
            <span>Apakah sudah anda lakukan ?</span>
            <div className="my-3">
              <img
                src={ILStudyUploadRubrik}
                width={250}
                className="img-fluid"
              />
            </div>
            <div>
              <label class="custom-switch">
                <input
                  type="checkbox"
                  name="JawabanUrl"
                  id="JawabanUrl"
                  className="custom-switch-input"
                  onChange={(e) => onRubrikCheck(e.target.checked)}
                  disabled={data?.Hasil === "Tuntas"}
                />
                <span className="custom-switch-indicator"></span>
              </label>
            </div>
            <label>Klik jika sudah melakukannya</label>
            <div>
              <button
                type="submit"
                className="btn btn-primary w-50 mt-3"
                disabled={isDisabled}
                onClick={() => btnUploadJawaban()}
              >
                Kirim Tugas
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const ItemTabScore = ({ data }) => {
  return (
    <>
      <div className="mb-3">
        <h6>Nilai : </h6>
        <p>{data?.Jawaban?.Nilai || "Tidak ada nilai"}</p>
      </div>
      <div className="mb-3">
        <h6>Hasil : </h6>
        <p>{data?.Hasil || "Tidak ada hasil"}</p>
      </div>
      <div className="mb-3">
        <h6>Catatan : </h6>
        <p>{data?.Catatan || "Tidak ada catatan"}</p>
      </div>
    </>
  );
};

export default SectionContent;
