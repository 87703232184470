import React from "react";
import { IMGNotFoundSquare } from "src/assets";

const ItemJadwal = ({ day, date, timeStart, timeEnd, img, teach }) => {
  return (
    <div className="card-qiroaty-jadwal">
      <div className="d-flex justify-content-between">
        <div>
          <div className="day">
            <span>{day}</span>
          </div>
        </div>
        <div>
          <span>Jam Mulai : {timeStart}</span>
        </div>
      </div>
      <div className="d-flex justify-content-between mt-3 mb-3">
        <div>
          <span>{date}</span>
        </div>
        <div>
          <span>Jam Selesai : {timeEnd}</span>
        </div>
      </div>
      <hr />
      <div className="d-flex align-items-center mt-3">
        <div className="card-user-profile">
          <img
            src={img}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = IMGNotFoundSquare;
            }}
            className={"user-profile"}
          />
        </div>
        <div className="ms-3">
          <span>{teach}</span>
        </div>
      </div>
    </div>
  );
};

export default ItemJadwal;
