import React from "react";
import { IMGLogin1, IMGLogin2, IMGLogin3, Logo } from "src/assets";
import Slider from "react-slick";
import ItemBanner from "./components/ItemBanner";

const SectionBanner = () => {
  return (
    <div className="col-md-7 vh-100 bg-primary d-none d-md-block">
      <div className="card-banner-login">
        <Slider
          slidesPerRow={1}
          autoplay={true}
          infinite={true}
          dots
          speed={500}
          responsive={[
            {
              breakpoint: 801,
              settings: {
                slidesPerRow: 1,
              },
            },
          ]}
          className="abs-container"
        >
          <ItemBanner
            img={IMGLogin1}
            title={"Fitur sangat mudah untuk digunakan 🔥"}
          />
          <ItemBanner
            img={IMGLogin2}
            title={
              "Web dapat diakses dengan cepat menggunakan server premium ⚡"
            }
          />
          <ItemBanner
            img={IMGLogin3}
            title={"Tampilan Website sangat responsive, easy use ❤️"}
          />
        </Slider>
      </div>
    </div>
  );
};

export default SectionBanner;
