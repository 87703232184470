import React, { useEffect } from "react";
import { IoFilterOutline, IoCalendarOutline } from "react-icons/io5";
import { useSelector, useDispatch } from "react-redux";
import { ILStudyNotfound } from "src/assets";
import { FUNCDateToString } from "src/config";
import {
  getDateQiroatyFilterFirstLast,
  setDataQiroatyJadwals,
  setFormQiroaty,
  setQiroatyModalFilter,
} from "src/config/redux/action";
import ItemJadwal from "./ItemJadwal";
import { Skeleton } from "@mui/material";

const SectionTime = () => {
  const { formQiroaty, modalFilterQiroaty, dataQiroatyJadwals } = useSelector(
    (state) => state.qiroatyReducer
  );
  const dispatch = useDispatch();
  const onModal = () => {
    dispatch(setFormQiroaty("SelectType", "time"));
    dispatch(setQiroatyModalFilter(modalFilterQiroaty));
  };
  useEffect(() => {
    dispatch(getDateQiroatyFilterFirstLast(formQiroaty.TanggalFilter, "time"));
    dispatch(
      setDataQiroatyJadwals(FUNCDateToString(formQiroaty.TanggalFilter))
    );
  }, [dispatch]);

  return (
    <div className="row">
      <div className="col-md-12 mb-3">
        <div className="d-flex justify-content-between align-items-center">
          <div className="qiroaty-jadwal-filter" onClick={() => onModal()}>
            <IoFilterOutline color="#3F3D56" />
            <span className="ms-2">Filter</span>
          </div>
          <div className="qiroaty-jadwal-filter-tanggal">
            <IoCalendarOutline color="#fff" />
            <span>
              Tanggal : {formQiroaty.TanggalAwal} / {formQiroaty.TanggalAkhir}
            </span>
          </div>
        </div>
      </div>
      {dataQiroatyJadwals?.length > 0 &&
        dataQiroatyJadwals?.map((v, i) => {
          return (
            <div key={i} className="col-md-6">
              <ItemJadwal
                day={v.Hari}
                date={v.Tanggal}
                img={
                  "http://adm.taufiqischool.id/assets/img/avatar/avatar-1.png"
                }
                timeStart={v.JamMulai}
                timeEnd={v.JamSelesai}
                teach={v.Guru}
              />
            </div>
          );
        })}
      {dataQiroatyJadwals?.length === 0 && (
        <LoadingSkeleton height={150} count={6} />
      )}
      {!dataQiroatyJadwals && (
        <div className="qiroaty-jadwal-notfound">
          <img src={ILStudyNotfound} width={400} className="img-fluid" alt="" />
          <h5>Yey, tidak ada jadwal qiroaty </h5>
        </div>
      )}
    </div>
  );
};

const LoadingSkeleton = ({ height, count }) => {
  var loading = [];

  for (let i = 0; i < count; i++) {
    loading.push(
      <div key={i} className="col-md-6">
        <Skeleton
          variant="rectangular"
          height={height}
          style={{ borderRadius: 20, marginBottom: 20 }}
        />
      </div>
    );
  }
  return <>{loading}</>;
};

export default SectionTime;
