import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { LayoutAdm, LayoutGuest } from "src/components";
import route_guest from "src/routes/route_guest";
import { NotFound } from "src/pages/guest";
import AOS from "aos";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "aos/dist/aos.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "kalend/dist/styles/index.css";

import "./App.css";
import route_client from "./routes/route_client";

const App = (props) => {
  return (
    <>
      <Routes>
        <Route path="/" name="Home Guest" element={<LayoutGuest />}>
          {route_guest.map(({ path, name, Component, exact }, key) => {
            return (
              <Route
                key={key}
                exact={exact}
                path={path}
                name={name}
                element={<Component />}
              />
            );
          })}
        </Route>

        {localStorage.getItem("TOKEN") != null && (
          <Route path="/clientarea" name="Client Area" element={<LayoutAdm />}>
            {route_client.map(({ path, name, Component, exact }, key) => {
              return (
                <Route
                  key={key}
                  exact={exact}
                  path={path}
                  name={name}
                  element={<Component />}
                />
              );
            })}
          </Route>
        )}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default App;
